import { LoginApi, Configuration, ConfigurationParameters, BaseAPI, UserApi, ExpeditionApi, AppApi, ChatApi, PostalAreaApi } from "../../client"
import SessionStorage from './../sessionStorage/SessionStorage';
import * as Env from './../environments/Env'

export enum Api { LoginApi, AppApi, UserApi, ExpeditionApi, ChatApi, PostalAreaApi }

export class ApiClient {

    protected static config(token?: string | undefined): Configuration {
        const params: ConfigurationParameters = {};
        if (token) params.accessToken = token;

        params.basePath = Env.getEnvURL_BE();

        params.headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
        //console.log(process.env);
        return new Configuration(params);
    }

    public static getClient(type: Api): BaseAPI {
        switch (type) {
            case Api.LoginApi:
                return new LoginApi(this.config(SessionStorage.getToken()));
            case Api.AppApi:
                return new AppApi(this.config(SessionStorage.getToken()));
            case Api.UserApi:
                return new UserApi(this.config(SessionStorage.getToken()));
            case Api.ExpeditionApi:
                return new ExpeditionApi(this.config(SessionStorage.getToken()));
            case Api.ChatApi:
                return new ChatApi(this.config(SessionStorage.getToken()));
            case Api.PostalAreaApi:
                return new PostalAreaApi(this.config(SessionStorage.getToken()));
            default:
                throw new NotImplementedException(undefined)
        }
    }
}


class NotImplementedException extends Error {
    constructor(value: Api | undefined) {
        super("Api not implemented: " + value?.toString);
    }
}

