/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ManagerTerritoryResponse,
    ManagerTerritoryResponseFromJSON,
    ManagerTerritoryResponseFromJSONTyped,
    ManagerTerritoryResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageableManagerTerritoryResponse
 */
export interface PageableManagerTerritoryResponse {
    /**
     * 
     * @type {number}
     * @memberof PageableManagerTerritoryResponse
     */
    currentPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableManagerTerritoryResponse
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableManagerTerritoryResponse
     */
    resultsPerPage?: number;
    /**
     * 
     * @type {Array<ManagerTerritoryResponse>}
     * @memberof PageableManagerTerritoryResponse
     */
    items?: Array<ManagerTerritoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageableManagerTerritoryResponse
     */
    totalItems?: number;
}

export function PageableManagerTerritoryResponseFromJSON(json: any): PageableManagerTerritoryResponse {
    return PageableManagerTerritoryResponseFromJSONTyped(json, false);
}

export function PageableManagerTerritoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableManagerTerritoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentPage': !exists(json, 'currentPage') ? undefined : json['currentPage'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'resultsPerPage': !exists(json, 'resultsPerPage') ? undefined : json['resultsPerPage'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(ManagerTerritoryResponseFromJSON)),
        'totalItems': !exists(json, 'totalItems') ? undefined : json['totalItems'],
    };
}

export function PageableManagerTerritoryResponseToJSON(value?: PageableManagerTerritoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentPage': value.currentPage,
        'totalPages': value.totalPages,
        'resultsPerPage': value.resultsPerPage,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(ManagerTerritoryResponseToJSON)),
        'totalItems': value.totalItems,
    };
}


