import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

import detector from "i18next-browser-languagedetector"

//Import any language file with an alias
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import pt from './pt.json'

//add all the languages to the bundle
const resources = {
  es: { translation: es },
  en: { translation: en },
  fr: { translation: fr },
  pt: { translation: pt }
};
const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage']
};
i18n
  .use(detector) //detect browser language
  .use(Backend) // XHR requests
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: DETECTION_OPTIONS,
    backend: {
      loadPath: "./{{lng}}.json"
    },
    fallbackLng: "es", // use en if detected lng is not available
    keySeparator: false, //'.',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      wait: true
    }
  });

export default i18n