/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentResponse
 */
export interface AppointmentResponse {
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    expAlb?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentResponse
     */
    to?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentResponse
     */
    dayLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentResponse
     */
    dayOffset?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentResponse
     */
    date?: number;
}

export function AppointmentResponseFromJSON(json: any): AppointmentResponse {
    return AppointmentResponseFromJSONTyped(json, false);
}

export function AppointmentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expAlb': !exists(json, 'expAlb') ? undefined : json['expAlb'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'dayLimit': !exists(json, 'dayLimit') ? undefined : json['dayLimit'],
        'dayOffset': !exists(json, 'dayOffset') ? undefined : json['dayOffset'],
        'date': !exists(json, 'date') ? undefined : json['date'],
    };
}

export function AppointmentResponseToJSON(value?: AppointmentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expAlb': value.expAlb,
        'phoneNumber': value.phoneNumber,
        'from': value.from,
        'to': value.to,
        'dayLimit': value.dayLimit,
        'dayOffset': value.dayOffset,
        'date': value.date,
    };
}


