import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../infrastructure/app-config";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function NavigationTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        history.push(PATHS.adminTracking);
        break;
      case 1:
        history.push(PATHS.adminAccount);
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (history.location) {
      if (history.location.pathname === PATHS.adminTracking) setValue(0);
      else if (history.location.pathname === PATHS.adminAccount) setValue(1);
    }
  }, [history.location]);

  return (
    <Paper className={classes.root}>
      <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
        <Tab label={t("navigationTabs.trackingList")} />
        <Tab label={t("navigationTabs.accountAdmin")} />
      </Tabs>
    </Paper>
  );
}
