import { action, makeAutoObservable, observable, runInAction } from "mobx";
import {
  AppointmentAvailabilityResponse,
  AppointmentRequest,
  ManagerTerritoryResponse,
  PageableManagerTerritoryResponse,
  PostalAreaDTO,
  SpecificDayDTO,
  TimeFrameDTO,
} from "../client";
import PostalAreaRepository from "../data/repositories/PostalAreaRepository";

export default class PostalAreaViewModel {
  static instance: PostalAreaViewModel;

  static getInstance(): PostalAreaViewModel {
    if (PostalAreaViewModel.instance === undefined) {
      PostalAreaViewModel.instance = new PostalAreaViewModel();
    }
    return PostalAreaViewModel.instance;
  }

  currentPage: PageableManagerTerritoryResponse = {
    currentPage: 1,
    items: [],
    resultsPerPage: 10,
    totalItems: 0,
    totalPages: 0,
  };
  postalAreasIds?: string[] = [];
  appointmentAvailabilityResponse?: AppointmentAvailabilityResponse;
  postalAreas: ManagerTerritoryResponse[] = [];
  currentPostalArea?: PostalAreaDTO;
  isLoading: boolean = false;

  @observable postalFilter?: string;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setLoading(bool: boolean) {
    this.isLoading = bool;
  }

  async createPostalArea(postalArea: PostalAreaDTO) {
    this.isLoading = true;
    return runInAction(async () => {
      console.log("createPostalArea "+JSON.stringify(postalArea))
      return PostalAreaRepository.createNewPostalArea(postalArea)
        .then((res) => {
          this.currentPostalArea = res;
          this.isLoading = false;
          return true;
        })
        .catch((error) => {
          this.isLoading = false;
          return false;
        });
    });
  }

  editPostalArea(postalArea: PostalAreaDTO) {
    this.isLoading = true;
    return runInAction(async () => {
      return PostalAreaRepository.editPostalArea(postalArea)
        .then((res) => {
          this.isLoading = false;
          this.currentPostalArea = res;
          return true;
        })
        .catch((error) => {
          this.isLoading = false;
          return false;
        });
    });
  }

  deletePostalArea(postalAreaId: string) {
    this.isLoading = true;
    return runInAction(async () => {
      return PostalAreaRepository.deletePostalAreaById(postalAreaId)
        .then((res) => {
          this.isLoading = false;
          this.currentPostalArea = res;
          return true;
        })
        .catch((error) => {
          this.isLoading = false;
          return false;
        });
    });
  }

  addTimeFrameToPostalArea(postalAreaId: string, timeframe?: TimeFrameDTO) {
    this.isLoading = true;
    return runInAction(async () => {
      return PostalAreaRepository.addTimeFrameToPostalArea(postalAreaId, timeframe)
        .then((res) => {
          this.isLoading = false;
          this.currentPostalArea = res;
          return true;
        })
        .catch((error) => {
          this.isLoading = false;
          return false;
        });
    });
  }

  modifyTimeFrameFromPostalArea(postalAreaId: string, timeframe?: TimeFrameDTO) {
    this.isLoading = true;
    return runInAction(async () => {
      return PostalAreaRepository.modifyTimeFrameFromPostalArea(postalAreaId, timeframe)
        .then((res) => {
          this.isLoading = false;
          this.currentPostalArea = res;
          return true;
        })
        .catch((error) => {
          this.isLoading = false;
          return false;
        });
    });
  }

  removeTimeFrameFromPostalArea(postalAreaId: string, uuid: string) {
    this.isLoading = true;
    return runInAction(async () => {
      return PostalAreaRepository.removeTimeFrameFromPostalArea(postalAreaId, uuid)
        .then((res) => {
          this.isLoading = false;
          this.currentPostalArea = res;
          return true;
        })
        .catch((error) => {
          this.isLoading = false;
          return false;
        });
    });
  }

  addSpecificDayForPostalArea(postalAreaId: string, specificDay?: SpecificDayDTO) {
    this.isLoading = true;
    return runInAction(async () => {
      return PostalAreaRepository.addSpecificDayForPostalArea(postalAreaId, specificDay)
        .then((res) => {
          this.isLoading = false;
          this.currentPostalArea = res;
          return true;
        })
        .catch((error) => {
          this.isLoading = false;
          return false;
        });
    });
  }

  modifySpecificDayFromPostalArea(postalAreaId: string, specificDay?: SpecificDayDTO) {
    this.isLoading = true;
    return runInAction(async () => {
      return PostalAreaRepository.modifySpecificDayFromPostalArea(postalAreaId, specificDay)
        .then((res) => {
          this.isLoading = false;
          this.currentPostalArea = res;
          return true;
        })
        .catch((error) => {
          this.isLoading = false;
          return false;
        });
    });
  }

  removeSpecificDayFromPostalArea(postalAreaId: string, uuid: string) {
    this.isLoading = true;
    return runInAction(async () => {
      return PostalAreaRepository.removeSpecificDayFromPostalArea(postalAreaId, uuid)
        .then((res) => {
          this.isLoading = false;
          this.currentPostalArea = res;
          return true;
        })
        .catch((error) => {
          this.isLoading = false;
          return false;
        });
    });
  }

  bookAppointment(request: AppointmentRequest) {
    this.isLoading = true;
    return runInAction(async () => {
      return PostalAreaRepository.bookAppointment(request)
        .then((res) => {
          this.isLoading = false;
          this.appointmentAvailabilityResponse = undefined;
          return true;
        })
        .catch((error) => {
          this.isLoading = false;
          return false;
        });
    });
  }

  getAppointments(expAlb: string, phoneNumber: string) {
    this.isLoading = true;
    return runInAction(async () => {
      return PostalAreaRepository.getAppointments(expAlb, phoneNumber)
        .then((res) => {
          this.isLoading = false;
          this.appointmentAvailabilityResponse = res;
          return true;
        })
        .catch((error) => {
          this.isLoading = false;
          return false;
        });
    });
  }

  getAllPostalAreas() {
    this.isLoading = true;
    return runInAction(async () => {
      return PostalAreaRepository.getAllPostalAreas()
        .then((res) => {
          this.isLoading = false;
          this.postalAreas = res;
          return true;
        })
        .catch((error) => {
          this.isLoading = false;
          return false;
        });
    });
  }

  getPostalAreasIdList(): void {
    this.setLoading(true);
    PostalAreaRepository.getPostalAreasForManagerPaginated(1, 10000)
      .then((res) => {
        this.postalAreasIds = res.items?.map((p) => p.id ?? "");
        this.postalAreas = res.items ?? [];
      })
      .catch((error: unknown) => [])
      .finally(() => this.setLoading(false));
  }

  getPostalAreasForManagerPaginated(pageNumber: number, pageSize: number) {
    this.isLoading = true;
    runInAction(() => {
      PostalAreaRepository.getPostalAreasForManagerPaginated(pageNumber, pageSize, this.postalFilter)
        .then((response) => {
          this.currentPage = response;
          this.postalAreas = response.items ?? [];
        })
        .catch((error: unknown) => {})
        .finally(() => {
          this.isLoading = false;
        });
    });
  }

  getPostalAreaById(id: string) {
    this.isLoading = true;
    runInAction(() => {
      PostalAreaRepository.getPostalAreaById(id)
        .then((response) => {
          this.currentPostalArea = response;
        })
        .catch((error: unknown) => {})
        .finally(() => {
          this.isLoading = false;
        });
    });
  }

  setSelectedArea(area: PostalAreaDTO) {
    this.currentPostalArea = area;
  }

  @action
  setAreas(areas: PostalAreaDTO[]) {
    this.isLoading = true;
    this.postalAreas = areas;
    this.isLoading = false;
  }

  @action setFilter(filter?: string) {
    this.postalFilter = filter;
  }

  @action cleanFilter() {
    this.setFilter(undefined);
  }
}
