import {  Grid, Typography, Avatar, Container, makeStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { OwnColors } from '../../../commons/Constants';

const useStyles = makeStyles(theme => ({
    avatarIcon2: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        width: 120,
        height: 120,
        fontSize: 35,
        lineHeight: 1,
        borderRadius: '50%',
        overflow: 'hidden',
        userSelect: 'none',

    },
}));

const NotFound = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    return <Container maxWidth={"md"} style={{ paddingTop: 30 }}>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
        >

            <Grid item xs={12}>
                <Avatar className={classes.avatarIcon2} style={{ backgroundColor: OwnColors.mobelBlue }}>{<SentimentVeryDissatisfiedIcon style={{ height: '115px', width: "115px" }} />}</Avatar>
            </Grid>

        </Grid>
        <Typography style={{ paddingTop: 15 }} align={"center"}>{t("trackingView.noRef")}</Typography>
    </Container>;
}

export default NotFound;