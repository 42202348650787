import { makeStyles } from "@material-ui/core";
import { OwnColors } from "../../../commons/Constants";
import { Height } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    [theme.breakpoints.up("xs")]: {
      height: "85vh",
    },
    [theme.breakpoints.up("sm")]: {
      height: "90vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "90vh",
    },
    [theme.breakpoints.up("lg")]: {
      height: "90vh",
    },
    [theme.breakpoints.up("xl")]: {
      height: "90vh",
    },
  },
  chatBox: {
    overflow: "auto",
    [theme.breakpoints.up("xs")]: {
      height: "90%",
    },
    [theme.breakpoints.up("sm")]: {
      height: "110%",
    },
    paddingTop: "1rem"
  },
}));

export default useStyles;
