
import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import AddIcon from '@material-ui/icons/Add';
import { debounceSearchRender } from './Search'
import EditIcon from '@material-ui/icons/Edit';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import IconButton from '@material-ui/core/IconButton';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { TableRow, TableCell, Grid, Typography, CircularProgress } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ShipmentViewModel from '../../../viewmodels/ShipmentViewModel';
import getMuiTheme from './TrackingTableStyles'


export default function TrackingTable(props) {
  const { t } = useTranslation()
  let [resizable, setResizable] = useState(false)
  let [expeditionSearched, setExpeditionSearched] = useState("")

  React.useEffect(() => {
    console.log(props.selectedRef);
  })

  const options = {
    responsive: "vertical",
    tableBodyHeight: 'auto',
    tableBodyMaxHeight: '72vh',
    filter: false,
    columns: false,
    viewColumns: false,
    page: props.page,
    count: props.count,
    jumpToPage: true,
    changeRowsPerPage: false,
    rowsPerPageOptions: [],
    draggableColumns: { enabled: true },
    expandableRows: props.expandable,
    selectableRows: props.selectableRows,
    rowsSelected: props.selectedIndex != undefined ? [props.selectedIndex] : [],
    expandableRowsHeader: true,
    expandableRowsOnClick: false,
    selectableRowsOnClick: props.selectableRowsOnClick,
    resizableColumns: resizable,
    print: false,
    download: false,
    pagination: true,
    rowsPerPage: 50,
    customSearchRender: debounceSearchRender(500, props.isLoading, null, props.loadShipments, props.setIsLoadingInTable),
    serverSide: true,
    confirmFilters: true,
    onSearchOpen: () => {
    },
    onSearchClose: () => {
    },
    onSearchChange: (stringWritten) => {

      setExpeditionSearched(stringWritten);
    },
    searchProps: {
      /*onBlur: (e) => {
        alert('onBlur!');
      },*/
      onKeyUp: (e) => {
        if (e.key === "Enter") {
          props.searchExpedition(expeditionSearched);
        }
      }
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      const elements = []

      rowsSelected.forEach(index => {
        elements.push(props.data[index])
      })
      props.handleRowsSelected(elements)
    },

    onChangePage: (currentPage) => {
      props.handleCurrentPage(currentPage)
    },
    textLabels: {
      body: {
        noMatch: t("tracking.table.noMatch"),
        toolTip: t("tracking.table.order"),
        columnHeaderTooltip: column => `${t("tracking.table.order.by")} ${column.label}`
      },
      pagination: {
        next: t("tracking.table.result.next"),
        previous: t("tracking.table.result.previous"),
        rowsPerPage: t("tracking.table.result.by.page"),
        displayRows: t("tracking.table.result.of"),
      },
      toolbar: {
        search: t("tracking.table.result.search"),
        downloadCsv: t("tracking.table.tools.order.downloadCsv"),
        print: t("tracking.table.tools.print"),
        viewColumns: t("tracking.table.tools.viewColumns"),
        filterTable: t("tracking.table.tools.filterTable"),
      },
      filter: {
        all: t("tracking.table.filter.all"),
        title: t("tracking.table.filter.title"),
        reset: t("tracking.table.filter.reset"),
      },
      viewColumns: {
        title: t("tracking.table.viewColumns.title"),
        titleAria: t("tracking.table.viewColumns.titleAria"),
      },
      selectedRows: {
        text: t("tracking.table.selectedRows.text")
      }
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return <CustomToolbarSelect />
    },
    selectToolbarPlacement: 'replace',
    disableToolbarSelect: true
  };


  const CustomToolbarSelect = (props1) => {
    return <React.Fragment><div style={{marginRight:"4vh", color: "black"}} className={"custom-toolbar-select"}>{props.selectedRef}</div></React.Fragment>;
  }

  return (
    <MuiThemeProvider theme={getMuiTheme()} >
      <MUIDataTable
        title={
          <Typography variant="h6">
            {props.title}
            {props.isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={props.data}
        columns={props.columns}
        options={options}
      />
    </MuiThemeProvider >
  )
}

