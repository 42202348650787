import React from 'react';
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { makeStyles, fade, CircularProgress } from '@material-ui/core';
import ShipmentViewModel from '../../viewmodels/ShipmentViewModel';
import TrackingTable from '../components/TableMaterial/TrackingTable';
import * as Utils from './../../commons/Utils';
import { ExpeditionStatusResponse, PageableShipment, Shipment } from '../../client';
import SessionStorage from '../../data/sessionStorage/SessionStorage';
import UsersViewModel from '../../viewmodels/UsersViewModel';

export interface IAdminTrackingTableProps {
    handleSelectedRef: (ref: Shipment) => void,
    classes: any,
    selectedRef: number | undefined,
    selectedIndex: number | undefined,
    filterCheckActive: boolean
}

const AdminTrackingTable: React.FunctionComponent<IAdminTrackingTableProps> = (props: IAdminTrackingTableProps) => {

    const { t } = useTranslation();
    const vmShipment = ShipmentViewModel.getInstance();
    const vmUser = UsersViewModel.getInstance();
    const shipments = vmShipment.shipmentsAdmin;
    const [currentPage, setCurrentPage] = React.useState<number>(0)
    const [totalPages, setTotalPages] = React.useState<number>(0)
    const [columns, setColumns] = React.useState<any>()
    //const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const handleCurrentPage = (currentPage: number) => {
        setCurrentPage(currentPage+1);
        vmShipment.setSelectedRowIndex(undefined)
        vmShipment.unLoadShipment();
        vmShipment.unloadAdminShipments();
        vmShipment.setIsLoadingInTable(true);
        
        if (props.filterCheckActive) {
            vmShipment.filterExpeditionsByLastMessage(currentPage + 1, 50).then(response => {
                setCurrentPage(response.currentPage ?? 0);
                setTotalPages(response.totalPages ?? 0);
                vmShipment.setIsLoadingInTable(false);
            });
        } else {
            vmShipment.loadAdminShipments(currentPage+1, 50).then((pageableShipments: PageableShipment) => {
                setCurrentPage(pageableShipments.currentPage || 0);
                setTotalPages(pageableShipments.totalPages || 0);
                vmShipment.setIsLoadingInTable(false);
            });
        }
    }

    const handleRowsSelected = (rows: any) => {
        ///debugger
        //setRowsSelectedByUser(rows)
        if (!vmShipment.loadingShipment) {

        }
        props.handleSelectedRef(rows[0])
    }

    React.useEffect(() => {
        if (!shipments) {
            vmShipment.isLoadingInTable = true;
            vmShipment.loadAdminShipments().then((pageableShipments: PageableShipment) => {
                setCurrentPage(pageableShipments.currentPage || 0);
                setTotalPages(pageableShipments.totalPages || 0);
            })
                .finally(() => vmShipment.isLoadingInTable = false);
        }
    }, [])

    React.useEffect(() => {
        if (shipments) {
            var renderedColumns = [
                {
                    label: t("trackingAdminView.table.ref"),
                    name: "shipmentExp",
                    options: {
                        sort: true,
                        filter: true,
                        filterOptions: { fullWidth: true },
                        /*customBodyRenderLite: (dataIndex: any) => {
                            let val = shipments![dataIndex].shipmentExp;
                            return val;
                        },*/
                        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                            return (
                                <span style={shipments?.[tableMeta.rowIndex]?.unAnswered ? { fontWeight: "bold" } : {}}>
                                    {value}
                                </span>
                            );
                        }
                    }
                },
                {
                    label: t("trackingAdminView.table.date"),
                    name: "date",
                    options: {
                        sort: true,
                        filter: true,
                        filterOptions: { fullWidth: true },
                        customBodyRenderLite: (dataIndex: any) => {
                            let val = Utils.timestamp2HumanReadableDateTime(shipments?.[dataIndex]?.creationDate!.getTime()!, t);
                            return val;
                        }
                    }
                }];

            if (vmUser.currentUser?.role?.toLocaleLowerCase() === "admin") {
                renderedColumns.push(
                    {
                        label: t("trackingAdminView.table.manager"),
                        name: "accountManagerId",
                        options: {
                            sort: true,
                            filter: true,
                            filterOptions: { fullWidth: true },
                            customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                                return <span>{value}</span>;
                            }
                        }
                    }
                )
            }
            setColumns(renderedColumns);
        }
    }, [shipments])

    const searchExpedition = (expNumber: string) => {
        vmShipment.isLoadingInTable = true;
        vmShipment.unLoadShipment();
        vmShipment.searchAdminShipment(expNumber as unknown as number)
            .finally(() => vmShipment.isLoadingInTable = false);
    }

    return <>
        <div>
            <div>
                {(shipments && shipments.length === 0) ?
                    /* Circular progress when table is not loaded */
                    <div>
                        <CircularProgress className={props.classes.circleProgress} />
                    </div>
                    :

                    <div>
                        <TrackingTable
                            searchExpedition={searchExpedition}
                            data={shipments}
                            loadShipments={vmShipment.loadAdminShipments}
                            setIsLoadingInTable={vmShipment.setIsLoadingInTable}
                            selectableRows="single"
                            selectableRowsOnClick={true}
                            handleRowsSelected={handleRowsSelected}
                            handleCurrentPage={handleCurrentPage}
                            updatePage={false}
                            page={currentPage-1}
                            count={totalPages * 50}
                            isLoading={vmShipment.isLoadingInTable}
                            selectedRef={props.selectedRef}
                            selectedIndex={props.selectedIndex}
                            columns={columns}
                        />
                    </div>
                }
            </div>
        </div>
    </>;
};

export default observer(AdminTrackingTable);