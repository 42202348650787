import React from "react";
import {Link, Box, Grid,  TextField, Typography, Container } from '@material-ui/core';

import { OwnColors } from "../../../commons/Constants";
import { Copyright } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoginViewState } from "../../loginView/LoginView";
import Button from "../commons/Button";

export interface IRecoverPassFormProps{
    LogoMobel: string,
    classes: any,
    handleChange: (prop: keyof LoginViewState) => (event: React.ChangeEvent<HTMLInputElement>)=>void,
    values: any,
    handleSubmitRecoverPass: ()=>void,
    handleShowRecover: ()=>void,
    changePassError: boolean,
    changePassMessage: string
}

const RecoverPassForm: React.FunctionComponent<IRecoverPassFormProps> = (props: IRecoverPassFormProps) => {

    const history = useHistory();
    const {t} = useTranslation();

    return <Container component="main" maxWidth="xs">
        <div className={props.classes.paper}>
            <Grid container spacing={2} style={{ marginTop: 15 }}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        value={props.values.username}
                        id="email"
                        label={t("login.email.address")}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={props.handleChange("username")}
                    />
                </Grid>
            </Grid>

            <Button
                variant={"contained"}
                text={t("login.recover.button")}
                onClick={() => props.handleSubmitRecoverPass()}
                size="medium"
                color={OwnColors.lightGrey}
                textColor={OwnColors.grey}
            />
            <Grid container>
                <Grid item xs>
                    <Link href="#" variant="body2" onClick={() => props.handleShowRecover()}>
                        {t("login.goBack")}
                    </Link>
                </Grid>
            </Grid>
        </div>
        <Typography
            style={{ paddingTop: 20 }}
            variant="body2"
            color={props.changePassError ? "error" : "primary"}
            align="center"
        >
            {props.changePassMessage}
        </Typography>
        <Box mt={8}>
            <Copyright />
        </Box>
    </Container>;
}

export default RecoverPassForm;
