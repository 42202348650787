import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Container, Grid, Typography } from "@material-ui/core";
import MobelLandingImage from "../../assets/images/landingMobel.png";
import viewStyles from "./WelcomeStyles";

const WelcomeView: React.FunctionComponent = (props: any) => {
  const { t } = useTranslation();
  const classes = viewStyles();

  return (
    <div className={classes.content}>
      <div className={classes.heroContent}>
        <Container maxWidth="md">
          <Grid container spacing={4}>
            <Grid className={classes.imageGrid} item key={"image"} xs={12} sm={6} md={6}>
              <img className={classes.image} src={MobelLandingImage} alt={"MobelLandingImage"} />
            </Grid>
            <Grid className={classes.textGrid} item key={"text"} xs={12} sm={6} md={6}>
              <Typography variant="h2" align="left" className={classes.textPrimaryBlueBig} paragraph>
                {<strong>{t("welcomeView.t1")}</strong>}
              </Typography>
              <Typography variant="h3" align="left" className={classes.textPrimaryBlueSmall} paragraph>
                {<strong>{t("welcomeView.t1")}</strong>}
              </Typography>
              <Typography variant="h4" align="left" className={classes.textPrimaryBlueReallySmall} paragraph>
                {<strong>{t("welcomeView.t1")}</strong>}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default observer(WelcomeView);
