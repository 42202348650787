import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core';


const trackingStyles = makeStyles(theme => ({
    mainContainer: {
        marginTop: 74,
        marginBottom: '5px',
        [theme.breakpoints.up('sm')]: {
            marginTop: 84,
        },
    },
    container: {
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        marginTop: "48px"
    },
    avatarLetter: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        width: 50,
        height: 50,
        fontSize: 35,
        lineHeight: 1,
        borderRadius: '50%',
        overflow: 'hidden',
        userSelect: 'none',

    },
    avatarIcon: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        width: 50,
        height: 50,
        fontSize: 35,
        lineHeight: 1,
        borderRadius: '50%',
        overflow: 'hidden',
        userSelect: 'none',

    },
    avatarIcon2: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        width: 120,
        height: 120,
        fontSize: 35,
        lineHeight: 1,
        borderRadius: '50%',
        overflow: 'hidden',
        userSelect: 'none',

    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        //marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
    },
    chatComponent: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 15
        },
    },
}));

export default trackingStyles;