/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Message,
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
    Shipment,
    ShipmentFromJSON,
    ShipmentFromJSONTyped,
    ShipmentToJSON,
    Step,
    StepFromJSON,
    StepFromJSONTyped,
    StepToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExpeditionStatusResponse
 */
export interface ExpeditionStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof ExpeditionStatusResponse
     */
    eta?: string;
    /**
     * 
     * @type {Shipment}
     * @memberof ExpeditionStatusResponse
     */
    shipment?: Shipment;
    /**
     * 
     * @type {Array<Step>}
     * @memberof ExpeditionStatusResponse
     */
    steps?: Array<Step>;
    /**
     * 
     * @type {Array<Message>}
     * @memberof ExpeditionStatusResponse
     */
    messages?: Array<Message>;
    /**
     * 
     * @type {string}
     * @memberof ExpeditionStatusResponse
     */
    deliveryType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExpeditionStatusResponse
     */
    isChatActive?: boolean;
}

export function ExpeditionStatusResponseFromJSON(json: any): ExpeditionStatusResponse {
    return ExpeditionStatusResponseFromJSONTyped(json, false);
}

export function ExpeditionStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpeditionStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eta': !exists(json, 'eta') ? undefined : json['eta'],
        'shipment': !exists(json, 'shipment') ? undefined : ShipmentFromJSON(json['shipment']),
        'steps': !exists(json, 'steps') ? undefined : ((json['steps'] as Array<any>).map(StepFromJSON)),
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(MessageFromJSON)),
        'deliveryType': !exists(json, 'deliveryType') ? undefined : json['deliveryType'],
        'isChatActive': !exists(json, 'isChatActive') ? undefined : json['isChatActive'],
    };
}

export function ExpeditionStatusResponseToJSON(value?: ExpeditionStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eta': value.eta,
        'shipment': ShipmentToJSON(value.shipment),
        'steps': value.steps === undefined ? undefined : ((value.steps as Array<any>).map(StepToJSON)),
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(MessageToJSON)),
        'deliveryType': value.deliveryType,
        'isChatActive': value.isChatActive,
    };
}


