import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  userChatLine: {
    position: "relative",
    marginLeft: "20px",
    marginBottom: "10px",
    padding: "10px",
    borderRadius: "10px",
    width: "80%",
    "&:after": {
      content: "''",
      color: "blue",
      position: "absolute",
      borderTop: "30px solid white",
      borderLeft: "25px solid transparent",
      borderRight: "10px solid transparent",
      borderRadius: "8px",
      top: "0px",
      left: "-22px",
    },
  },
  adminChatLine: {
    position: "relative",
    marginRight: "20px",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#80BFD6",
    width: "80%",
    borderRadius: "10px",
    "&:after": {
      content: "''",
      position: "absolute",
      borderRadius: "8px",
      borderTop: "30px solid #80BFD6",
      borderLeft: "10px solid transparent",
      borderRight: "25px solid transparent",
      top: "0",
      right: "-20px",
    },
  },
}));
