/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Shipment
 */
export interface Shipment {
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    shipmentExp?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    shipmentAlb?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    phone?: string;
    /**
     * 
     * @type {Date}
     * @memberof Shipment
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    accountManagerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    clientId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    unAnswered?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    clientName?: string;
}

export function ShipmentFromJSON(json: any): Shipment {
    return ShipmentFromJSONTyped(json, false);
}

export function ShipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Shipment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shipmentExp': !exists(json, 'shipmentExp') ? undefined : json['shipmentExp'],
        'shipmentAlb': !exists(json, 'shipmentAlb') ? undefined : json['shipmentAlb'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'accountManagerId': !exists(json, 'accountManagerId') ? undefined : json['accountManagerId'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'unAnswered': !exists(json, 'unAnswered') ? undefined : json['unAnswered'],
        'clientName': !exists(json, 'clientName') ? undefined : json['clientName'],
    };
}

export function ShipmentToJSON(value?: Shipment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shipmentExp': value.shipmentExp,
        'shipmentAlb': value.shipmentAlb,
        'phone': value.phone,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'accountManagerId': value.accountManagerId,
        'clientId': value.clientId,
        'unAnswered': value.unAnswered,
        'clientName': value.clientName,
    };
}


