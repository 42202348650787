import { action, makeAutoObservable, runInAction } from "mobx";
import { useTranslation } from "react-i18next";
import { ExpeditionStatusResponse, Message, Shipment } from "../client";
import ChatRepository from "../data/repositories/ChatRepository";
import { Chat } from "./../client";
import UsersViewModel from "./UsersViewModel";

export default class ChatViewModel {
  static instance: ChatViewModel;
  static getInstance(): ChatViewModel {
    if (ChatViewModel.instance === undefined) {
      ChatViewModel.instance = new ChatViewModel();
    }
    return ChatViewModel.instance;
  }

  constructor() {
    makeAutoObservable(this);
  }

  currentChat: Message[] = [];

  loadChatMsg(t: (key: string) => string, shipment?: Shipment) {
    return runInAction(async () => {
      return ChatRepository.getChatHistory(shipment?.shipmentExp!)
        .then((res) => {
          // console.log("loadChatMsg => chatApi.getMessagesFromExpedition => res = ", res);
          this.currentChat = this.setAsyncInfoWarningMessageChat(res, t);
          // console.log("loadChatMsg => chatApi.getMessagesFromExpedition => res =>  this.currentChat = ", this.currentChat);
          return res;
        })
        .catch((error) => {
          // console.log("loadChatMsg => chatApi.getMessagesFromExpedition => error = ", error);
          return error;
        });
    });
  }

  sendChatMsg(shipment: Shipment, msg: string, customer: boolean, t: (key: string) => string) {
    return runInAction(async () => {
      const message: Message = {
        message: msg,
        shipmentExp: shipment?.shipmentExp!,
        accountManagerId: shipment?.accountManagerId,
        clientId: shipment?.clientId,
        messageOwnerId: customer ? shipment?.clientId : shipment?.accountManagerId,
      };
      return ChatRepository.sendChat(message)
        .then((res) => {
          // console.log("sendChatMsg => chatApi.createMessage => res = ", res);
          //this.currentChat!.push(res);
          this.loadChatMsg(t, shipment);
          // console.log(
          //   "sendChatMsg => chatApi.createMessage => res =>  this.currentChat = ",
          //   this.currentChat
          // );
          return res;
        })
        .catch((error) => {
          // console.log(
          //   "sendChatMsg => chatApi.createMessage => error = ",
          //   error
          // );
          return error;
        });
    });
  }

  setAsyncInfoWarningMessageChat(messages: Message[], t: (key: string) => string): Message[] {
    if (!UsersViewModel.getInstance().currentUser) {
      let firstCustomerMessage = messages.find((message) => message.clientId === message.messageOwnerId);
      if (firstCustomerMessage) {
        let index = messages.indexOf(firstCustomerMessage);
        if (index > -1) {
          if (messages.length == index + 1) {
            //is last item
            messages.push(this.generateAsyncWarningMessage(firstCustomerMessage, t));
          } else {
            messages.splice(index + 1, 0, this.generateAsyncWarningMessage(firstCustomerMessage, t));
          }
        }
      }
    }

    return messages;
  }

  @action
  private generateAsyncWarningMessage(sample: Message, t: (key: string) => string): Message {
    let asyncWarningMessage: Message = {
      accountManagerId: sample.accountManagerId,
      clientId: sample.clientId,
      datetime: new Date(sample.datetime!.getTime() + 1),
      id: 0,
      message: t("chat.viewmodel.syncCommunication.warning"),
      messageOwnerId: sample.accountManagerId,
      shipmentExp: sample.shipmentExp,
    };

    return asyncWarningMessage;
  }

  updateChat(chat: Chat) {
    return runInAction(async () => {
      return ChatRepository.updateChat(chat)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          // console.log("sendChatMsg => chatApi.createMessage => error = ", error);
          return error;
        });
    });
  }
}
