import { Button, Grid, Menu, MenuItem, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import MaterialToolbar from "@material-ui/core/Toolbar";
import { AccountCircle } from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LanguageIcon from "@material-ui/icons/Language";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Phone from "@material-ui/icons/Phone";
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LogoMobel from "../../../assets/images/logoMobel.png";
import { OwnColors } from "../../../commons/Constants";
import i18n from "../../../infrastructure/i18n/i18n";
import ChatViewModel from "../../../viewmodels/ChatViewModel";
import ShipmentViewModel from "../../../viewmodels/ShipmentViewModel";
import UsersViewModel from "../../../viewmodels/UsersViewModel";
import SearchDialog from "./SearchDialog";
import toolbarStyles from "./css/toolbarStyles";
import { PATHS, ROLES } from "../../../infrastructure/app-config";

const Toolbar: React.FunctionComponent = (props: any) => {
  const classes = toolbarStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [showSearchMenu, setShowSearchMenu] = React.useState(false);
  const [searchRefText, setSearchRefText] = React.useState("");
  const [searchRefTextError, setSearchRefTextError] = React.useState(false);
  const [searchPhoneText, setSearchPhoneText] = React.useState("");
  const [searchPhoneTextError, setSearchPhoneTextError] = React.useState(false);
  const vmShipment = ShipmentViewModel.getInstance();
  const vmChat = ChatViewModel.getInstance();
  const vmUser = UsersViewModel.getInstance();

  const [langMenuAnchorEl, setLangMenuAnchorEl] = React.useState(null);
  const langMenuOpen = Boolean(langMenuAnchorEl);

  const handleClickLangMenu = (event: any) => {
    setLangMenuAnchorEl(event.currentTarget);
  };

  const handleClickLangMenuSelection = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const handleCloseLangMenu = () => {
    setLangMenuAnchorEl(null);
  };

  React.useEffect(() => {
    setSearchRefText(vmShipment.trackingAlb || "");
  }, [vmShipment.trackingAlb]);

  React.useEffect(() => {
    setSearchPhoneText(vmShipment.trackingPhone || "");
  }, [vmShipment.trackingPhone]);

  const handleLogin = () => {
    window.scrollTo(0, 0);
    history.push(PATHS.login);
  };

  const handleTrackingAdmin = () => {
    window.scrollTo(0, 0);
    history.push(PATHS.adminTracking);
  };

  const handlePostalAreas = () => {
    window.scrollTo(0, 0);
    history.push(PATHS.postalAreas);
  };

  const handleLogout = () => {
    window.scrollTo(0, 0);
    vmUser.logOut();
    history.push(PATHS.root);
  };

  const handleShowSearchMenu = (value: boolean) => {
    console.log("handleShowSearchMenu");
    setShowSearchMenu(value);
  };

  const handleUserAccount = () => {
    console.log("handleUserAccount");
  };

  const handleSearchRefText = (text: string) => {
    setSearchRefText(text);
    if (text !== "") {
      setSearchRefTextError(false);
    }
  };

  const handleSearchPhoneText = (text: string) => {
    setSearchPhoneText(text);
    if (text !== "") {
      setSearchPhoneTextError(false);
    }
  };

  const handleSearch = () => {
    let error = validateSearchFields();
    if (!error) {
      vmShipment.trackingAlb = searchRefText;
      vmShipment.trackingPhone = searchPhoneText;
      vmShipment
        .loadShipment(searchRefText, searchPhoneText)
        .then((res) => {
          console.log("handleSearch => res = ", res);
          return res;
        })
        .then((res2) => {
          vmChat.loadChatMsg(t, res2?.shipment);
        });
      if (history.location.pathname === PATHS.adminTracking) history.push(PATHS.adminTracking);
      else history.push(PATHS.tracking);
      handleShowSearchMenu(false);
    }
    console.log("handleSearch => validateSearchFields => error = ", error);
  };

  const validateSearchFields = () => {
    let error = false;
    if (searchRefText === "") {
      error = true;
      setSearchRefTextError(true);
    }
    if (searchPhoneText === "") {
      error = true;
      setSearchPhoneTextError(true);
    }
    return error;
  };

  const handleFormButtonClick = (evt: unknown) => {
    const role = vmUser.currentUser?.role;

    if (role === ROLES.ADMIN) {
      handleTrackingAdmin();
    } else if (role === ROLES.TERRITORY_MANAGER) {
      handlePostalAreas();
    } else {
      handleLogin();
    }
  };

  return (
    <div className={classes.grow}>
      <AppBar
        style={{ backgroundColor: OwnColors.blueGrey }}
        //position="static">
        position="fixed"
        //classes={{root: classes.appBarModified}}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <MaterialToolbar>
          {
            <img
              src={LogoMobel}
              onClick={() => history.push(PATHS.root)}
              style={{
                display: "block",
                maxHeight: "40px",
                position: "absolute",
              }}
            />
          }
          <Grid container spacing={0} direction="row" alignItems="center" justify="center">
            <div
              className={classes.sectionDesktop}
              style={{
                paddingTop: 7,
                paddingBottom: 7,
                paddingRight: 7,
                paddingLeft: 0,
                borderRadius: 10,
                backgroundColor: OwnColors.blueGrey,
                justifyContent: "center",
              }}
            >
              {
                <Grid
                  className={classes.search}
                  style={{
                    backgroundColor: searchRefTextError ? OwnColors.redError : OwnColors.lightGrey,
                  }}
                >
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder={t("search.dialog.reference")}
                    error={searchRefTextError}
                    value={searchRefText}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={(text) => {
                      handleSearchRefText(text.target.value);
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                </Grid>
              }
              {
                <Grid
                  className={classes.search}
                  style={{
                    backgroundColor: searchPhoneTextError ? OwnColors.redError : OwnColors.lightGrey,
                  }}
                >
                  <div className={classes.searchIcon}>
                    <Phone />
                  </div>
                  <InputBase
                    placeholder={t("search.dialog.phone")}
                    error={searchPhoneTextError}
                    value={searchPhoneText}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={(text) => {
                      handleSearchPhoneText(text.target.value);
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                </Grid>
              }
              <Button
                variant="contained"
                //color={OwnColors.mobelBlue}
                className={classes.searchButton}
                style={{ backgroundColor: OwnColors.mobelBlue }}
                endIcon={<SendIcon style={{ marginLeft: -12, color: OwnColors.lightGrey }} />}
                onClick={handleSearch}
              ></Button>
            </div>
          </Grid>

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label={t("components.toolbar.menu.show.more")}
              aria-controls={"mobileMenuId"}
              aria-haspopup="false"
              size="medium"
              onClick={(event) => {
                handleShowSearchMenu(true);
              }}
              color={"inherit"}
            >
              <SearchIcon />
            </IconButton>
          </div>

          <div className={classes.grow} />
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography>{vmUser.currentUser?.email}</Typography>
            <IconButton
              aria-label={t("components.toolbar.menu.show.more")}
              aria-controls={"langMenu"}
              aria-haspopup="true"
              size="medium"
              onClick={(event) => {
                handleClickLangMenu(event);
              }}
              color={"inherit"}
            >
              {<LanguageIcon />}
            </IconButton>
            <Menu id="langMenu" anchorEl={langMenuAnchorEl} keepMounted open={langMenuOpen} onClose={handleCloseLangMenu}>
              {[
                { lang: "es", label: t("topbar.langmenu.spanish") },
                { lang: "en", label: t("topbar.langmenu.english") },
                { lang: "fr", label: t("topbar.langmenu.french") },
                { lang: "pt", label: t("topbar.langmenu.portuguese") },
              ].map((option) => (
                <MenuItem key={option.lang} onClick={() => handleClickLangMenuSelection(option.lang)}>
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <div style={{ display: "flex" }}>
            <IconButton
              aria-label={t("components.toolbar.menu.show.more")}
              aria-controls={"mobileMenuId"}
              aria-haspopup="false"
              size="medium"
              onClick={handleFormButtonClick}
              color={"inherit"}
            >
              {vmUser.currentUser?.email ? <ListAltIcon /> : <AccountCircle />}
            </IconButton>
          </div>
          <div style={{ display: "flex" }}>
            {vmUser.currentUser?.email && (
              <IconButton
                aria-label={t("components.toolbar.menu.show.more")}
                aria-controls={"mobileMenuId"}
                aria-haspopup="false"
                size="medium"
                onClick={(event) => {
                  handleLogout();
                }}
                color={"inherit"}
              >
                <ExitToAppIcon />
              </IconButton>
            )}
          </div>
          <SearchDialog
            open={showSearchMenu}
            searchRefText={searchRefText}
            handleSearchRefText={handleSearchRefText}
            searchRefTextError={searchRefTextError}
            searchPhoneText={searchPhoneText}
            handleSearchPhoneText={handleSearchPhoneText}
            searchPhoneTextError={searchPhoneTextError}
            onConfirm={() => handleSearch()}
            onClose={() => handleShowSearchMenu(false)}
          />
        </MaterialToolbar>
      </AppBar>
    </div>
  );
};

export default observer(Toolbar);
