import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import UsersViewModel from "../../viewmodels/UsersViewModel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
}));

export interface ProtectedRouteProps extends RouteProps {
  allowedProfiles: String[];
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  let redirectPath = "";
  const classes = useStyles();
    console.log("ROL "+UsersViewModel.getInstance()?.currentUser?.role)
    console.log("ALLOWED ROLES "+props.allowedProfiles)
  if (
    ((UsersViewModel.getInstance()?.currentUser?.email && UsersViewModel.getInstance()?.currentUser?.token) ||
      UsersViewModel.getInstance().loadLocalStorageUser()) &&
    (props.allowedProfiles.length === 0 || props.allowedProfiles.find((profile) => profile === UsersViewModel.getInstance()?.currentUser?.role))
  ) {
    return (
      <>
        <Route {...props} />
      </>
    );
  } else {
    const renderComponent = () => <Redirect to={{ pathname: "/login" }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  }
};

export default ProtectedRoute;
