import { AppApi } from "../../client/apis/AppApi";
import { ExpeditionApi } from "../../client/apis/ExpeditionApi";
import { ExpeditionStatusResponse } from "../../client/models/ExpeditionStatusResponse";
import { PageableShipment } from "../../client/models/PageableShipment";
import { ApiClient, Api } from "./ApiClient";

export default class ShipmentRepository {
  private static getAppApiClient() {
    return ApiClient.getClient(Api.AppApi) as AppApi;
  }

  private static getExpeditionApiClient() {
    return ApiClient.getClient(Api.ExpeditionApi) as ExpeditionApi;
  }

  static async getAdminShipments(pageNum: number, pageSize: number): Promise<PageableShipment> {
    const appApi = this.getAppApiClient();
    const pageableShipments: PageableShipment = await appApi.getAdminExpeditions(pageNum, pageSize);

    return pageableShipments;
  }

  static async getStatusExpedition(shipmentExp: string, phoneNumber: string): Promise<ExpeditionStatusResponse> {
    const appApi = this.getAppApiClient();
    const expeditionStatusResponse = await appApi.getStatusExpedition(shipmentExp, phoneNumber);

    return expeditionStatusResponse;
  }

  static async getStatusExpeditionByReference(reference: number): Promise<ExpeditionStatusResponse> {
    const appExpedition = this.getExpeditionApiClient();
    const res: ExpeditionStatusResponse = await appExpedition.getStatusExpeditionByRef(reference);

    return res;
  }

  static async filterExpeditionsByLastMessage(pageNum: number, pageSize: number): Promise<PageableShipment> {
    const appApi = this.getAppApiClient();
    const response = await appApi.filterExpeditionsByLastMessage(pageNum, pageSize);

    return response;
  }
}
