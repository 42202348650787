import { Box, Grid, StepContent, Typography, makeStyles } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { AppointmentAvailabilityResponse, ExpeditionStatusResponse } from "../../../client";
import { Step as IStep } from "../../../client/models/Step";
import { InfoBlueCard } from "./InfoBlueCard";
import TrackingLine from "./TrackingLine";

const useStyles = makeStyles((theme) => ({
  trackingBox: {
    // flexGrow: 1,
    overflow: "auto",
    // maxHeight: "85vh",
    [theme.breakpoints.up("xs")]: {
      height: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      height: "85vh",
    },
  },
}));

export interface ITrackingComponentProps {
  managerName?: string;
  stepContent?: IStep;
  activeStep: number;
  selectedRef?: ExpeditionStatusResponse;
  eta?: string;
  deliveryType?: string;
  isAdmin?: boolean;
  appointmentAvailabilityResponse?: AppointmentAvailabilityResponse;
}

const TrackingComponent: React.FunctionComponent<ITrackingComponentProps> = (props: ITrackingComponentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  React.useEffect(() => {
    setStepContent(props.stepContent);
  }, [props.stepContent]);

  React.useEffect(() => {
    setActiveStep(props.activeStep);
  }, [props.activeStep]);

  const [activeStep, setActiveStep] = React.useState<number>(-1);
  const [stepContent, setStepContent] = React.useState<IStep>();

  const handleStepButton = (step: number, refStep: IStep) => {
    handleStep(step, refStep);
  };

  const handleStep = (step: number, refStep: IStep) => {
    let tempStemp = activeStep === step ? -1 : step;
    setStepContent(refStep);
    setActiveStep(tempStemp);
  };

  const buildDeliveryType = (type: string): string => {
    let delivery = "";
    switch (type) {
      case "APC":
        delivery = t("trackingView.deliveryType.APC");
        break;
      case "DCM":
        delivery = t("trackingView.deliveryType.DCM");
        break;
      case "DSM":
        delivery = t("trackingView.deliveryType.DSM");
        break;
      case "EAT":
        delivery = t("trackingView.deliveryType.EAT");
        break;
      case "EEP":
        delivery = t("trackingView.deliveryType.EEP");
        break;
      default:
        delivery = type;
        break;
    }
    return delivery;
  };

  return (
    <Box className={classes.trackingBox}>
      <Grid container item xs={12} justify="space-between" style={{ minHeight: 100 }}>
        <InfoBlueCard title={t("trackingView.ref")} description={props.selectedRef?.shipment?.shipmentExp} />
        <InfoBlueCard title={t("trackingView.eta")} description={props.eta} eta={props.eta} />
        <InfoBlueCard title={t("trackingView.manager")} description={props.managerName} />
        <InfoBlueCard
          title={t("trackingView.precita")}
          description={
            props.appointmentAvailabilityResponse?.availableTimeframes?.length !== 0
              ? t("trackingView.available")
              : t("trackingView.unavailable")
          }
          isPre={true}
          appointmentAvailabilityResponse={props.appointmentAvailabilityResponse}
        />
      </Grid>

      {/* {props.selectedRef?.steps && !props.isAdmin && (
        <Typography style={{ fontWeight: "bold", marginLeft: "10px" }}>{t("trackingView.moreInfo")}</Typography>
      )} */}
      {/* {!props.isAdmin && (
        <Grid container>
          <Typography style={{ fontWeight: "bold", marginLeft: "10px" }}>{t("trackingView.schedule")}</Typography>
          <Grid item style={{ marginLeft: "10px" }}>
            <Typography>Lunes a Viernes 9:00h a 17:00h</Typography>
          </Grid>
        </Grid>
      )} */}
      {props.isAdmin && props.deliveryType && (
        <Grid container>
          <Typography style={{ fontWeight: "bold", marginLeft: "10px" }}>{t("trackingView.deliveryType")}</Typography>
          <Grid item style={{ marginLeft: "10px" }}>
            <Typography>{buildDeliveryType(props.deliveryType)}</Typography>
          </Grid>
        </Grid>
      )}
      <Stepper style={{ padding: 0 }} nonLinear activeStep={activeStep} orientation="vertical">
        {props.selectedRef?.steps &&
          props.selectedRef?.steps.map((refStep: IStep, index) => (
            <Step key={refStep.shipmentExp! + index} onClick={() => handleStepButton(index, refStep)}>
              {/* <StepButton onClick={() => handleStepButton(index, refStep)}> */}
              {<TrackingLine trackingLine={refStep} child={false} activeStep={activeStep === index} />}
              {/* </StepButton> */}
              <StepContent>
                {stepContent && stepContent.subSteps && stepContent.subSteps.length !== 0 ? (
                  stepContent.subSteps.map((refChildStep: IStep, index) => <TrackingLine trackingLine={refChildStep} child={true} />)
                ) : (
                  <Typography>{t("trackingAdminView.NoChildren")}</Typography>
                )}
              </StepContent>
            </Step>
          ))}
      </Stepper>
    </Box>
  );
};
export default observer(TrackingComponent);
