import React, { CSSProperties, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { PickersDay } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AppointmentAvailabilityResponse, AppointmentRequest, CustomerTimeFrame } from "../../../../client";
import { Badge, CircularProgress, useMediaQuery } from "@mui/material";
import PostalAreaViewModel from "../../../../viewmodels/PostalAreaViewModel";
import { enqueueSnackbar } from "notistack";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { OwnColors } from "../../../../commons/Constants";
import { DialogHeader } from "./DialogHeader";
import { DialogBody } from "./DialogBody";

export const PreAppointmentDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  availableAppointments?: AppointmentAvailabilityResponse;
}> = observer(({ open, handleClose, availableAppointments }) => {
  const { t } = useTranslation();
  const postalAreaVM = PostalAreaViewModel.getInstance();
  const [highlightedDays, setHighlightedDays] = useState<number[]>([]);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(dayjs());
  const [initialDate, setInitialDate] = useState<dayjs.Dayjs>(dayjs());
  const [localTimeframes, setLocalTimeframes] = useState<CustomerTimeFrame[]>([]);
  const [currentMonth, setCurrentMonth] = useState(0);
  const [availableDays, setAvailableDays] = useState<Map<number, number[]>>(new Map());
  const [appointment, setAppointment] = useState<AppointmentRequest>();
  const [selectedDay, setSelectedDay] = useState(0);

  const handleChangeTimeframes = (timeframe: CustomerTimeFrame) => {
    setAppointment({
      appointmentDate: parseInt(selectedDate.format('YYYYMMDD')),
      expAlb: availableAppointments?.expAlb,
      phoneNumber: availableAppointments?.phone,
      timeframeId: timeframe.id,
    });
  };

  const calendarDayHandler = (props: any & { highlighted: number[] }) => {
    let backgroundColor: string;
    const { highlightedDays, ...others } = props;
    const currentDay = props.day.date();
    const isAvailable: boolean = highlightedDays.includes(currentDay);
    const isSelected: boolean = isAvailable && selectedDay === currentDay;

    if (isSelected) {
      backgroundColor = OwnColors.mobelBlue;
    } else if (isAvailable) {
      backgroundColor = OwnColors.lightBlue;
    } else {
      backgroundColor = "transparent";
    }

    const dayStyle: CSSProperties = {
      backgroundColor: backgroundColor,
      borderRadius: 10,
    };

    return (
      <Badge>
        <PickersDay {...others} disabled={!isAvailable} style={dayStyle} onDaySelect={handleDaySelect} />
      </Badge>
    );
  };

  const create = async () => {
    if (!appointment?.timeframeId) {
      enqueueSnackbar(t("postalarea.dialog.preappointment.create.noframe"), { variant: "info" });
      return;
    }

    const isSuccessfull = await postalAreaVM.bookAppointment(appointment);
    isSuccessfull
      ? enqueueSnackbar(t("postalarea.dialog.preappointment.create.ok"), { variant: "success" })
      : enqueueSnackbar(t("postalarea.dialog.preappointment.create.nok"), { variant: "error" });
    handleClose();
  };

  useEffect(() => {
    const firstItemDate = availableAppointments?.availableTimeframes?.[0]?.date;
    const parsedDate = dayjs(firstItemDate);
    const available = new Map<number, number[]>();

    availableAppointments?.availableTimeframes?.forEach((a) => {
      const date = new Date(a.date ?? 0);
      const month = date.getMonth();

      if (!available.has(month)) {
        available.set(month, []);
      }

      available.get(month)?.push(date.getDate());
    });

    setAvailableDays(available);
    setCurrentMonth(parsedDate.month());
    setInitialDate(parsedDate);
  }, [availableAppointments]);

  useEffect(() => {
    const dates = availableDays.get(currentMonth) ?? [];
    setHighlightedDays(dates);
  }, [currentMonth, availableDays]);

  const handleChange = (month: dayjs.Dayjs) => setCurrentMonth(month.month());

  const handleDaySelect = (day: unknown) => {
    const date = dayjs(day as Dayjs);
    const availableAppointment = availableAppointments?.availableTimeframes?.find((a) => {
      const appointmentDate = dayjs(a.date ?? 0);
      return date.isSame(appointmentDate, "day") ? a : undefined;
    });

    setAppointment(undefined);
    setSelectedDate(date);
    setSelectedDay(date.date());
    setLocalTimeframes(availableAppointment?.frames ?? []);
  };
  const isDesktop = useMediaQuery("(min-width:450px)");
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={!isDesktop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogHeader handleClose={handleClose} isLoading={postalAreaVM.isLoading} />

      <DialogBody
        calendarDayHandler={calendarDayHandler}
        handleChange={handleChange}
        handleChangeTimeframes={handleChangeTimeframes}
        appointment={appointment}
        highlightedDays={highlightedDays}
        initialDate={initialDate}
        localTimeframes={localTimeframes}
        selectedDate={selectedDate}
      />

      <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleClose} disabled={postalAreaVM.isLoading}>
          {t("postalarea.dialog.postalarea.cancel")}
        </Button>
        {postalAreaVM.isLoading ? (
          <div style={{ display: "flex", justifyContent: "start" }}>
            <CircularProgress size={25} style={{ marginRight: "1rem" }} />
          </div>
        ) : (
          <Button style={{ background: OwnColors.lightGreen, color: "white" }} onClick={create}>
            {t("postalarea.dialog.postalarea.create")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});
