import { DialogContent, DialogContentText, Typography, Grid } from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { DateCalendar, LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { AppointmentRequest, CustomerTimeFrame } from "../../../../client";
import { getRamdomNumber } from "../../../../commons/Utils";
import { bodyStyles } from "./Dialog.styles";
import { useMediaQuery } from "@mui/material";
import { OwnColors } from "../../../../commons/Constants";
import { AccessTime, CalendarToday } from "@material-ui/icons";

interface DialogBodyProps {
  appointment?: AppointmentRequest;
  initialDate?: Dayjs;
  highlightedDays?: number[];
  localTimeframes?: CustomerTimeFrame[];
  selectedDate?: Dayjs;
  handleChange: (month: Dayjs) => void;
  handleChangeTimeframes: (timeframe: CustomerTimeFrame) => void;
  calendarDayHandler: (props: any & { hihghlighted: number[] }) => JSX.Element;
}

export const DialogBody: React.FC<DialogBodyProps> = ({
  appointment,
  calendarDayHandler,
  handleChange,
  handleChangeTimeframes,
  highlightedDays,
  initialDate,
  localTimeframes = [],
  selectedDate,
}) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language.substring(0, 2);
  const isDesktop = useMediaQuery("(min-width:450px)");
  const { dialogContent, timeframeItem, mainContainer, noTimeframesMessage, selectedTimeFrameContainer, timeframesContainer } =
    bodyStyles;

  const formatSelectedFrameDate = (frame?: CustomerTimeFrame, date?: Dayjs): JSX.Element => (
    <div style={selectedTimeFrameContainer}>
      <Typography align="center">
        {t("postalarea.dialog.preappointment.description.delivery")}
        {date ? date?.format("DD/MM/YYYY") : <CalendarToday />}
      </Typography>
      <Typography align="center">
        {t("postalarea.dialog.preappointment.description.delivery2").toLowerCase()}
          {frame?.startTime || <AccessTime style={{height: "1rem"}}/>} 
          {t("postalarea.dialog.preappointment.description.delivery3")} 
          {frame?.endTime || <AccessTime style={{height: "1rem"}}/>}
      </Typography>
    </div>
  );

  return (
    <DialogContent style={dialogContent}>
      <DialogContentText align="center" id="alert-dialog-description">
        {t("postalarea.dialog.preappointment.description")}
      </DialogContentText>
      <Grid container style={mainContainer}>
        <Grid container item xs={12} md={8} style={{ justifyContent: "center" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
            <DateCalendar
              views={["day"]}
              referenceDate={initialDate}
              onMonthChange={handleChange}
              slotProps={{ day: { highlightedDays } as any }}
              slots={{ day: calendarDayHandler }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4} style={timeframesContainer}>
          <Typography>{t("postalarea.dialog.preappointment.frames.title")}</Typography>
          {localTimeframes?.length > 0 ? (
            localTimeframes.map((i) => (
              <div
                key={getRamdomNumber(0, 11111)}
                style={{
                  ...timeframeItem,
                  backgroundColor: appointment?.timeframeId === i.id ? OwnColors.mobelBlue : "white",
                  color: appointment?.timeframeId === i.id ? "white" : "black",
                  border: appointment?.timeframeId === i.id ? "" : "1px solid #0F407A",
                }}
                onClick={() => handleChangeTimeframes(i)}
                onKeyDown={(e) => e.key === "Enter" && handleChangeTimeframes(i)}
              >
                {t("postalarea.dialog.preappointment.description.delivery2")} 
                {i.startTime || <AccessTime />} 
                {t("postalarea.dialog.preappointment.description.delivery3")} 
                {i.endTime || <AccessTime />}
              </div>
            ))
          ) : (
            <Typography style={noTimeframesMessage}>{t("postalarea.dialog.preappointment.description.noTimeframes")}</Typography>
          )}
        </Grid>
      </Grid>
      {formatSelectedFrameDate(
        localTimeframes.find((frame) => frame.id === appointment?.timeframeId),
        selectedDate
      )}
    </DialogContent>
  );
};
