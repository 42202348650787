import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { OwnColors } from "../../../commons/Constants";
import { PreAppointmentDialog } from "./PreAppointmentDialog/PreAppointmentDialog";
import { AppointmentAvailabilityResponse } from "../../../client";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { dayJSFromString } from "../../../commons/Utils";

type InfoBlueCardProps = {
  title: string;
  description?: string;
  isPre?: boolean;
  eta?: string;
  appointmentAvailabilityResponse?: AppointmentAvailabilityResponse;
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: ".2rem",
    minHeight: "7rem",
  },
  title: {
    color: "white",
    letterSpacing: "0px",
    textTransform: "uppercase",
    fontFamily: "normal normal normal 12px/16px Roboto",
    fontSize: "12px",
    textAlign: "center"
  },
  description: {
    color: "white",
    fontWeight: "bold",
    margin: "5px",
    letterSpacing: "0px",
    textTransform: "uppercase",
    fontFamily: "normal normal normal 12px/16px Roboto",
    fontSize: "12px",
    textAlign: "center"
  },
  eta: {
    color: "white",
    fontWeight: "bold",
    letterSpacing: "0px",
    textTransform: "uppercase",
    fontFamily: "normal normal normal 12px/16px Roboto",
    fontSize: "12px",
    textAlign: "center"
  },
}));

export const InfoBlueCard: React.FC<InfoBlueCardProps> = ({
  title,
  description = "",
  isPre = undefined,
  eta,
  appointmentAvailabilityResponse,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {isPre ? (
        <Grid item xs={6} md={3} className={styles.container} style={{ display: "flex" }}>
          <Button
            fullWidth
            disabled={
              appointmentAvailabilityResponse === undefined ||
              (appointmentAvailabilityResponse.availableTimeframes?.length === 0) === true
            }
            onClick={() => {
              if (
                appointmentAvailabilityResponse === undefined ||
                (appointmentAvailabilityResponse.availableTimeframes?.length === 0) === true
              )
                return;

              setOpen(true);
            }}
            style={{
              minHeight: "5rem",
              flex: 1,
              backgroundColor: appointmentAvailabilityResponse?.availableTimeframes?.length
                ? OwnColors.success
                : OwnColors.unavailable,
              height: "100%",
            }}
          >
            <Grid
              item
              justify="center"
              alignContent="center"
              style={{
                height: "100%",
                borderRadius: "6px",
                padding: "5px",
                flexDirection: "column",
              }}
            >
              <Typography className={styles.title}>{title}</Typography>
              <Typography className={eta ? styles.eta : styles.description}>{description}</Typography>
            </Grid>
          </Button>
        </Grid>
      ) : (
        <Grid alignContent="center" justify="center" item xs={6} md={3} className={styles.container} style={{ display: "flex" }}>
          <Grid
            container
            justify="center"
            alignContent="center"
            style={{
              minHeight: "5rem",
              flex: 1,
              background: OwnColors.mobelBlue,
              height: "100%",
              borderRadius: "6px",
              padding: "5px",
              flexDirection: "column",
            }}
          >
            <Typography className={styles.title}>{title}</Typography>
            <Typography className={eta ? styles.eta : styles.description}>
              {eta && eta !== "Fecha no disponible" && dayJSFromString(eta).isValid()
                ? `${t("trackingView.between")} ${dayJSFromString(eta).format("DD-MM-YYYY")} ${t(
                    "trackingView.between.and"
                  )} ${dayJSFromString(eta).add(3, "d").format("DD-MM-YYYY")}`
                : description}
            </Typography>
          </Grid>
        </Grid>
      )}

      {open ? (
        <PreAppointmentDialog open={true} handleClose={handleClose} availableAppointments={appointmentAvailabilityResponse} />
      ) : null}
    </>
  );
};
