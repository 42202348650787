/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ExpeditionStatusResponse,
    ExpeditionStatusResponseFromJSON,
    ExpeditionStatusResponseToJSON,
    Message,
    MessageFromJSON,
    MessageToJSON,
    PageableShipment,
    PageableShipmentFromJSON,
    PageableShipmentToJSON,
} from '../models';

export interface CreateMessageRequest {
    message?: Message;
}

export interface DeleteMessageByIdRequest {
    id: number;
}

export interface FilterExpeditionsByLastMessageRequest {
    pageNum: number;
    pageSize: number;
}

export interface GetAdminExpeditionsRequest {
    pageNum: number;
    pageSize: number;
}

export interface GetMessageByIdRequest {
    id: number;
}

export interface GetMessagesFromExpeditionRequest {
    ref: string;
}

export interface GetStatusExpeditionRequest {
    ref: string;
    phone: string;
}

export interface GetUnAnsweredAdminExpeditionsRequest {
    pageNum: number;
    pageSize: number;
}

export interface UpdateMessageByIdRequest {
    id: number;
    message?: Message;
}

/**
 * no description
 */
export class AppApi extends runtime.BaseAPI {

    /**
     * createMessage
     * createMessage
     */
    async createMessageRaw(requestParameters: CreateMessageRequest): Promise<runtime.ApiResponse<Message>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/messages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MessageToJSON(requestParameters.message),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageFromJSON(jsonValue));
    }

    /**
     * createMessage
     * createMessage
     */
    async createMessage(message?: Message): Promise<Message> {
        const response = await this.createMessageRaw({ message: message });
        return await response.value();
    }

    /**
     * deleteMessageById
     * deleteMessageById
     */
    async deleteMessageByIdRaw(requestParameters: DeleteMessageByIdRequest): Promise<runtime.ApiResponse<ErrorResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMessageById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/messages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ErrorResponseFromJSON(jsonValue));
    }

    /**
     * deleteMessageById
     * deleteMessageById
     */
    async deleteMessageById(id: number): Promise<ErrorResponse> {
        const response = await this.deleteMessageByIdRaw({ id: id });
        return await response.value();
    }

    /**
     * Filters all expeditions by the timestamp in which last message was received
     * filterExpeditionsByLastMessage
     */
    async filterExpeditionsByLastMessageRaw(requestParameters: FilterExpeditionsByLastMessageRequest): Promise<runtime.ApiResponse<PageableShipment>> {
        if (requestParameters.pageNum === null || requestParameters.pageNum === undefined) {
            throw new runtime.RequiredError('pageNum','Required parameter requestParameters.pageNum was null or undefined when calling filterExpeditionsByLastMessage.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling filterExpeditionsByLastMessage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageNum !== undefined) {
            queryParameters['pageNum'] = requestParameters.pageNum;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/expeditions/filter/lastMessage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableShipmentFromJSON(jsonValue));
    }

    /**
     * Filters all expeditions by the timestamp in which last message was received
     * filterExpeditionsByLastMessage
     */
    async filterExpeditionsByLastMessage(pageNum: number, pageSize: number): Promise<PageableShipment> {
        const response = await this.filterExpeditionsByLastMessageRaw({ pageNum: pageNum, pageSize: pageSize });
        return await response.value();
    }

    /**
     * List all the expeditions followed .
     * getAdminExpeditions
     */
    async getAdminExpeditionsRaw(requestParameters: GetAdminExpeditionsRequest): Promise<runtime.ApiResponse<PageableShipment>> {
        if (requestParameters.pageNum === null || requestParameters.pageNum === undefined) {
            throw new runtime.RequiredError('pageNum','Required parameter requestParameters.pageNum was null or undefined when calling getAdminExpeditions.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getAdminExpeditions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageNum !== undefined) {
            queryParameters['pageNum'] = requestParameters.pageNum;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/expeditions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableShipmentFromJSON(jsonValue));
    }

    /**
     * List all the expeditions followed .
     * getAdminExpeditions
     */
    async getAdminExpeditions(pageNum: number, pageSize: number): Promise<PageableShipment> {
        const response = await this.getAdminExpeditionsRaw({ pageNum: pageNum, pageSize: pageSize });
        return await response.value();
    }

    /**
     * List all the messages
     * getAllMessages
     */
    async getAllMessagesRaw(): Promise<runtime.ApiResponse<Array<Message>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MessageFromJSON));
    }

    /**
     * List all the messages
     * getAllMessages
     */
    async getAllMessages(): Promise<Array<Message>> {
        const response = await this.getAllMessagesRaw();
        return await response.value();
    }

    /**
     * Get Message by Id
     * getMessageById
     */
    async getMessageByIdRaw(requestParameters: GetMessageByIdRequest): Promise<runtime.ApiResponse<Message>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMessageById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/messages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageFromJSON(jsonValue));
    }

    /**
     * Get Message by Id
     * getMessageById
     */
    async getMessageById(id: number): Promise<Message> {
        const response = await this.getMessageByIdRaw({ id: id });
        return await response.value();
    }

    /**
     * getMessagesFromExpedition
     * getMessagesFromExpedition
     */
    async getMessagesFromExpeditionRaw(requestParameters: GetMessagesFromExpeditionRequest): Promise<runtime.ApiResponse<Array<Message>>> {
        if (requestParameters.ref === null || requestParameters.ref === undefined) {
            throw new runtime.RequiredError('ref','Required parameter requestParameters.ref was null or undefined when calling getMessagesFromExpedition.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.ref !== undefined) {
            queryParameters['ref'] = requestParameters.ref;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/expedition/messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MessageFromJSON));
    }

    /**
     * getMessagesFromExpedition
     * getMessagesFromExpedition
     */
    async getMessagesFromExpedition(ref: string): Promise<Array<Message>> {
        const response = await this.getMessagesFromExpeditionRaw({ ref: ref });
        return await response.value();
    }

    /**
     * getStatusExpedition
     * getStatusExpedition
     */
    async getStatusExpeditionRaw(requestParameters: GetStatusExpeditionRequest): Promise<runtime.ApiResponse<ExpeditionStatusResponse>> {
        if (requestParameters.ref === null || requestParameters.ref === undefined) {
            throw new runtime.RequiredError('ref','Required parameter requestParameters.ref was null or undefined when calling getStatusExpedition.');
        }

        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling getStatusExpedition.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.ref !== undefined) {
            queryParameters['ref'] = requestParameters.ref;
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/status/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExpeditionStatusResponseFromJSON(jsonValue));
    }

    /**
     * getStatusExpedition
     * getStatusExpedition
     */
    async getStatusExpedition(ref: string, phone: string): Promise<ExpeditionStatusResponse> {
        const response = await this.getStatusExpeditionRaw({ ref: ref, phone: phone });
        return await response.value();
    }

    /**
     * List all the unanswered expeditions.
     * getUnAnsweredAdminExpeditions
     */
    async getUnAnsweredAdminExpeditionsRaw(requestParameters: GetUnAnsweredAdminExpeditionsRequest): Promise<runtime.ApiResponse<PageableShipment>> {
        if (requestParameters.pageNum === null || requestParameters.pageNum === undefined) {
            throw new runtime.RequiredError('pageNum','Required parameter requestParameters.pageNum was null or undefined when calling getUnAnsweredAdminExpeditions.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getUnAnsweredAdminExpeditions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageNum !== undefined) {
            queryParameters['pageNum'] = requestParameters.pageNum;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/unAnsweredExpeditions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableShipmentFromJSON(jsonValue));
    }

    /**
     * List all the unanswered expeditions.
     * getUnAnsweredAdminExpeditions
     */
    async getUnAnsweredAdminExpeditions(pageNum: number, pageSize: number): Promise<PageableShipment> {
        const response = await this.getUnAnsweredAdminExpeditionsRaw({ pageNum: pageNum, pageSize: pageSize });
        return await response.value();
    }

    /**
     * updateMessageById
     * updateMessageById
     */
    async updateMessageByIdRaw(requestParameters: UpdateMessageByIdRequest): Promise<runtime.ApiResponse<Message>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMessageById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/messages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MessageToJSON(requestParameters.message),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageFromJSON(jsonValue));
    }

    /**
     * updateMessageById
     * updateMessageById
     */
    async updateMessageById(id: number, message?: Message): Promise<Message> {
        const response = await this.updateMessageByIdRaw({ id: id, message: message });
        return await response.value();
    }

}
