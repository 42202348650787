/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MinimalTimeFrameDTO,
    MinimalTimeFrameDTOFromJSON,
    MinimalTimeFrameDTOFromJSONTyped,
    MinimalTimeFrameDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface SpecificDayDTO
 */
export interface SpecificDayDTO {
    /**
     * 
     * @type {string}
     * @memberof SpecificDayDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecificDayDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecificDayDTO
     */
    postalCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SpecificDayDTO
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SpecificDayDTO
     */
    date?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SpecificDayDTO
     */
    workDay?: boolean;
    /**
     * 
     * @type {Array<MinimalTimeFrameDTO>}
     * @memberof SpecificDayDTO
     */
    timeFrames?: Array<MinimalTimeFrameDTO>;
}

export function SpecificDayDTOFromJSON(json: any): SpecificDayDTO {
    return SpecificDayDTOFromJSONTyped(json, false);
}

export function SpecificDayDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecificDayDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'workDay': !exists(json, 'workDay') ? undefined : json['workDay'],
        'timeFrames': !exists(json, 'timeFrames') ? undefined : ((json['timeFrames'] as Array<any>).map(MinimalTimeFrameDTOFromJSON)),
    };
}

export function SpecificDayDTOToJSON(value?: SpecificDayDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'postalCode': value.postalCode,
        'active': value.active,
        'date': value.date,
        'workDay': value.workDay,
        'timeFrames': value.timeFrames === undefined ? undefined : ((value.timeFrames as Array<any>).map(MinimalTimeFrameDTOToJSON)),
    };
}


