import { Chat, ChatApi } from "../../client";
import { AppApi } from "../../client/apis/AppApi";
import { Message } from "../../client/models/Message";
import { ApiClient, Api } from "./ApiClient";

export default class ShipmentRepository {

    private static getAppApiClient() {
        return ApiClient.getClient(Api.AppApi) as AppApi;
    }

    private static getChatApiClient() {
        return ApiClient.getClient(Api.ChatApi) as ChatApi;
    }

    static async getChatHistory(ref: string): Promise<Message[]> {
        const appApi = this.getAppApiClient();
        return await appApi.getMessagesFromExpedition(ref).catch(e => []);
    }

    static async sendChat(msg: Message): Promise<Message> {
        const appApi = this.getAppApiClient();
        const message: Message = await appApi.createMessage(msg);

        return message;
    }

    static async updateChat(chat: Chat): Promise<Chat> {
        const chatApi = this.getChatApiClient();
        const updatedChat = await chatApi.updateChat(chat);

        return updatedChat;
    }
}