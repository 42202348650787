import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { AddCircleOutline, Close } from "@material-ui/icons";
import { Box, Chip, CircularProgress, Divider, Grid, Switch, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { PostalAreaDTO } from "../../../client";
import { useTranslation } from "react-i18next";
import { OwnColors } from "../../../commons/Constants";

interface IPostalAreaDialogProps {
  handleClose: () => void;
  open: boolean;
  isLoading: boolean;
  postalArea: PostalAreaDTO;
  edit: (pa: PostalAreaDTO) => Promise<void>;
  create: (pa: PostalAreaDTO) => Promise<void>;
}

const COLUMN_MIN_HEIGHT = 5;
const NUMBER_OF_COLUMNS = 6;

export const PostalAreaCreateDialog: React.FC<IPostalAreaDialogProps> = observer(
  ({ handleClose, open, postalArea, edit, create, isLoading }) => {
    const [pa, setPA] = useState<PostalAreaDTO>(postalArea);
    const [newPostalCode, setNewPostalCode] = useState<string>();
    const [postalCodesTable, setPostalCodesTable] = useState<Array<Array<string>>>([]);

    useEffect(() => {
      setPA(postalArea);
      console.log("postalArea")
      console.log(JSON.stringify(postalArea))
    }, [postalArea]);

    useEffect(() => {
      setPostalCodesTable(buildPostalCodesTableMap());
    }, [pa]);

    const handleDeletePostalCode = (deletingPC: string) => {
      setPA({ ...pa, postalCodes: pa.postalCodes?.filter((pc) => pc !== deletingPC) });
    };

    const handleAddPC = () => {
      if (newPostalCode)
        setPA({
          ...pa,
          postalCodes: [...(pa.postalCodes || []), newPostalCode].filter((item, index, stringArray) => {
            return stringArray.indexOf(item) === index;
          }),
        });
      setNewPostalCode("");
    };
    const editing = postalArea.id !== undefined;
    const { t } = useTranslation();

    const buildPostalCodesTableMap = (): Array<Array<string>> => {
      let tableList = new Array<Array<string>>();
      if (pa.postalCodes?.length && pa.postalCodes?.length > 0) {
        let currentHeight = (pa.postalCodes?.length || 0) / NUMBER_OF_COLUMNS;
        if (currentHeight > NUMBER_OF_COLUMNS) {
          currentHeight = Math.ceil(currentHeight);
        } else {
          currentHeight = COLUMN_MIN_HEIGHT;
        }

        let postalCodes = [...pa.postalCodes].map((i) => i.toString()).sort((a: string, b: string) => parseInt(a) - parseInt(b));

        while (postalCodes.length >= currentHeight) {
          tableList.push(postalCodes.splice(0, currentHeight));
        }
        if (postalCodes.length > 0) {
          tableList.push([...postalCodes]);
        }
      }
      return tableList;
    };

    const handleConfirmButtonClick = () => (postalArea.id ? edit(pa) : create(pa));

    const handleNumericChange = (value: string, field: string) => {
      let numValue = Number(value);
      if (!isNaN(numValue) && numValue >= 0) setPA({ ...pa, [field]: numValue });
    };

    return (
      <Dialog
        disableBackdropClick
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: OwnColors.mobelBlue,
            height: "46px",
            textAlign: "start",
            padding: "6px",
          }}
        >
          <DialogTitle
            style={{
              display: "flex",
              color: "white",
            }}
            id="alert-dialog-title"
          >
            {postalArea.id ? t("postalarea.dialog.postalarea.title.edit") + postalArea?.name : t("postalarea.dialog.postalarea.title")}
          </DialogTitle>
          <IconButton
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disabled={isLoading}
            style={{ color: "white" }}
            onClick={handleClose}
          >
            {isLoading ? <Close style={{ color: "GrayText" }} /> : <Close />}
          </IconButton>
        </div>

        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={6} xl={2}>
              <Box style={{ marginBottom: "1rem" }}>
                <TextField
                  placeholder={t("postalarea.dialog.postal.area.placeholder")}
                  value={pa?.name ?? ""}
                  disabled={false}
                  onChange={(e) => setPA({ ...pa, name: e.target.value })}
                  variant="standard"
                  style={{ width: "100%" }}
                  label={t("postalarea.dialog.postal.area")}
                />
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                <TextField
                  type="number"
                  placeholder="1"
                  value={pa?.dayOffset}
                  variant="standard"
                  onChange={(e) => handleNumericChange(e.target.value, "dayOffset")}
                  style={{ width: "100%" }}
                  label={t("postalarea.dialog.day.offset")}
                />
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                <TextField
                  type="number"
                  placeholder="2"
                  value={pa?.dayLimit}
                  onChange={(e) => handleNumericChange(e.target.value, "dayLimit")}
                  variant="standard"
                  style={{ width: "100%" }}
                  label={t("postalarea.dialog.day.limit")}
                />
              </Box>
              <Box style={{ marginBottom: "1rem" }}>
                <label style={{ width: "8rem", paddingRight: "1rem" }}>{t("postalarea.dialog.active")}</label>
                <Switch color="primary" checked={pa?.active} onChange={(evt, checked) => setPA({ ...pa, active: checked })} />
              </Box>
            </Grid>

            <Grid item xs={0} md={1} xl={1} container direction="row" justifyContent="center" alignItems="center">
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={12} md={5} xl={9}>
              <Box style={{ marginBottom: "1rem", display: "flex" }}>
                <TextField
                  placeholder={t("postalarea.dialog.postal.code.placeholder")}
                  value={newPostalCode ?? ""}
                  disabled={false}
                  onChange={(e) => setNewPostalCode(e.target.value)}
                  variant="standard"
                  style={{ width: "7rem" }}
                  label={t("postalarea.dialog.postal.code")}
                  sx={{ marginRight: "1rem" }}
                />
                <Box sx={{ marginTop: "5px", display: "flex" }}>
                  <IconButton onClick={handleAddPC}>
                    <AddCircleOutline color="primary" />
                  </IconButton>
                </Box>
              </Box>
              <Box>
                <Grid container>
                  {postalCodesTable.map((columnGroup) => {
                    return (
                      <Grid item xs={2}>
                        {columnGroup.map((cp) => {
                          return (
                            <Chip
                              label={cp}
                              color="primary"
                              variant="outlined"
                              onDelete={() => {
                                handleDeletePostalCode(cp);
                              }}
                              sx={{ margin: "2px 0px 2px 0px" }}
                            />
                          );
                        })}
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={handleClose} disabled={isLoading}>
            {t("postalarea.dialog.postalarea.cancel")}
          </Button>
          {isLoading ? (
            <div style={{ display: "flex", justifyContent: "start" }}>
              <CircularProgress size={25} style={{ marginRight: "1rem" }} />
            </div>
          ) : (
            <Button style={{ backgroundColor: OwnColors.lightGreen, color: "white" }} onClick={handleConfirmButtonClick}>
              {postalArea.id ? t("postalarea.dialog.postalarea.save") : t("postalarea.dialog.postalarea.create")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
);
