import React from "react";
import { Grid, IconButton, DialogTitle } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { headerStyles } from "./Dialog.styles";

interface DialogHeaderProps {
  isLoading?: boolean;
  handleClose: () => void;
}

export const DialogHeader: React.FC<DialogHeaderProps> = ({ isLoading, handleClose }) => {
  const { t } = useTranslation();
  const { closeButton, dialogButton, dialogTitle, mainContainer } = headerStyles;

  return (
    <Grid container style={mainContainer}>
      <Grid item xs={10}>
        <DialogTitle style={dialogTitle} id="alert-dialog-title">
          {t("postalarea.dialog.preappointment.title")}
        </DialogTitle>
      </Grid>
      <Grid container item xs={2} style={{ justifyContent: "end" }}>
        <IconButton
          disableFocusRipple
          disableRipple
          disableTouchRipple
          style={dialogButton}
          disabled={isLoading}
          onClick={handleClose}
        >
          {isLoading ? <Close style={closeButton} /> : <Close />}
        </IconButton>
      </Grid>
    </Grid>
  );
};
