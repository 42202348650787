/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentRequest
 */
export interface AppointmentRequest {
    /**
     * 
     * @type {string}
     * @memberof AppointmentRequest
     */
    expAlb?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentRequest
     */
    timeframeId?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentRequest
     */
    appointmentDate?: number;
}

export function AppointmentRequestFromJSON(json: any): AppointmentRequest {
    return AppointmentRequestFromJSONTyped(json, false);
}

export function AppointmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expAlb': !exists(json, 'expAlb') ? undefined : json['expAlb'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'timeframeId': !exists(json, 'timeframeId') ? undefined : json['timeframeId'],
        'appointmentDate': !exists(json, 'appointmentDate') ? undefined : json['appointmentDate'],
    };
}

export function AppointmentRequestToJSON(value?: AppointmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expAlb': value.expAlb,
        'phoneNumber': value.phoneNumber,
        'timeframeId': value.timeframeId,
        'appointmentDate': value.appointmentDate,
    };
}


