import React, { useState } from "react";
import Draggable from "react-draggable";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Paper, makeStyles, FormControlLabel, Grid } from "@material-ui/core";
import { OwnColors } from "../../../commons/Constants";

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    backgroundColor:OwnColors.mobelBlue,
    color:'white',
    cursor: 'move'
  },
  dialogWidth: {
    width: 500
  },
  select: {
    paddingLeft: '5%',
    minWidth: '100%',
    maxWidth: '100%',
  },
  font: {
    marginTop:15,
    fontSize:'16px'
  },
  checkboxLabel: {
    fontSize: 14
  }
}));

export interface ConfirmDialogStrings {
  cancelText?: string | undefined,
  confirmText?: string,
  title: string,
  description: string,
}

export interface ConfirmDialogProps {
  open: boolean,
  strings: ConfirmDialogStrings,
  onClose: any,
  onConfirm?: any,
  cancelButton?:any
}

export default function ConfirmDialog(props:ConfirmDialogProps) {
  const classes = useStyles();
  const strings = props.strings;

  /* Component to make a draggable dialog */
  const PaperComponent = (props : any) =>{
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
  }

  return(

    <Dialog open={props.open === true ? true : false} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">      
      <DialogTitle id="draggable-dialog-title" className={classes.dialogTitle}> 
        {strings.title}
      </DialogTitle>

      <DialogContent className={classes.dialogWidth}>  
        <Grid container spacing={2} className={classes.select} >
          
          <Grid item xs={12} className={classes.font}>
            {strings.description}
          </Grid>  
         
        </Grid>    
      </DialogContent>

      {/* Dialog buttons */}
      <DialogActions>
        {strings.cancelText ? <Button onClick={() => {props.onClose()}} color="primary"> {strings.cancelText} </Button> : null}
        <Button onClick={() => {props.onConfirm()}} color="primary"> {strings.confirmText} </Button>
      </DialogActions>
    </Dialog>)
}