import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as Utils from "./../../commons/Utils";
import WelcomeView from "../../view/welcomeView/WelcomeView";
import TrackingView from "../../view/trackingView/TrackingView";
import AdminAccountView from "../../view/adminAccountView/AdminAccountView";
import AdminTrackingView from "../../view/adminTrackingView/AdminTrackingView";
import LoginView from "../../view/loginView/LoginView";
import AppSkeleton from "./../../view/components/skeleton/AppSkeleton";
import React from "react";
import ProtectedRoute from "./ProtectedRoute";
import PostalAreaView from "../../view/siteManager/plazaManagerPostalAreaView/PostalAreaView";
import PlazaManagerTimeZonesView from "../../view/siteManager/plazaManagerTimeZonesView/SiteManagerTimeZonesView";
import { PATHS, ROLES } from "../app-config";

interface IRouterProps {
  logged: boolean;
  currentLang: string;
}

export default function Router(routerProps: IRouterProps) {
  function welcomeView(props: any) {
    return <AppSkeleton component={<WelcomeView {...props} />} />;
  }
  function trackingView(props: any) {
    return <AppSkeleton component={<TrackingView {...props} />} />;
  }
  function adminTrackingView(props: any) {
    return <AppSkeleton component={<AdminTrackingView {...props} />} />;
  }
  function adminAccountView(props: any) {
    return <AppSkeleton component={<AdminAccountView {...props} />} />;
  }
  function plazaPreAppointmentManager(props: any) {
    return <AppSkeleton component={<PostalAreaView {...props} />} />;
  }
  function plazaTimezonesManager(props: any) {
    return <AppSkeleton component={<PlazaManagerTimeZonesView {...props} />} />;
  }
  function loginView(props: any) {
    return <AppSkeleton component={<LoginView {...props} />} />;
  }

  return (
    <BrowserRouter>
      <Switch>
        {Utils.scrollToTop()}
        <Route exact path={PATHS.root} component={welcomeView} {...routerProps} />
        <Route exact path={PATHS.tracking} component={trackingView} />
        <ProtectedRoute allowedProfiles={[ROLES.ADMIN, ROLES.SUPPORT]} exact path={PATHS.adminTracking} component={adminTrackingView} {...routerProps} />
        <ProtectedRoute allowedProfiles={[ROLES.ADMIN]} exact path={PATHS.adminAccount} component={adminAccountView} {...routerProps} />
        <ProtectedRoute
          allowedProfiles={[ROLES.TERRITORY_MANAGER]}
          exact
          path={PATHS.postalAreas}
          component={plazaPreAppointmentManager}
          {...routerProps}
        />
        <ProtectedRoute
          allowedProfiles={[ROLES.TERRITORY_MANAGER]}
          exact
          path={PATHS.timeFrames}
          component={plazaTimezonesManager}
          {...routerProps}
        />
        <Route exact path={PATHS.login} component={loginView} {...routerProps} />
      </Switch>
    </BrowserRouter>
  );
}
