import Toolbar from './Toolbar';
import MainDashboard from './MainDashboard';
import Footer from './Footer';
import { makeStyles } from '@material-ui/core';
import ShipmentViewModel from '../../../viewmodels/ShipmentViewModel';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
}));

export interface IAppSkeletonProps {
    component?: any
}

export default function AppSkeleton(props: IAppSkeletonProps) {
    const classes = useStyles();

    return <>
        <div className={classes.root}>
            <div style={{ display: "block" }}>
                <Toolbar />
                <MainDashboard component={props.component} {...props}/>
            </div>

            <Footer />
        </div>
    </>
}