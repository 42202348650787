import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Paper, makeStyles, FormControlLabel, Grid, fade } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OwnColors } from "../../../commons/Constants";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Phone from "@material-ui/icons/Phone"

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    backgroundColor: '#3f51b5',
    color: 'white',
    cursor: 'move'
  },
  dialogWidth: {
    //width: 500
  },
  select: {
    //paddingLeft: '5%',
    minWidth: '100%',
    maxWidth: '100%',
  },
  font: {
    marginTop: 15,
    fontSize: '16px'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    //marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    color: "#adadad",
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    color: "#adadad",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

export interface SearchDialogProps {
  open: boolean,
  searchRefText: string,
  searchRefTextError: boolean,
  handleSearchRefText: (text: string) => void,
  searchPhoneText: string,
  searchPhoneTextError: boolean,
  handleSearchPhoneText: (text: string) => void,
  onClose: () => void,
  onConfirm: () => void,
}

export default function SearchDialog(props: SearchDialogProps) {

  const classes = useStyles();
  const { t } = useTranslation();

  return (

    <Dialog open={props.open === true ? true : false} aria-labelledby="draggable-dialog-title">
      <DialogTitle id="draggable-dialog-title" className={classes.dialogTitle}>
        {t("search.dialog.title")}
      </DialogTitle>

      <DialogContent className={classes.dialogWidth}>
        <Grid container spacing={0} className={classes.select} >

          <Grid item xs={12} className={classes.font}>
            <div className={classes.search} style={{ backgroundColor: props.searchRefTextError ? OwnColors.redError : OwnColors.lightGrey }}>
              <div className={classes.searchIcon}>
                <SearchIcon
                />
              </div>
              <InputBase
                placeholder={t("search.dialog.reference")}
                error={props.searchRefTextError}
                value={props.searchRefText}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={(text) => {
                  props.handleSearchRefText(text.target.value);
                }}

                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            <div className={classes.search} style={{ backgroundColor: props.searchPhoneTextError ? OwnColors.redError : OwnColors.lightGrey, marginTop: 15 }}>
              <div className={classes.searchIcon}>
                <Phone
                />
              </div>
              <InputBase
                placeholder={t("search.dialog.phone")}
                error={props.searchPhoneTextError}
                value={props.searchPhoneText}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={(text) => {
                  props.handleSearchPhoneText(text.target.value);
                }}

                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Grid>

        </Grid>
      </DialogContent>

      {/* Dialog buttons */}
      <DialogActions>
        <Button onClick={() => { props.onClose() }} color="primary"> {t("search.dialog.cancel")} </Button>
        <Button onClick={() => { props.onConfirm() }} color="primary"> {t("search.dialog.confirm")} </Button>
      </DialogActions>
    </Dialog>)
}