import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UsersViewModel from "../../../viewmodels/UsersViewModel";
import ConfirmDialog from "../../components/commons/ConfirmDialog";
import ManagerNavigationTabs from "../../components/plazaManager/ManagerNavigationTabs";
import { PostalAreaTable } from "./PostalAreaTable";
import usePlazaManagerPreAppointmentStyles from "./PreAppointment.styles";
import PostalAreaViewModel from "../../../viewmodels/PostalAreaViewModel";
import { ROLES } from "../../../infrastructure/app-config";

const PostalAreaView: React.FunctionComponent = (props: any) => {
  const { t } = useTranslation();
  const vmUsers = UsersViewModel.getInstance();
  const postalAreaVM = PostalAreaViewModel.getInstance();
  const classes = usePlazaManagerPreAppointmentStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [confirmDialogOpen] = React.useState(false);
  const [confirmDialogStrings] = React.useState({
    localStorage: "ownerDelete",
    cancelText: t("federation.manageAssociations.dialog.text.cancel"),
    confirmText: t("federation.manageAssociations.dialog.text.delete"),
    title: t("federation.manageAssociations.dialog.title.delete"),
    description: t("federation.manageAssociations.dialog.text.confirmDelete"),
  });

  const getPostalAreas = useCallback(
    (pageNumber: number, pageSize: number) => {
      postalAreaVM.getPostalAreasForManagerPaginated(pageNumber + 1, pageSize);
    },
    [postalAreaVM]
  );

  const [confirmDialogFunc] = React.useState({
    onClose: () => {},
    onConfirm: () => {},
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setRowsPerPage(pageSize);
    setPage(0);
  };

  useEffect(() => {
    getPostalAreas(page, rowsPerPage);
  }, [getPostalAreas, page, rowsPerPage]);

  return (
    <div className={classes.contentUsers}>
      <Grid item xs={12}>
        {vmUsers.currentUser?.role === ROLES.TERRITORY_MANAGER && <ManagerNavigationTabs />}
      </Grid>
      <div style={{ marginTop: 10 }}>
        {vmUsers.users?.length === 0 ? (
          <div>
            <CircularProgress className={classes.circleProgress} />
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
            {
              <ConfirmDialog
                {...{
                  open: confirmDialogOpen,
                  strings: confirmDialogStrings,
                  onClose: confirmDialogFunc.onClose,
                  onConfirm: confirmDialogFunc.onConfirm,
                }}
              />
            }
            <div
              style={{
                width: "50rem",
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <PostalAreaTable
                getPostalAreas={getPostalAreas}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                currentItems={postalAreaVM.postalAreas}
                currentPage={page}
                rowsPerPage={rowsPerPage}
                totalItems={postalAreaVM.currentPage.totalItems}
              />
            </div>
          </div>
        )}
      </div>
      <Backdrop
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          position: "fixed",
          zIndex: 10,
        }}
        open={postalAreaVM.isLoading}
      />
    </div>
  );
};

export default observer(PostalAreaView);
