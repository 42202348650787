import { Grid, Slide, IconButton, Paper, Typography } from "@material-ui/core";
import { InfoOutlined, Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { informationPanelStyles } from "./informationPanelStyles";

interface IInformationPanelProps {
  panelVisible: boolean;
  dismissFn: () => void;
}

export const InformationPanel: React.FC<IInformationPanelProps> = ({ dismissFn, panelVisible }) => {
  const { t } = useTranslation();
  const { mainContainer, closeButton, infoIcon } = informationPanelStyles();

  return (
    <Slide direction="up" in={panelVisible} style={{ display: "flex" }}>
      <Paper className={mainContainer}>
        <Grid container>
          <Grid container item xs={1} justify="center">
            <InfoOutlined className={infoIcon} />
          </Grid>
          <Grid container item xs={10}>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>{t("information.panel.title")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{t("information.panel.text")}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={1} justify="flex-end" alignContent="flex-start">
            <IconButton onClick={dismissFn} className={closeButton}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </Slide>
  );
};
