import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";
import { CircularProgress, DialogContentText } from "@mui/material";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { OwnColors } from "../../../commons/Constants";

interface IDeleteDialogProps {
  open: boolean;
  handleClose: () => void;
  postalAreaId: string;
  remove: (id: string) => Promise<void>;
  isLoading: boolean;
}

export const DeletePostalAreaDialog: React.FC<IDeleteDialogProps> = observer(
  ({ open, handleClose, postalAreaId, isLoading, remove }) => {
    const [areaId, setAreaId] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
      setAreaId(postalAreaId);
    }, [postalAreaId]);

    const handleConfirmButtonClick = () => remove(areaId);

    return (
      <Dialog
        disableBackdropClick
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: OwnColors.mobelBlue,
            height: "46px",
            textAlign: "start",
            padding: "6px",
          }}
        >
          <DialogTitle
            style={{
              display: "flex",
              color: "white",
            }}
            id="alert-dialog-title"
          >
            {`${t("postalarea.deleteDialog.postalarea.title")} ${areaId}`}
          </DialogTitle>
          <IconButton
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disabled={isLoading}
            style={{ color: "white" }}
            onClick={handleClose}
          >
            {isLoading ? <Close style={{ color: "GrayText" }} /> : <Close />}
          </IconButton>
        </div>

        <DialogContent>
          <DialogContentText>{t("postalarea.deleteDialog.postalarea.description")}</DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={handleClose} disabled={isLoading}>
            {t("postalarea.dialog.postalarea.cancel")}
          </Button>
          {isLoading ? (
            <div style={{ display: "flex", justifyContent: "start" }}>
              <CircularProgress size={25} style={{ marginRight: "1rem" }} />
            </div>
          ) : (
            <Button style={{ background: OwnColors.lightGreen, color: "white" }} onClick={handleConfirmButtonClick}>
              {t("postalarea.dialog.postalarea.delete")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
);
