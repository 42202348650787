import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";
import { CircularProgress, Switch, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { OwnColors } from "../../../commons/Constants";
import PostalAreaViewModel from "../../../viewmodels/PostalAreaViewModel";
import { enqueueSnackbar } from "notistack";
import { observer } from "mobx-react-lite";
import { PostalAreaDTO, TimeFrameDTO } from "../../../client/models";
import { useTranslation } from "react-i18next";
const days = ["L", "M", "X", "J", "V", "S", "D"];

export const TimeframeCreationDialog: React.FC<{ handleClose: () => void; postalArea: PostalAreaDTO; timeframe: TimeFrameDTO }> =
  observer(({ handleClose, timeframe, postalArea }) => {
    const postalareaVm = PostalAreaViewModel.getInstance();
    const [workDays, setWorkDays] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [editingTimeframe, setEditingTimeframe] = useState<TimeFrameDTO>(timeframe);
    const { t } = useTranslation();

    const create = async () => {
      const isSuccessfull = await postalareaVm.addTimeFrameToPostalArea(postalArea.id!, editingTimeframe);
      isSuccessfull
        ? enqueueSnackbar(t("postalarea.dialog.common.creation.success"), { variant: "success" })
        : enqueueSnackbar(t("postalarea.dialog.common.failure"), { variant: "error" });
      handleClose();
    };

    const edit = async () => {
      const isSuccessfull = await postalareaVm.modifyTimeFrameFromPostalArea(postalArea.id!, editingTimeframe);
      isSuccessfull
        ? enqueueSnackbar(t("postalarea.dialog.common.edition.success"), { variant: "success" })
        : enqueueSnackbar(t("postalarea.dialog.common.failure"), { variant: "error" });
      handleClose();
    };

    useEffect(() => {
      setEditingTimeframe(timeframe);
    }, [timeframe]);

    useEffect(() => {
      if (timeframe?.workDays)
        setWorkDays(
          Array.from(timeframe.workDays?.toString().padStart(7, "0") ?? [])
            .map((i) => parseInt(i))
        );
    }, []);

    return (
      <Dialog
        open={true}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: OwnColors.mobelBlue,
            height: "46px",
            textAlign: "start",
            padding: "6px",
          }}
        >
          <DialogTitle
            style={{
              display: "flex",
              color: "white",
            }}
            id="alert-dialog-title"
          >
            {timeframe.id
              ? `${t("postalarea.dialog.timeframe.title.edit")} ${timeframe.name}`
              : t("postalarea.dialog.timeframe.title")}
          </DialogTitle>
          <IconButton
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disabled={postalareaVm.isLoading}
            style={{ color: "white" }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </div>

        <DialogContent>
          <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
              <label style={{ width: "8rem", paddingRight: "1rem" }}>{t("postalarea.dialog.timeframe.name")}</label>
              <TextField
                placeholder="Diurno"
                variant="standard"
                value={editingTimeframe.name}
                onChange={(evt) => setEditingTimeframe({ ...editingTimeframe, name: evt.target.value })}
                style={{ width: "20rem" }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
              <label style={{ width: "8rem", paddingRight: "1rem" }}>{t("postalarea.dialog.timeframe.start.time")}</label>
              <TextField
                placeholder="0"
                variant="standard"
                value={editingTimeframe.startTime}
                onChange={(evt) => setEditingTimeframe({ ...editingTimeframe, startTime: evt.target.value })}
                style={{ width: "6rem" }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
              <label style={{ width: "8rem", paddingRight: "1rem" }}>{t("postalarea.dialog.timeframe.end.time")}</label>
              <TextField
                placeholder="0"
                value={editingTimeframe.endTime}
                onChange={(evt) => setEditingTimeframe({ ...editingTimeframe, endTime: evt.target.value })}
                variant="standard"
                style={{ width: "6rem" }}
              />
            </div>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "1rem" }}>
              {workDays.map((i, index) => (
                <div
                  onClick={() => {
                    let copy = [...workDays];
                    copy[index] = copy[index] === 0 ? 1 : 0;
                    setWorkDays([...copy]);
                    setEditingTimeframe({ ...editingTimeframe, workDays: parseInt([...copy].join(""))});
                  }}
                  key={"day" + i + index}
                  style={{
                    color: i === 1 ? "white" : "black",
                    background: i === 1 ? OwnColors.mobelBlue : "white",
                    border: "1px solid #000",
                    width: "1.3rem",
                    borderRadius: "15px",
                    textAlign: "center",
                    margin: "5px",
                    cursor: "pointer",
                  }}
                >
                  {days[index]}
                </div>
              ))}
            </div>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
              <label style={{ width: "4rem", paddingRight: "1rem" }}>{t("postalarea.dialog.timeframe.active")}</label>
              <Switch
                checked={editingTimeframe?.active}
                onChange={(evt, isChecked) => setEditingTimeframe({ ...editingTimeframe, active: isChecked })}
                color="primary"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={handleClose} disabled={postalareaVm.isLoading}>
            {t("postalarea.dialog.postalarea.cancel")}
          </Button>
          {postalareaVm.isLoading ? (
            <div style={{ display: "flex", justifyContent: "start" }}>
              <CircularProgress size={25} style={{ marginRight: "1rem" }} />
            </div>
          ) : (
            <Button
              style={{ backgroundColor: OwnColors.lightGreen, color: "white" }}
              onClick={() => (timeframe.id ? edit() : create())}
            >
              {timeframe.id ? t("postalarea.dialog.postalarea.save") : t("postalarea.dialog.postalarea.create")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  });
