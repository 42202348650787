import { Typography } from "@material-ui/core";
import { CheckCircle, Clear, DeleteForever, Edit, RemoveCircle } from "@material-ui/icons";
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, styled, tableCellClasses } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import React, { useState } from "react";
import { OwnColors } from "../../../commons/Constants";
import { TimeframeCreationDialog } from "../../components/plazaManager/TimeZoneCreationDialog";
import { TimeFrameDTO } from "../../../client";
import { days } from "./SiteManagerTimeZonesView";
import { DeleteDPostalAreaTimeframeDialog } from "../../components/plazaManager/DeletePostalAreaTimeframeDialog";
import { useTranslation } from "react-i18next";
import PostalAreaViewModel from "../../../viewmodels/PostalAreaViewModel";
import { observer } from "mobx-react-lite";

const emptyTimeframe: TimeFrameDTO = {
  id: undefined,
  endTime: undefined,
  startTime: undefined,
  name: undefined,
  workDays: undefined,
  active: true,
};

export const TimeframeTable: React.FC<{}> = observer(() => {
  const vmPostalArea = PostalAreaViewModel.getInstance();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [timeframe, setTimeframe] = useState<TimeFrameDTO>(emptyTimeframe);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewTimeframe = () => {
    if (!vmPostalArea.currentPostalArea) return;
    setTimeframe({ ...emptyTimeframe });
    setOpen(true);
  };

  const handleEditTimeframe = (timeframe: TimeFrameDTO) => {
    setTimeframe(timeframe);
    setOpen(true);
  };

  const handleDeleteTimeframe = (timeframe: TimeFrameDTO) => {
    setTimeframe(timeframe);
    setOpenDelete(true);
  };

  return (
    <Paper>
      {openDelete ? (
        <DeleteDPostalAreaTimeframeDialog
          handleClose={() => setOpenDelete(false)}
          postalArea={vmPostalArea.currentPostalArea!}
          timeFrame={timeframe}
          open={openDelete}
        />
      ) : null}
      <TableContainer>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography style={{ margin: ".5rem" }}>{t("postalarea.timeframe.table.title")}</Typography>
          <Button
            variant="contained"
            onClick={handleNewTimeframe}
            style={{ width: "17rem", height: "2rem", marginBottom: ".5rem", background: OwnColors.mobelBlue }}
          >
            {t("postalarea.table.add.timeframe")}
          </Button>
        </div>
        <Table sx={{ width: "100%" }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width={"40%"}>{t("postalarea.timeframe.table.columns.name")}</StyledTableCell>
              <StyledTableCell width={"8%"}>{t("postalarea.timeframe.table.columns.active")}</StyledTableCell>
              <StyledTableCell width={"15%"} align="center">
                {t("postalarea.timeframe.table.columns.starthour")}
              </StyledTableCell>
              <StyledTableCell width={"15%"} align="center">
                {t("postalarea.timeframe.table.columns.endhour")}
              </StyledTableCell>
              <StyledTableCell width={"28%"} align="center">
                {t("postalarea.timeframe.table.columns.weekdays")}
              </StyledTableCell>
              <StyledTableCell width={"7%"} align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vmPostalArea.currentPostalArea?.timeFrames?.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.active ? (
                    <CheckCircle style={{ color: OwnColors.lightGreen }} />
                  ) : (
                    <Clear style={{ backgroundColor: OwnColors.lightRed, borderRadius: 15, color: "white" }} fontSize="small" />
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">{row.startTime}</StyledTableCell>
                <StyledTableCell align="center">{row.endTime}</StyledTableCell>
                <StyledTableCell align="center">
                  <div style={{ display: "flex", pointerEvents: "none" }}>
                    {Array.from(row.workDays?.toString().padStart(7, "0") ?? [])
                      ?.map((day: string, index) => (
                        <div
                          key={"day" + day + index}
                          style={{
                            color: day === "1" ? "white" : "black",
                            background: day === "1" ? OwnColors.mobelBlue : "white",
                            border: "0.5px solid #000",
                            width: "1.3rem",
                            borderRadius: "15px",
                            textAlign: "center",
                            margin: "1px",
                          }}
                        >
                          {days[index]}
                        </div>
                      ))}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <div style={{ display: "flex" }}>
                    <Edit
                      style={{ width: "1.3rem", marginRight: ".5rem", cursor: "pointer" }}
                      onClick={() => {
                        handleEditTimeframe(row);
                      }}
                    />
                    <DeleteForever style={{ width: "1.3rem", cursor: "pointer" }} onClick={() => handleDeleteTimeframe(row)} />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {open ? (
        <TimeframeCreationDialog timeframe={timeframe} handleClose={handleClose} postalArea={vmPostalArea.currentPostalArea!} />
      ) : null}
    </Paper>
  );
});

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: OwnColors.mobelBlue,
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f5f5f5",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
