import { makeStyles } from '@material-ui/core';
import '../../../commons/Constants'

const usePlazaManagerPreAppointmentStyles = makeStyles(theme => ({
    contentUsers: {
        marginTop: 54,
        [theme.breakpoints.up('sm')]: {
            marginTop: 64,
        },
    },
    circleProgress: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        position: 'fixed',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        zIndex: 10
    },
    actionButtons: {
        cursor: 'pointer'
    }
}));

export default usePlazaManagerPreAppointmentStyles;