import { makeAutoObservable } from "mobx";
import { ExpeditionStatusResponse, PageableShipment, Shipment } from "../client";
import ShipmentRepository from "../data/repositories/ShipmentRepository";

export default class ShipmentViewModel {
  static instance: ShipmentViewModel;

  static getInstance(): ShipmentViewModel {
    if (ShipmentViewModel.instance === undefined) {
      ShipmentViewModel.instance = new ShipmentViewModel();
    }
    return ShipmentViewModel.instance;
  }

  private constructor() {
    makeAutoObservable(this);
    this.loadAdminShipments = this.loadAdminShipments.bind(this);
    this.setIsLoadingInTable = this.setIsLoadingInTable.bind(this);
  }

  loadingShipment: boolean = false;
  isLoadingInTable: boolean = false;
  currentShipment?: Shipment;
  currentShipmentResponse?: ExpeditionStatusResponse;
  //adminShipments: ExpeditionStatusResponse[] | null | undefined = undefined
  selectedRowIndex: number | undefined;
  shipmentsAdmin: Shipment[] | null | undefined = undefined;
  trackingAlb: string | undefined;
  trackingPhone: string | undefined;

  setIsLoadingInTable(val: boolean) {
    this.isLoadingInTable = val;
  }

  loadShipment(shipmentExp: string, phoneNumber: string) {
    this.loadingShipment = true;
    return new Promise<ExpeditionStatusResponse>((resolve, reject) => {
      ShipmentRepository.getStatusExpedition(shipmentExp, phoneNumber)
        .then((res: ExpeditionStatusResponse) => {
          this.loadingShipment = false;
          if (res.shipment === undefined) this.currentShipment = undefined;
          else {
            this.currentShipmentResponse = res;
            this.currentShipment = res.shipment;
            console.log("currentShipmentResponse")
            console.log(JSON.stringify(this.currentShipmentResponse))
          }
          console.log("loadShipment => shipmentApi.getStatusExpedition => res = ", res);
          resolve(res);
        })
        .catch((error) => {
          this.loadingShipment = false;
          this.currentShipment = undefined;
          console.log("loadShipment => shipmentApi.getStatusExpedition => error = ", error);
          reject(error);
        });
    });
  }

  unLoadShipment() {
    this.currentShipment = undefined;
    this.selectedRowIndex = undefined;
  }

  setSelectedRowIndex(value: number | undefined) {
    this.selectedRowIndex = value;
  }

  unloadAdminShipments() {
    this.shipmentsAdmin = undefined;
  }

  loadAdminShipments(pageNum: number = 1, pageSize: number = 50): Promise<PageableShipment> {
    return new Promise((resolve, reject) =>
      ShipmentRepository.getAdminShipments(pageNum, pageSize)
        .then((res: PageableShipment) => {
          this.loadingShipment = false;
          if (res === undefined) this.shipmentsAdmin = undefined;
          else this.shipmentsAdmin = res.items;
          console.log("loadAdminShipments => shipmentApi.getStatusExpedition => res = ", res);
          resolve(res);
        })
        .catch((error) => {
          this.loadingShipment = false;
          this.shipmentsAdmin = undefined;
          console.log("loadAdminShipments => shipmentApi.getStatusExpedition => error = ", error);
          reject(error);
        })
    );
  }

  searchAdminShipment(expRef: number): Promise<ExpeditionStatusResponse> {
    return new Promise((resolve, reject) =>
      ShipmentRepository.getStatusExpeditionByReference(expRef)
        .then((res: ExpeditionStatusResponse) => {
          this.loadingShipment = false;
          if (res === undefined || res.shipment === undefined) this.shipmentsAdmin = undefined;
          else this.shipmentsAdmin = [res.shipment!];
          console.log("searchAdminShipment => shipmentApi.searchAdminShipment => res = ", res);
          resolve(res);
        })
        .catch((error) => {
          this.loadingShipment = false;
          this.shipmentsAdmin = undefined;
          console.log("searchAdminShipment => shipmentApi.searchAdminShipment => error = ", error);
          reject(error);
        })
    );
  }

  filterExpeditionsByLastMessage(pageNum: number = 1, pageSize: number = 50): Promise<PageableShipment> {
    return new Promise((resolve, reject) => {
      ShipmentRepository.filterExpeditionsByLastMessage(pageNum, pageSize)
        .then((response) => {
          this.loadingShipment = false;
          if (response === undefined || response.items?.length === 0) this.shipmentsAdmin = undefined;
          else this.shipmentsAdmin = response.items;
          console.log("filterExpeditionsByLastMessage => shipmentApi.filterExpeditionsByLastMessage => res = ", response);
          resolve(response);
        })
        .catch((err) => {
          this.loadingShipment = false;
          this.shipmentsAdmin = undefined;
          console.log("filterExpeditionsByLastMessage => appApi.filterExpeditionsByLastMessage => error = ", err);
          reject(err);
        });
    });
  }

  setTrackingPhone(phone: string) {
    this.trackingPhone = phone;
  }
  setTrackingAlb(alb: string) {
    this.trackingAlb = alb;
  }
}
