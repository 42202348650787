import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import UsersViewModel, { IUser } from "../../viewmodels/UsersViewModel";
import ConfirmDialog from "./../components/commons/ConfirmDialog";
import { OwnColors } from "./../../commons/Constants";
import { ROLES } from "../../infrastructure/app-config";

const useStyles = makeStyles((theme) => ({
  contentMaterials: {
    marginTop: 54,
    marginLeft: 56,
    [theme.breakpoints.up("sm")]: {
      marginTop: 64,
    },
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  dialogTitle: {
    backgroundColor: OwnColors.mobelBlue,
    color: "white",
    cursor: "move",
  },
  separation: {
    marginTop: 15,
    marginBottom: 14,
  },
  select: {
    paddingLeft: "5%",
    minWidth: "100%",
    maxWidth: "100%",
  },
  inputColor: {
    color: "black",
  },
  disabledInputColor: {
    color: "grey",
  },
}));

const override = {
  color: {
    color: "black",
  },
};

export const UserForm: React.SFC<{
  usersVm: UsersViewModel;
  selectedUser: IUser | undefined;
  open: boolean;
  cancel: Function;
  save: Function;
}> = observer(({ usersVm, open, selectedUser, cancel, save }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const vmUser = usersVm;

  const [rol, setRol] = useState<any>(selectedUser?.role || ROLES.SUPPORT);

  const [email, setEmail] = useState(selectedUser !== undefined ? selectedUser.email : "");
  const [territoryId, setTerritoryId] = useState(selectedUser !== undefined ? selectedUser.territoryId : "");
  const [emailError, setEmailError] = useState(false);
  const [territoryIdError, setTerritoryIdError] = useState(false);
  const [pass, setPass] = useState("");
  const [passError, setPassError] = useState(false);
  const [passRepeated, setPassRepeated] = useState("");
  const [passRepeatedError, setPassRepeatedError] = useState(false);

  const isNewUser = selectedUser === undefined ? true : false;

  useEffect(() => {
    setEmail(selectedUser?.email);
    setTerritoryId(selectedUser?.territoryId);
    setRol(selectedUser?.role || ROLES.SUPPORT);
  }, [selectedUser]);

  /* Confirm dialog state */
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [confirmDialogStrings, setConfirmDialogStrings] = useState({
    cancelText: t("accountAdminView.editForm.cancel"),
    confirmText: t("accountAdminView.editForm.confirm"),
    title: t("accountAdminView.editForm.title"),
    description: t("federation.manageUsers.editDialog.description"),
  });

  const [confirmDialogFunc, setConfirmDialogFunc] = useState({
    onClose: () => {},
    onConfirm: () => {},
  });

  /* Set states for the confirm dialog */
  const handleConfirmDialog = () => {
    setConfirmDialogOpen(true);
    //cancel()
  };

  const saveStore = () => {
    console.log("User Form => save");

    if (territoryId === "") {
      setTerritoryIdError(true);
    } else {
      setTerritoryIdError(false);
    }
    if (email === "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (isNewUser && pass === "") {
      setPassError(true);
    } else {
      setPassError(false);
    }
    if ((isNewUser && passRepeated === "") || (pass && passRepeated === "")) {
      setPassRepeatedError(true);
    } else {
      setPassRepeatedError(false);
    }
    if (pass && passRepeated && passRepeated !== pass) {
      setPassRepeatedError(true);
    } else {
      setPassRepeatedError(false);
    }
    if (!passRepeatedError && !passError && !emailError && !territoryIdError) {
      save(
        {
          pass: pass,
          rol: rol,
          email: email,
          territoryId: territoryId,
        },
        isNewUser
      );
      clearInputs();
    }
  };

  const handleCancel = () => {
    cancel();
    clearInputs();
  };

  const clearInputs = () => {
    setRol("");
    setEmail("");
    setTerritoryId("");
    setPass("");
    setPassRepeated("");
  };

  return (
    <div className={classes.contentMaterials}>
      <div>
        <ConfirmDialog
          {...{
            open: confirmDialogOpen,
            strings: confirmDialogStrings,
            onClose: confirmDialogFunc.onClose,
            onConfirm: confirmDialogFunc.onConfirm,
          }}
        />

        {/* Create and edit dialog form */}
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
          <Dialog open={open} onClose={clearInputs} disableBackdropClick aria-labelledby="draggable-dialog-title">
            <DialogTitle id="draggable-dialog-title" color="primary" className={classes.dialogTitle}>
              {isNewUser ? t("accountAdminView.editForm.createTitle") : t("accountAdminView.editForm.editTitle")}
            </DialogTitle>

            <DialogContent>
              <Grid container spacing={2} className={classes.select}>
                <Grid item md={6} sm={6} xs={12}>
                  <Select
                    classes={{ root: classes.inputColor }}
                    label={t("accountAdminView.table.role")}
                    id="rol-simple-select"
                    value={rol || ROLES.TERRITORY_MANAGER}
                    onChange={(val: any) => setRol(val.target.value)}
                  >
                    <MenuItem value={ROLES.TERRITORY_MANAGER}>{t("accountAdminView.table.role.territoryManager")}</MenuItem>
                    <MenuItem value={ROLES.SUPPORT}>{t("accountAdminView.table.role.support")}</MenuItem>
                  </Select>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    InputProps={{
                      classes: {
                        input: classes.inputColor,
                      },
                    }}
                    value={territoryId}
                    label={t("user.form.territoryId")}
                    disabled={false}
                    onChange={(val: any) => setTerritoryId(val.target.value)}
                    error={territoryIdError}
                    autoComplete='off' 
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    InputProps={{
                      classes: {
                        input: isNewUser ? classes.inputColor : classes.disabledInputColor,
                      },
                    }}
                    style={{ color: "#000000 !important" }}
                    value={email}
                    label={t("user.form.mail")}
                    disabled={!isNewUser}
                    onChange={(val: any) => setEmail(val.target.value)}
                    error={emailError}
                    autoComplete='off' 
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    value={pass}
                    type="password"
                    label={t("user.form.password")}
                    onChange={(val: any) => setPass(val.target.value)}
                    error={passError}
                    autoComplete='off' 
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    value={passRepeated}
                    type="password"
                    label={t("user.form.repeat.password")}
                    onChange={(val: any) => setPassRepeated(val.target.value)}
                    error={passRepeatedError}
                    autoComplete='off' 
                  />
                </Grid>
              </Grid>
            </DialogContent>

            {/* Dialog buttons */}
            <DialogActions>
              <Button onClick={() => handleCancel()} color="primary" style={{ backgroundColor: OwnColors.lightGrey, color: "black" }}>
                {t("accountAdminView.editForm.cancel")}
              </Button>

              <Button
                onClick={() => {
                  saveStore();
                }}
                style={{ backgroundColor: OwnColors.mobelBlue, color: "white" }}
              >
                {isNewUser ? t("accountAdminView.editForm.confirm") : t("accountAdminView.editForm.save")}
              </Button>
            </DialogActions>
          </Dialog>
        </Draggable>
      </div>
    </div>
  );
});
