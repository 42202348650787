import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";
import { debounceSearchRender } from "./Search";
import EditIcon from "@material-ui/icons/Edit";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import RefreshIcon from "@material-ui/icons/Refresh";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import IconButton from "@material-ui/core/IconButton";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { TableRow, TableCell, Grid } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { OwnColors } from "./../../../commons/Constants";
/*
const useStyles = makeStyles(() =>
  createStyles({
    buttonEdit:{
      color:"white",
      marginRight:12
    }
  })
);*/

export default function DataTable(props) {
  const { t } = useTranslation();
  const [selectOptions] = useState([]);
  let [resizable, setResizable] = useState(false);
  let [tempExpandRows, setTempExpandRows] = useState();
  let [tempSelectableRows, setTempSelectableRows] = useState();
  let [openSelect] = useState(true);
  let [toolbarSelect, setToolbarSelect] = useState("default");

  const getMuiTheme = () =>
    createMuiTheme({
      palette:{
        primary: {main: OwnColors.mobelBlue},
        secondary: {main: OwnColors.lightGreen}
      },
      overrides: {
        MUIDataTableToolbar: {
          root: {
            backgroundColor: "#fafafa",
            boxShadow: "0px 4px 4px -1px rgba(0,0,0,0.27) !important",
            marginBottom: 8,
          },
          actions: {
            display: "contents",
          },
          icon: {
            color: "rgba(0, 0, 0, 0.54);",
          },
          titleText: {
            color: "rgba(0, 0, 0, 0.54);",
          },
          iconActive: {
            color: "white !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            color: "black !important",
            fontWeight: "bold",
          },
        },
        MUIDataTableResize: {
          resizer: {
            width: 0,
          },
        },
        MUIDataTableToolbarSelect: {
          root: {
            backgroundColor: "#fafafa !important",
            color: "rgba(0, 0, 0, 0.54)",
            boxShadow: "0px 4px 4px -1px rgba(0,0,0,0.27) !important",
            marginBottom: 8,
            height: 64,
          },
          title: {
            width: "max-content",
          },
        },
        MuiPaper: {
          rounded: {
            borderRadius: 0,
          },
        },
        MUIDataTableSearch: {
          searchIcon: {
            color: "rgba(0,0,0,0.54)",
            margin: 0,
            marginTop: 12,
          },
          searchText: {
            marginTop: 8,
          },
        },
        MuiInputBase: {
          input: {
            color: "rgba(0,0,0,0.54)",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            backgroundColor: "#fafafa !important",
            boxShadow: "-2px 0px 4px 3px rgba(0,0,0,0.27) !important",
            zIndex: 300,
          },
          select: {
            color: "black",
          },
        },
        MuiTypography: {
          colorInherit: {
            color: "rgba(0,0,0,0.54)",
          },
        },
        MuiTablePagination: {
          root: {
            color: "rgba(0,0,0,0.54) !important",
          },
          selectIcon: {
            color: "rgba(0,0,0,0.54) !important",
          },
          toolbar: {
            marginRight: 30,
            padding: "0px !important",
          },
        },
        MuiTableCell: {
          footer: {
            backgroundColor: "#3f51b5 !important",
            //position: "fixed",
            width: "100%",
            //bottom: 0
          },
        },
        MuiIconButton: {
          root: {
            color: "rgba(0, 0, 0, 0.54)",
            "&:hover": {
              color: `${OwnColors.mobelBlue}!important`,
              backgroundColor: "rgba(255, 255, 255, 0.38)",
            },
          },
        },
        MuiGrid: {
          container: {
            justifyContent: "flex-end",
            alignItems: "center",
          },
        },
        MUIDataTableSelectCell: {
          icon: {
            color: "black",
          },
        },
      },
    });

  const options = {
    responsive: "vertical",
    tableBodyHeight: "auto",
    tableBodyMaxHeight: "72vh",
    filterType: "multiselect",
    page: props.page,
    jumpToPage: true,
    draggableColumns: { enabled: true },
    expandableRows: props.expandable,
    selectableRows: props.selectableRows,
    rowsSelected: tempSelectableRows,
    expandableRowsHeader: true,
    expandableRowsOnClick: false,
    selectableRowsOnClick: props.selectableRowsOnClick,
    rowsExpanded: tempExpandRows,
    resizableColumns: resizable,
    print: false,
    pagination: true,
    selectToolbarPlacement: toolbarSelect,
    downloadOptions: {
      filename: "Reports.csv",
      separator: ";",
    },
    rowsPerPageOptions: [],
    rowsPerPage: 10,
    customSearchRender: debounceSearchRender(500),

    onRowExpansionChange: (currentRowsExpanded) => {
      resizable === true && setResizable(false);
      if (openSelect === true) {
        setTempExpandRows([currentRowsExpanded[0].index, currentRowsExpanded[0].dataIndex]);
        if (tempExpandRows !== undefined) {
          currentRowsExpanded[0].dataIndex === tempExpandRows[1] && setTempExpandRows([]);
        }
      }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      const elements = [];

      rowsSelected.forEach((index) => {
        elements.push(props.data[index]);
      });
      props.handleRowsSelected(elements);
    },

    onChangePage: (currentPage) => {
      props.handleCurrentPage(currentPage);
    },

    customToolbar: () => {
      return (
        <>
          {/*props.resizableGuidesButton && (
            <Tooltip title={t("table.component.guides")}>
              <IconButton
                aria-label={t("table.component.guides")}
                onClick={() => {
                  setResizable(!resizable);
                }}
              >
                <SwapHorizIcon />
              </IconButton>
            </Tooltip>
          )*/}

          {props.updateButton && (
            <Tooltip title={t("table.component.update")}>
              <IconButton
                aria-label={t("table.component.update")}
                onClick={() => {
                  props.onUpdate();
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}

          {props.createButton && (
            <Tooltip title={t("table.component.create")}>
              <IconButton
                aria-label={t("table.component.create")}
                onClick={() => {
                  props.create();
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      );
    },

    customToolbarSelect: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      const onRowDeleteAction = () => {
        currentRowsSelected.data = [];
        currentRowsSelected.lookup = {};
        //rowsSelected = []
        props.delete();
      };
      return (
        <Grid container>
          <Grid item xs={11} style={{ textAlign: "end", marginRight: 20 }}>
            {props.changePasswordMenu === true && (
              <Tooltip title="Change Password">
                <IconButton aria-label="ChangePassword" onClick={() => props.changePassword()}>
                  <LockOpenIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Edit">
              <IconButton aria-label="Edit" onClick={() => props.edit()}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton aria-label="Crear" onClick={() => onRowDeleteAction()}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );
    },

    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;

      if (props.family) {
        props.data[rowMeta.dataIndex].materials && selectOptions.splice(0, selectOptions.length);
        props.data[rowMeta.dataIndex].materials.forEach((val) => selectOptions.push(val.name));
      } else {
        props.data[rowMeta.dataIndex].families && selectOptions.splice(0, selectOptions.length);
        props.data[rowMeta.dataIndex].families.forEach((val) => selectOptions.push(val.name));
      }

      return (
        <>
          <TableRow>
            <TableCell colSpan={colSpan}>
              {" "}
              {selectOptions.length !== 0 ? (
                <Grid spacing={1} style={{ marginLeft: 71 }}>
                  <Grid item>
                    {props.family ? t("table.section.text.expandableMaterials") : t("table.section.text.expandableFamilies")}
                  </Grid>

                  {selectOptions.map((val, index) => (
                    <Grid item style={{ marginLeft: 12 }}>
                      {index === selectOptions.length - 1 ? val : val}
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid spacing={1} style={{ marginLeft: 71 }}>
                  <Grid item>
                    {props.family ? t("table.section.text.expandableVoidMaterials") : t("table.section.text.expandableVoidFamilies")}
                  </Grid>
                </Grid>
              )}
            </TableCell>
          </TableRow>
        </>
      );
    },
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable title={props.title} data={props.data} columns={props.columns} options={options} />
    </MuiThemeProvider>
  );
}
