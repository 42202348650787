import { Grid, Typography } from "@material-ui/core";
import { OwnColors } from "../../../commons/Constants";
import { observer } from "mobx-react-lite";
import Icon from "../icon/Icon";
import { useStyles } from "./chatLineStyles";

const ChatLine: React.FunctionComponent<{
  userLine: boolean;
  chatText: string;
  messageDate?: string;
  messageHour?: string;
}> = observer(({ userLine, chatText, messageDate, messageHour }) => {
  const classes = useStyles();

  const userChatLine = (text: string, date?: string, hour?: string) => {
    return (
      <Grid xs={12} item container direction={"row"} style={{justifyContent: "flex-start"}}>
        <Grid style={{ backgroundColor: "white" }} className={classes.userChatLine}>
          <Grid container>
            <Grid container item xs={2} justify="flex-start" style={{justifyContent: "flex-start"}}>
              <Icon icon="icon-profile" size={32} style={{ color: OwnColors.blueGrey }} />
            </Grid>
            <Grid item xs={10}>
              <Typography>{text}</Typography>
            </Grid>
          </Grid>
          {date && (
            <Grid item xs={12} style={{ paddingLeft: "3px", paddingTop: 5 }}>
              <Typography variant="body2">
                {date} {hour}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  const adminChatLine = (text: string, date?: string, hour?: string) => {
    return (
      <Grid xs={12} item container direction={"row"} justify={"flex-end"}>
        <Grid className={classes.adminChatLine}>
          <Grid container style={{ paddingRight: 10, paddingLeft: 10 }}>
            <Grid item xs={10}>
              <Typography>{text}</Typography>
            </Grid>
            <Grid container item xs={2} justify="flex-end">
              <Icon icon="icon-mobel" size={32} style={{ color: OwnColors.mobelBlue, backgroundColor: "white", borderRadius: 15 }} />
            </Grid>
          </Grid>
          {date && (
            <Grid item xs={12} style={{ paddingRight: "3px", paddingTop: 5 }}>
              <Typography variant="body2" align="right" style={{ color: "white" }}>
                {date} {hour}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  return userLine ? userChatLine(chatText, messageDate, messageHour) : adminChatLine(chatText, messageDate, messageHour);
});

export default ChatLine;
