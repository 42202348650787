/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AppointmentAvailabilityResponse,
    AppointmentAvailabilityResponseFromJSON,
    AppointmentAvailabilityResponseToJSON,
    AppointmentRequest,
    AppointmentRequestFromJSON,
    AppointmentRequestToJSON,
    AppointmentResponse,
    AppointmentResponseFromJSON,
    AppointmentResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PageableManagerTerritoryResponse,
    PageableManagerTerritoryResponseFromJSON,
    PageableManagerTerritoryResponseToJSON,
    PostalAreaDTO,
    PostalAreaDTOFromJSON,
    PostalAreaDTOToJSON,
    SpecificDayDTO,
    SpecificDayDTOFromJSON,
    SpecificDayDTOToJSON,
    TimeFrameDTO,
    TimeFrameDTOFromJSON,
    TimeFrameDTOToJSON,
} from '../models';

export interface AddSpecificDayForPostalAreaRequest {
    postalAreaId: string;
    specificDayDTO?: SpecificDayDTO;
}

export interface AddTimeFrameToPostalAreaRequest {
    postalAreaId: string;
    timeFrameDTO?: TimeFrameDTO;
}

export interface BookAppointmentRequest {
    appointmentRequest?: AppointmentRequest;
}

export interface CreateNewPostalAreaRequest {
    postalAreaDTO?: PostalAreaDTO;
}

export interface DeletePostalAreaByIdRequest {
    postalAreaId: string;
}

export interface EditPostalAreaRequest {
    postalAreaDTO?: PostalAreaDTO;
}

export interface GetAvailableAppointmentRequest {
    ref: string;
    phone: string;
}

export interface GetPostalAreaByIdRequest {
    postalAreaId: string;
}

export interface GetPostalAreasFromTerritoryForManagerPaginatedRequest {
    pageNum: number;
    pageSize: number;
    filter?: string;
}

export interface ModifySpecificDayFromPostalAreaRequest {
    postalAreaId: string;
    specificDayDTO?: SpecificDayDTO;
}

export interface ModifyTimeFrameFromPostalAreaRequest {
    postalAreaId: string;
    timeFrameDTO?: TimeFrameDTO;
}

export interface RemoveSpecificDayFromPostalAreaRequest {
    postalAreaId: string;
    uuid: string;
}

export interface RemoveTimeFrameFromPostalAreaRequest {
    postalAreaId: string;
    uuid: string;
}

/**
 * no description
 */
export class PostalAreaApi extends runtime.BaseAPI {

    /**
     * addSpecificDayForPostalArea
     * addSpecificDayForPostalArea
     */
    async addSpecificDayForPostalAreaRaw(requestParameters: AddSpecificDayForPostalAreaRequest): Promise<runtime.ApiResponse<PostalAreaDTO>> {
        if (requestParameters.postalAreaId === null || requestParameters.postalAreaId === undefined) {
            throw new runtime.RequiredError('postalAreaId','Required parameter requestParameters.postalAreaId was null or undefined when calling addSpecificDayForPostalArea.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/postal-area/{postalAreaId}/specific-day/`.replace(`{${"postalAreaId"}}`, encodeURIComponent(String(requestParameters.postalAreaId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SpecificDayDTOToJSON(requestParameters.specificDayDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostalAreaDTOFromJSON(jsonValue));
    }

    /**
     * addSpecificDayForPostalArea
     * addSpecificDayForPostalArea
     */
    async addSpecificDayForPostalArea(postalAreaId: string, specificDayDTO?: SpecificDayDTO): Promise<PostalAreaDTO> {
        const response = await this.addSpecificDayForPostalAreaRaw({ postalAreaId: postalAreaId, specificDayDTO: specificDayDTO });
        return await response.value();
    }

    /**
     * addTimeFrameToPostalArea
     * addTimeFrameToPostalArea
     */
    async addTimeFrameToPostalAreaRaw(requestParameters: AddTimeFrameToPostalAreaRequest): Promise<runtime.ApiResponse<PostalAreaDTO>> {
        if (requestParameters.postalAreaId === null || requestParameters.postalAreaId === undefined) {
            throw new runtime.RequiredError('postalAreaId','Required parameter requestParameters.postalAreaId was null or undefined when calling addTimeFrameToPostalArea.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/postal-area/{postalAreaId}/timeframe`.replace(`{${"postalAreaId"}}`, encodeURIComponent(String(requestParameters.postalAreaId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TimeFrameDTOToJSON(requestParameters.timeFrameDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostalAreaDTOFromJSON(jsonValue));
    }

    /**
     * addTimeFrameToPostalArea
     * addTimeFrameToPostalArea
     */
    async addTimeFrameToPostalArea(postalAreaId: string, timeFrameDTO?: TimeFrameDTO): Promise<PostalAreaDTO> {
        const response = await this.addTimeFrameToPostalAreaRaw({ postalAreaId: postalAreaId, timeFrameDTO: timeFrameDTO });
        return await response.value();
    }

    /**
     * bookAppointment
     * bookAppointment
     */
    async bookAppointmentRaw(requestParameters: BookAppointmentRequest): Promise<runtime.ApiResponse<AppointmentResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/appointment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentRequestToJSON(requestParameters.appointmentRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentResponseFromJSON(jsonValue));
    }

    /**
     * bookAppointment
     * bookAppointment
     */
    async bookAppointment(appointmentRequest?: AppointmentRequest): Promise<AppointmentResponse> {
        const response = await this.bookAppointmentRaw({ appointmentRequest: appointmentRequest });
        return await response.value();
    }

    /**
     * createNewPostalArea
     * createNewPostalArea
     */
    async createNewPostalAreaRaw(requestParameters: CreateNewPostalAreaRequest): Promise<runtime.ApiResponse<PostalAreaDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/postal-area`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostalAreaDTOToJSON(requestParameters.postalAreaDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostalAreaDTOFromJSON(jsonValue));
    }

    /**
     * createNewPostalArea
     * createNewPostalArea
     */
    async createNewPostalArea(postalAreaDTO?: PostalAreaDTO): Promise<PostalAreaDTO> {
        const response = await this.createNewPostalAreaRaw({ postalAreaDTO: postalAreaDTO });
        return await response.value();
    }

    /**
     * deletePostalAreaById
     * deletePostalAreaById
     */
    async deletePostalAreaByIdRaw(requestParameters: DeletePostalAreaByIdRequest): Promise<runtime.ApiResponse<PostalAreaDTO>> {
        if (requestParameters.postalAreaId === null || requestParameters.postalAreaId === undefined) {
            throw new runtime.RequiredError('postalAreaId','Required parameter requestParameters.postalAreaId was null or undefined when calling deletePostalAreaById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/postal-area/{postalAreaId}`.replace(`{${"postalAreaId"}}`, encodeURIComponent(String(requestParameters.postalAreaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostalAreaDTOFromJSON(jsonValue));
    }

    /**
     * deletePostalAreaById
     * deletePostalAreaById
     */
    async deletePostalAreaById(postalAreaId: string): Promise<PostalAreaDTO> {
        const response = await this.deletePostalAreaByIdRaw({ postalAreaId: postalAreaId });
        return await response.value();
    }

    /**
     * editPostalArea
     * editPostalArea
     */
    async editPostalAreaRaw(requestParameters: EditPostalAreaRequest): Promise<runtime.ApiResponse<PostalAreaDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/postal-area`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostalAreaDTOToJSON(requestParameters.postalAreaDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostalAreaDTOFromJSON(jsonValue));
    }

    /**
     * editPostalArea
     * editPostalArea
     */
    async editPostalArea(postalAreaDTO?: PostalAreaDTO): Promise<PostalAreaDTO> {
        const response = await this.editPostalAreaRaw({ postalAreaDTO: postalAreaDTO });
        return await response.value();
    }

    /**
     * getAllPostalAreas
     * getAllPostalAreas
     */
    async getAllPostalAreasRaw(): Promise<runtime.ApiResponse<Array<PostalAreaDTO>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/postal-area`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PostalAreaDTOFromJSON));
    }

    /**
     * getAllPostalAreas
     * getAllPostalAreas
     */
    async getAllPostalAreas(): Promise<Array<PostalAreaDTO>> {
        const response = await this.getAllPostalAreasRaw();
        return await response.value();
    }

    /**
     * getAvailableAppointment
     * getAvailableAppointment
     */
    async getAvailableAppointmentRaw(requestParameters: GetAvailableAppointmentRequest): Promise<runtime.ApiResponse<AppointmentAvailabilityResponse>> {
        if (requestParameters.ref === null || requestParameters.ref === undefined) {
            throw new runtime.RequiredError('ref','Required parameter requestParameters.ref was null or undefined when calling getAvailableAppointment.');
        }

        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling getAvailableAppointment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.ref !== undefined) {
            queryParameters['ref'] = requestParameters.ref;
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/appointment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentAvailabilityResponseFromJSON(jsonValue));
    }

    /**
     * getAvailableAppointment
     * getAvailableAppointment
     */
    async getAvailableAppointment(ref: string, phone: string): Promise<AppointmentAvailabilityResponse> {
        const response = await this.getAvailableAppointmentRaw({ ref: ref, phone: phone });
        return await response.value();
    }

    /**
     * getPostalAreaById
     * getPostalAreaById
     */
    async getPostalAreaByIdRaw(requestParameters: GetPostalAreaByIdRequest): Promise<runtime.ApiResponse<PostalAreaDTO>> {
        if (requestParameters.postalAreaId === null || requestParameters.postalAreaId === undefined) {
            throw new runtime.RequiredError('postalAreaId','Required parameter requestParameters.postalAreaId was null or undefined when calling getPostalAreaById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/postal-area/{postalAreaId}`.replace(`{${"postalAreaId"}}`, encodeURIComponent(String(requestParameters.postalAreaId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostalAreaDTOFromJSON(jsonValue));
    }

    /**
     * getPostalAreaById
     * getPostalAreaById
     */
    async getPostalAreaById(postalAreaId: string): Promise<PostalAreaDTO> {
        const response = await this.getPostalAreaByIdRaw({ postalAreaId: postalAreaId });
        return await response.value();
    }

    /**
     * getPostalAreasFromTerritoryForManagerPaginated
     * getPostalAreasFromTerritoryForManagerPaginated
     */
    async getPostalAreasFromTerritoryForManagerPaginatedRaw(requestParameters: GetPostalAreasFromTerritoryForManagerPaginatedRequest): Promise<runtime.ApiResponse<PageableManagerTerritoryResponse>> {
        if (requestParameters.pageNum === null || requestParameters.pageNum === undefined) {
            throw new runtime.RequiredError('pageNum','Required parameter requestParameters.pageNum was null or undefined when calling getPostalAreasFromTerritoryForManagerPaginated.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getPostalAreasFromTerritoryForManagerPaginated.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageNum !== undefined) {
            queryParameters['pageNum'] = requestParameters.pageNum;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/postal-area/manager`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageableManagerTerritoryResponseFromJSON(jsonValue));
    }

    /**
     * getPostalAreasFromTerritoryForManagerPaginated
     * getPostalAreasFromTerritoryForManagerPaginated
     */
    async getPostalAreasFromTerritoryForManagerPaginated(pageNum: number, pageSize: number, filter?: string): Promise<PageableManagerTerritoryResponse> {
        const response = await this.getPostalAreasFromTerritoryForManagerPaginatedRaw({ pageNum: pageNum, pageSize: pageSize, filter: filter });
        return await response.value();
    }

    /**
     * modifySpecificDayFromPostalArea
     * modifySpecificDayFromPostalArea
     */
    async modifySpecificDayFromPostalAreaRaw(requestParameters: ModifySpecificDayFromPostalAreaRequest): Promise<runtime.ApiResponse<PostalAreaDTO>> {
        if (requestParameters.postalAreaId === null || requestParameters.postalAreaId === undefined) {
            throw new runtime.RequiredError('postalAreaId','Required parameter requestParameters.postalAreaId was null or undefined when calling modifySpecificDayFromPostalArea.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/postal-area/{postalAreaId}/specific-day/`.replace(`{${"postalAreaId"}}`, encodeURIComponent(String(requestParameters.postalAreaId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SpecificDayDTOToJSON(requestParameters.specificDayDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostalAreaDTOFromJSON(jsonValue));
    }

    /**
     * modifySpecificDayFromPostalArea
     * modifySpecificDayFromPostalArea
     */
    async modifySpecificDayFromPostalArea(postalAreaId: string, specificDayDTO?: SpecificDayDTO): Promise<PostalAreaDTO> {
        const response = await this.modifySpecificDayFromPostalAreaRaw({ postalAreaId: postalAreaId, specificDayDTO: specificDayDTO });
        return await response.value();
    }

    /**
     * modifyTimeFrameFromPostalArea
     * modifyTimeFrameFromPostalArea
     */
    async modifyTimeFrameFromPostalAreaRaw(requestParameters: ModifyTimeFrameFromPostalAreaRequest): Promise<runtime.ApiResponse<PostalAreaDTO>> {
        if (requestParameters.postalAreaId === null || requestParameters.postalAreaId === undefined) {
            throw new runtime.RequiredError('postalAreaId','Required parameter requestParameters.postalAreaId was null or undefined when calling modifyTimeFrameFromPostalArea.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/postal-area/{postalAreaId}/timeframe`.replace(`{${"postalAreaId"}}`, encodeURIComponent(String(requestParameters.postalAreaId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TimeFrameDTOToJSON(requestParameters.timeFrameDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostalAreaDTOFromJSON(jsonValue));
    }

    /**
     * modifyTimeFrameFromPostalArea
     * modifyTimeFrameFromPostalArea
     */
    async modifyTimeFrameFromPostalArea(postalAreaId: string, timeFrameDTO?: TimeFrameDTO): Promise<PostalAreaDTO> {
        const response = await this.modifyTimeFrameFromPostalAreaRaw({ postalAreaId: postalAreaId, timeFrameDTO: timeFrameDTO });
        return await response.value();
    }

    /**
     * removeSpecificDayFromPostalArea
     * removeSpecificDayFromPostalArea
     */
    async removeSpecificDayFromPostalAreaRaw(requestParameters: RemoveSpecificDayFromPostalAreaRequest): Promise<runtime.ApiResponse<PostalAreaDTO>> {
        if (requestParameters.postalAreaId === null || requestParameters.postalAreaId === undefined) {
            throw new runtime.RequiredError('postalAreaId','Required parameter requestParameters.postalAreaId was null or undefined when calling removeSpecificDayFromPostalArea.');
        }

        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling removeSpecificDayFromPostalArea.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/postal-area/{postalAreaId}/specific-day/{uuid}`.replace(`{${"postalAreaId"}}`, encodeURIComponent(String(requestParameters.postalAreaId))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostalAreaDTOFromJSON(jsonValue));
    }

    /**
     * removeSpecificDayFromPostalArea
     * removeSpecificDayFromPostalArea
     */
    async removeSpecificDayFromPostalArea(postalAreaId: string, uuid: string): Promise<PostalAreaDTO> {
        const response = await this.removeSpecificDayFromPostalAreaRaw({ postalAreaId: postalAreaId, uuid: uuid });
        return await response.value();
    }

    /**
     * removeTimeFrameFromPostalArea
     * removeTimeFrameFromPostalArea
     */
    async removeTimeFrameFromPostalAreaRaw(requestParameters: RemoveTimeFrameFromPostalAreaRequest): Promise<runtime.ApiResponse<PostalAreaDTO>> {
        if (requestParameters.postalAreaId === null || requestParameters.postalAreaId === undefined) {
            throw new runtime.RequiredError('postalAreaId','Required parameter requestParameters.postalAreaId was null or undefined when calling removeTimeFrameFromPostalArea.');
        }

        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling removeTimeFrameFromPostalArea.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/postal-area/{postalAreaId}/timeframe/{uuid}`.replace(`{${"postalAreaId"}}`, encodeURIComponent(String(requestParameters.postalAreaId))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PostalAreaDTOFromJSON(jsonValue));
    }

    /**
     * removeTimeFrameFromPostalArea
     * removeTimeFrameFromPostalArea
     */
    async removeTimeFrameFromPostalArea(postalAreaId: string, uuid: string): Promise<PostalAreaDTO> {
        const response = await this.removeTimeFrameFromPostalAreaRaw({ postalAreaId: postalAreaId, uuid: uuid });
        return await response.value();
    }

}
