import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";
import { CircularProgress, DialogContentText } from "@mui/material";
import React, { useState, useEffect } from "react";
import PostalAreaViewModel from "../../../viewmodels/PostalAreaViewModel";
import { useSnackbar } from "notistack";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { PostalAreaDTO, SpecificDayDTO } from "../../../client";
import { OwnColors } from "../../../commons/Constants";

export const DeleteDPostalAreaSpecificDayDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  postalArea: PostalAreaDTO;
  specificDay: SpecificDayDTO;
}> = observer(({ open, handleClose, postalArea, specificDay }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [pa, setPA] = useState<PostalAreaDTO>(postalArea);
  const postalareaVm = PostalAreaViewModel.getInstance();
  const { t } = useTranslation();
  useEffect(() => {
    setPA(postalArea);
  }, [postalArea]);

  const remove = async () => {
    const isSuccessfull = await postalareaVm.removeSpecificDayFromPostalArea(pa.id!, specificDay.id!);
    isSuccessfull
      ? enqueueSnackbar(t("postalarea.dialog.common.deletion.success"), { variant: "success" })
      : enqueueSnackbar(t("postalarea.dialog.common.failure"), { variant: "error" });
    handleClose();
  };

  return (
    <Dialog
      disableBackdropClick
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: OwnColors.mobelBlue,
          height: "46px",
          textAlign: "start",
          padding: "6px",
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            color: "white",
          }}
          id="alert-dialog-title"
        >
          {t("postalarea.deleteDialog.specificdays.title")}
        </DialogTitle>
        <IconButton
          disableFocusRipple
          disableRipple
          disableTouchRipple
          disabled={postalareaVm.isLoading}
          style={{ color: "white" }}
          onClick={handleClose}
        >
          {postalareaVm.isLoading ? <Close style={{ color: "GrayText" }} /> : <Close />}
        </IconButton>
      </div>

      <DialogContent>
        <DialogContentText>
          {t("postalarea.deleteDialog.specificdays.description")}{" "}
          <b>
            <i>"{specificDay.name}"</i>
          </b>
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleClose} disabled={postalareaVm.isLoading}>
          {t("postalarea.dialog.postalarea.cancel")}
        </Button>
        {postalareaVm.isLoading ? (
          <div style={{ display: "flex", justifyContent: "start" }}>
            <CircularProgress size={25} style={{ marginRight: "1rem" }} />
          </div>
        ) : (
          <Button style={{ background: OwnColors.lightGreen, color: "white" }} onClick={remove}>
            {t("postalarea.dialog.postalarea.delete")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});
