import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { Grid, List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import MailIcon from "@material-ui/icons/Mail";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { observer } from "mobx-react";
import { OwnColors } from "../../../commons/Constants";
import InfoIcon from "@material-ui/icons/Info";
import HttpIcon from "@material-ui/icons/Http";
import PolicyIcon from "@material-ui/icons/Policy";
import MenuBookIcon from "@material-ui/icons/MenuBook";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    marginTop: "auto",
  },
  mainFooterContent: {
    padding: theme.spacing(3, 2),
    backgroundColor: OwnColors.grey,
    paddingBottom: 50,
  },
  belowFooterContent: {
    padding: theme.spacing(3, 2),
    backgroundColor: "white",
  },
  contactMargin: {
    [theme.breakpoints.up("sm")]: {
      //marginLeft: 40,
    },
  },
  link: {
    "& > *": {
      "&:hover": {
        color: "white",
      },
      color: OwnColors.lightGrey,
    },
  },
}));
export const StickyFooter: React.SFC<{}> = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const keyPoints = [
    { point: "Image" },
    { point: t("footer.contactUs") },
    { point: t("footer.aboutApp.us") },
    //{ point: t("footer.aboutApp") },
  ];

  const listContactUs = [
    { listPoint: t("footer.contactUs.mail"), linkTo: "" },
    { listPoint: t("footer.contactUs.phone"), linkTo: "" },
  ];

  const listUs = [
    { listPoint: t("footer.aboutApp.aboutUs"), linkTo: "/who-are-us" },
    { listPoint: t("footer.aboutApp.joinProject"), linkTo: "/unete-al-proyecto" },
  ];

  const listLocations = [
    { listPoint: t("footer.aboutApp.aboutUs"), linkTo: "" },
    { listPoint: t("footer.aboutApp.shellWithUs"), linkTo: "" },
  ];

  const listPopularLinks = [
    { listPoint: t("footer.popularLinks.legalDisclosure"), linkTo: "/aviso-legal" },
    { listPoint: t("footer.popularLinks.privacyPolicy"), linkTo: "/politica-de-privacidad" },
    { listPoint: t("footer.popularLinks.saleTerms"), linkTo: "/condiciones-de-compra" },
    { listPoint: t("footer.popularLinks.cookiesConsent"), linkTo: "/politica-de-cookies" },
  ];

  const Copyright = () => {
    return (
      <Typography align="center" style={{ color: OwnColors.lightGrey }} variant="body2" color="textSecondary">
        {"Copyright © "}
        {new Date().getFullYear()}{" "}
        <Link color="inherit" href="http://www.mobelservices.com/">
          {t("footer.copyright")}
        </Link>
      </Typography>
    );
  };

  const AboutUsColumn = () => {
    return (
      <Grid container item justify="space-between" key="Contacta con nosotros" xs={12} sm={4} md={4}>
        <Grid
          item
          container
          spacing={0}
          alignContent="space-between"
          justify="space-between"
          key={"Contacta con nosotrostext"}
          xs={12}
          sm={12}
          md={12}
        >
          <Grid item style={{ marginTop: "12px" }}>
            <div className={classes.contactMargin}>
              <List disablePadding dense style={{ marginTop: "-6px" }}>
                <div style={{ marginTop: "-8px" }}>
                  <ListItem button component="a" target="_blank" dense disableGutters href="http://mobelservices.com/nosotros.html">
                    <ListItemIcon style={{ marginRight: "-25px" }}>
                      <InfoIcon style={{ color: OwnColors.lightGrey }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ variant: "subtitle2", style: { color: OwnColors.lightGrey } }}
                      primary={t("footer.aboutUs")}
                    />
                  </ListItem>
                  <ListItem button component="a" target="_blank" dense disableGutters href="http://mobelservices.com">
                    <ListItemIcon style={{ marginRight: "-25px" }}>
                      <HttpIcon style={{ color: OwnColors.lightGrey }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ variant: "subtitle2", style: { color: OwnColors.lightGrey } }}
                      primary={t("footer.web")}
                    />
                  </ListItem>
                </div>
              </List>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const ContactColumn = () => {
    return (
      <Grid container item justify="space-between" key="Contacta con nosotros" xs={12} sm={4} md={4}>
        <Grid
          item
          container
          spacing={0}
          alignContent="space-between"
          justify="space-between"
          key={"Contacta con nosotrostext"}
          xs={12}
          sm={12}
          md={12}
        >
          <Grid item style={{ marginTop: "12px" }}>
            <div className={classes.contactMargin}>
              <List disablePadding dense style={{ marginTop: "-6px" }}>
                <div style={{ marginTop: "-8px" }}>
                  <ListItem dense disableGutters>
                    <ListItemIcon style={{ marginRight: "-25px" }}>
                      <PhoneInTalkIcon style={{ color: OwnColors.lightGrey }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ variant: "subtitle2", style: { color: OwnColors.lightGrey } }}
                      primary={t("footer.phone")}
                    />
                  </ListItem>
                  <ListItem dense disableGutters>
                    <ListItemIcon style={{ marginRight: "-25px" }}>
                      <MailIcon style={{ color: OwnColors.lightGrey }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ variant: "subtitle2", style: { color: OwnColors.lightGrey } }}
                      primary={t("footer.mail")}
                    />
                  </ListItem>
                </div>
              </List>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const TermsColumn = () => {
    return (
      <Grid container item justify="space-between" key="aboutUs" xs={12} sm={4} md={4}>
        <Grid
          item
          container
          spacing={0}
          alignContent="space-between"
          justify="space-between"
          key={"aboutUstext"}
          xs={12}
          sm={12}
          md={12}
        >
          <Grid item style={{ marginTop: "12px" }}>
            <div className={classes.contactMargin}>
              <List disablePadding style={{ marginTop: "-6px" }}>
                <div style={{ marginTop: "-8px" }}>
                  <ListItem
                    button
                    component="a"
                    href="http://mobelservices.com/aviso-legal.html"
                    target="_blank"
                    dense
                    disableGutters
                    className={classes.link}
                  >
                    <ListItemIcon style={{ marginRight: "-25px" }}>
                      <MenuBookIcon />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ variant: "subtitle2" }} primary={t("footer.terms")} />
                  </ListItem>
                  <ListItem
                    button
                    component="a"
                    href="http://mobelservices.com/politica-de-privacidad.html"
                    target="_blank"
                    dense
                    disableGutters
                    className={classes.link}
                  >
                    <ListItemIcon style={{ marginRight: "-25px" }}>
                      <PolicyIcon />
                    </ListItemIcon>
                    <ListItemText
                      //disableTypography
                      primaryTypographyProps={{ variant: "subtitle2" }}
                      primary={t("footer.policy")}
                    />
                  </ListItem>
                </div>
              </List>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <footer className={classes.footer}>
      <div className={classes.mainFooterContent} style={{ backgroundColor: OwnColors.grey }}>
        <Container maxWidth="md">
          <Grid container spacing={4}>
            <AboutUsColumn />
            <ContactColumn />
            <TermsColumn />
            <Grid container spacing={0} alignContent="center" justify="center" direction="column" style={{ marginTop: "10px" }}>
              <Grid item xs={12} sm={12} md={12}>
                <div style={{ marginTop: "10px" }}>
                  <Copyright />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </footer>
  );
});

export default StickyFooter;
