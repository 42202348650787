import { BackOfficeUser, Credentials, ErrorResponse, LoginApi, LoginResponse, UserApi } from "../../client";
import { Shipment } from "../../client/models/Shipment";
import SessionStorage from "../sessionStorage/SessionStorage";
import { ApiClient, Api } from "./ApiClient";

export default class UserRepository {

    private static getAppApiClient() {
        return ApiClient.getClient(Api.UserApi) as UserApi;
    }
    private static getLoginApiClient() {
        return ApiClient.getClient(Api.LoginApi) as LoginApi;
    }

    static async getUsers(): Promise<BackOfficeUser[]> {
        const userApi = this.getAppApiClient();
        const users: BackOfficeUser[] = await userApi.getAllBackOfficeUsers();

        return users;
    }

    static async getUser(email: string): Promise<BackOfficeUser> {
        const userApi = this.getAppApiClient();
        const user: BackOfficeUser = await userApi.getBackOfficeUserByEmail(email);

        return user;
    }

    static async createUser(backOfficeUser: BackOfficeUser): Promise<BackOfficeUser> {
        const userApi = this.getAppApiClient();
        const user: BackOfficeUser = await userApi.createBackOfficeUser(backOfficeUser);

        return user;
    }

    static async updateUser(backOfficeUser: BackOfficeUser): Promise<BackOfficeUser> {
        const userApi = this.getAppApiClient();
        const user: BackOfficeUser = await userApi.updateBackOfficeUserByEmail(backOfficeUser.email!, backOfficeUser);

        return user;
    }

    static async deleteUser(email: string): Promise<ErrorResponse> {
        const userApi = this.getAppApiClient();
        var response: ErrorResponse = await userApi.deleteBackOfficeUserByEmail(email);
        return response;
    }

    static doLogin(email: string, password: string) {
        return new Promise<LoginResponse>((resolve, reject) => {
            var credentials: Credentials = { email: email, password: password };
            this.getLoginApiClient().login(credentials).then((res: LoginResponse) => {
                resolve(res);
            })
                .catch(err => reject(err))
        })
    }
}