import { Box, Grid, IconButton, Divider, List, ListItem, ListItemIcon, ListItemText, TextField, Typography, Avatar, FormControl, OutlinedInput, Button, InputBase, fade, StepButton, StepContent, Container, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OwnColors } from '../../../commons/Constants';

const InProgress = (props: any) => {
    const {t} = useTranslation();
    return <Container maxWidth={"md"} style={{ paddingTop: 30 }}>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
        //style={{ minHeight: '100vh' }}
        >

            <Grid item xs={12}>
                <CircularProgress style={{ color: OwnColors.mobelBlue, height: '120px', width: "120px" }} />
            </Grid>

        </Grid>
        <Typography style={{ paddingTop: 15 }} align={"center"}>{t("trackingView.loading")}</Typography>
    </Container>;

};

export default InProgress;