import { Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import React from "react";
import { useTranslation } from "react-i18next";
import { Step as IStep } from "../../../client/models/Step";
import { OwnColors } from "./../../../commons/Constants";
import * as Utils from "./../../../commons/Utils";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

export interface ITrackingLineProps {
  trackingLine: IStep;
  child: boolean;
  activeStep?: boolean;
}

const TrackingLine: React.FunctionComponent<ITrackingLineProps> = (props: ITrackingLineProps) => {
  const { t } = useTranslation();

  const trackingLine = props.trackingLine;
  const isAChild = props.child === true ? true : false;

  return (
    <Paper style={{ width: "100%", display: "flex", margin: "10px 0 10px 0" }} elevation={2}>
      <Grid container direction={"row"} style={{ marginLeft: !isAChild ? "10px" : "20px" }} justify={"space-between"}>
        <Grid item xs={10} container direction={"column"}>
          <Grid item style={{ marginTop: "9px" }}>
            <List disablePadding dense style={{ marginTop: "-6px" }}>
              <div style={{ marginTop: "-8px" }}>
                <ListItem dense disableGutters style={{ marginTop: "-8px" }}>
                  <ListItemIcon style={{ marginRight: "-25px" }}>
                    <DateRangeIcon style={{ color: OwnColors.darkGrey }} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ variant: "subtitle2", style: { color: OwnColors.darkGrey, userSelect: "none" } }}
                    primary={Utils.timestamp2HumanReadableDateTime(trackingLine.datetime!.getTime(), t)}
                  />
                </ListItem>
                <ListItem dense disableGutters style={{ marginTop: "-8px" }}>
                  <ListItemIcon style={{ marginRight: "-25px" }}>
                    <LocationOnIcon style={{ color: OwnColors.darkGrey }} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ variant: "subtitle2", style: { color: OwnColors.darkGrey, userSelect: "none" } }}
                    primary={trackingLine.location}
                  />
                </ListItem>
                <ListItem dense disableGutters style={{ marginTop: "-8px" }}>
                  <ListItemIcon style={{ marginRight: "-25px" }}>
                    <LabelImportantIcon style={{ color: OwnColors.darkGrey }} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ variant: "subtitle2", style: { color: OwnColors.darkGrey, userSelect: "none" } }}
                    primary={trackingLine.description}
                  />
                </ListItem>
              </div>
            </List>
          </Grid>
        </Grid>
        {!isAChild ? (
          <Grid item xs={2} container direction={"row"} alignItems="center" justify="flex-end">
            <IconButton
              aria-label={t("components.toolbar.menu.show.more")}
              aria-controls={"mobileMenuId"}
              aria-haspopup="false"
              size="medium"
              color={"inherit"}
            >
              {props.activeStep ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
};

export default TrackingLine;
