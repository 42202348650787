import {
  AppointmentAvailabilityResponse,
  AppointmentRequest,
  AppointmentResponse,
  PageableManagerTerritoryResponse,
  PostalAreaApi,
  PostalAreaDTO,
  SpecificDayDTO,
  TimeFrameDTO,
} from "../../client";
import { AppApi } from "../../client/apis/AppApi";
import { Message } from "../../client/models/Message";
import { ApiClient, Api } from "./ApiClient";

export default class PostalAreaRepository {
  private static getAppApiClient() {
    return ApiClient.getClient(Api.AppApi) as AppApi;
  }

  private static getPostalAreaApiClient() {
    return ApiClient.getClient(Api.PostalAreaApi) as PostalAreaApi;
  }

  static async getChatHistory(ref: string): Promise<Message[]> {
    const appApi = this.getAppApiClient();
    return await appApi.getMessagesFromExpedition(ref).catch((e) => []);
  }

  static async sendChat(msg: Message): Promise<Message> {
    const appApi = this.getAppApiClient();
    const message: Message = await appApi.createMessage(msg);

    return message;
  }

  static async createNewPostalArea(postalArea: PostalAreaDTO): Promise<PostalAreaDTO> {
    console.log("createNewPostalArea "+JSON.stringify(postalArea))
    const postalAreaApi = this.getPostalAreaApiClient();
    return await postalAreaApi.createNewPostalArea(postalArea);
  }

  static async editPostalArea(postalArea: PostalAreaDTO): Promise<PostalAreaDTO> {
    const postalAreaApi = this.getPostalAreaApiClient();
    return await postalAreaApi.editPostalArea(postalArea);
  }

  static async deletePostalAreaById(postalAreaId: string): Promise<PostalAreaDTO> {
    const postalAreaApi = this.getPostalAreaApiClient();
    return await postalAreaApi.deletePostalAreaById(postalAreaId);
  }

  static async getPostalAreaById(postalAreaId: string): Promise<PostalAreaDTO> {
    const postalAreaApi = this.getPostalAreaApiClient();
    return await postalAreaApi.getPostalAreaById(postalAreaId);
  }

  static async getAllPostalAreas(): Promise<PostalAreaDTO[]> {
    const postalAreaApi = this.getPostalAreaApiClient();
    return await postalAreaApi.getAllPostalAreas();
  }

  static async bookAppointment(request: AppointmentRequest): Promise<AppointmentResponse> {
    const postalAreaApi = this.getPostalAreaApiClient();
    return await postalAreaApi.bookAppointment(request);
  }

  static async getAppointments(expAlb: string, phoneNumber: string): Promise<AppointmentAvailabilityResponse> {
    const postalAreaApi = this.getPostalAreaApiClient();
    return await postalAreaApi.getAvailableAppointment(expAlb, phoneNumber);
  }

  static async addSpecificDayForPostalArea(postalAreaId: string, specificDay?: SpecificDayDTO): Promise<PostalAreaDTO> {
    const postalAreaApi = this.getPostalAreaApiClient();
    return await postalAreaApi.addSpecificDayForPostalArea(postalAreaId, specificDay);
  }

  static async removeSpecificDayFromPostalArea(postalAreaId: string, uuid: string): Promise<PostalAreaDTO> {
    const postalAreaApi = this.getPostalAreaApiClient();
    return await postalAreaApi.removeSpecificDayFromPostalArea(postalAreaId, uuid);
  }
  static async modifySpecificDayFromPostalArea(postalAreaId: string, specificDay?: SpecificDayDTO): Promise<PostalAreaDTO> {
    const postalAreaApi = this.getPostalAreaApiClient();
    return await postalAreaApi.modifySpecificDayFromPostalArea(postalAreaId, specificDay);
  }

  static async addTimeFrameToPostalArea(postalAreaId: string, timeFrame?: TimeFrameDTO): Promise<PostalAreaDTO> {
    const postalAreaApi = this.getPostalAreaApiClient();
    return await postalAreaApi.addTimeFrameToPostalArea(postalAreaId, timeFrame);
  }

  static async removeTimeFrameFromPostalArea(postalAreaId: string, uuid: string): Promise<PostalAreaDTO> {
    const postalAreaApi = this.getPostalAreaApiClient();
    return await postalAreaApi.removeTimeFrameFromPostalArea(postalAreaId, uuid);
  }

  static async modifyTimeFrameFromPostalArea(postalAreaId: string, timeFrame?: TimeFrameDTO): Promise<PostalAreaDTO> {
    const postalAreaApi = this.getPostalAreaApiClient();
    return await postalAreaApi.modifyTimeFrameFromPostalArea(postalAreaId, timeFrame);
  }

  static getPostalAreasForManagerPaginated(pageNumber: number, pageSize: number, filter?: string): Promise<PageableManagerTerritoryResponse> {
    const postalAreaApi = this.getPostalAreaApiClient();
    return postalAreaApi.getPostalAreasFromTerritoryForManagerPaginated(pageNumber, pageSize, filter);
  }
}
