import { CircularProgress, Grid } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { BackOfficeUser } from "../../client/models/BackOfficeUser";
import UserRepository from "../../data/repositories/UserRepository";
import ShipmentViewModel from "../../viewmodels/ShipmentViewModel";
import UsersViewModel, { IUser } from "../../viewmodels/UsersViewModel";
import TableMaterial from "../components/TableMaterial/Table";
import ConfirmDialog from "../components/commons/ConfirmDialog";
import NavigationTabs from "../components/skeleton/NavigationTabs";
import * as Utils from "./../../commons/Utils";
import AdminAccountViewStyles from "./AdminAccountViewStyles";
import { UserForm } from "./UserForm";
import { useSnackbar } from "notistack";


const AdminAccountView: React.FunctionComponent = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const vmUsers = UsersViewModel.getInstance();
  const vmShipment = ShipmentViewModel.getInstance();
  const classes = AdminAccountViewStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<IUser>();
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [rowsSelected, setRowsSelected] = React.useState<any>();
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [confirmDialogStrings, setConfirmDialogStrings] = React.useState({
    localStorage: "ownerDelete",
    cancelText: t("federation.manageAssociations.dialog.text.cancel"),
    confirmText: t("federation.manageAssociations.dialog.text.delete"),
    title: t("federation.manageAssociations.dialog.title.delete"),
    description: t("federation.manageAssociations.dialog.text.confirmDelete"),
  });
  const [confirmDialogFunc, setConfirmDialogFunc] = React.useState({
    onClose: () => {},
    onConfirm: () => {},
  });

  const handleEditUser = (user: IUser) => {
    if (user.id === -1) {
      handleCurrentPage(currentPage);
    } else {
      handleCurrentPage(currentPage);
    }

    console.log("USER "+JSON.stringify(user))
    setSelectedUser(Utils.cloneArray(user));
    setOpen(true);
  };

  const handleDeleteUser = (user: IUser) => {
    setConfirmDialogStrings({
      localStorage: "ownerDelete",
      cancelText: t("accountAdminView.delete.cancelText"),
      confirmText: t("accountAdminView.delete.confirmText"),
      title: t("accountAdminView.delete.title"),
      description: t("accountAdminView.delete.description") + " " + user.email,
    });
    setConfirmDialogFunc({
      onClose: () => setConfirmDialogOpen(false),
      onConfirm: () => {
        //vm.deleteAssociation(user)
        setConfirmDialogOpen(false);
        selectedUser?.email &&
          UserRepository.deleteUser(selectedUser!.email!).then((res) => {
            vmUsers.loadUsers();
          });
      },
    });
    setConfirmDialogOpen(true);
  };

  const handleSave = (user: any, isNewUser: boolean) => {
    var backofficeUser: BackOfficeUser = {
      email: user.email,
      password: user.pass,
      role: user.rol,
      territoryId: user.territoryId
    };
    if (isNewUser) {
      UserRepository.createUser(backofficeUser).then((res) => {
        enqueueSnackbar(t("commons.saved.success"), { variant: "success" })
        vmUsers.loadUsers();
        setOpen(false);
      }).catch(()=>{
        enqueueSnackbar(t("commons.saved.error"), { variant: "error" });
      });
    } else {
      UserRepository.updateUser(backofficeUser).then((res) => {
        enqueueSnackbar(t("commons.saved.success"), { variant: "success" })
        vmUsers.loadUsers();
        setOpen(false);
      }).catch(()=>{
        enqueueSnackbar(t("commons.saved.error"), { variant: "error" });
      });
    }
  };

  const handleCreateUser = () => {
    setSelectedUser(undefined);
    setOpen(true);
  };

  /* Method to set open to false */
  const handleClose = () => {
    setOpen(false);
  };

  const handleCurrentPage = (currentPage: number) => {
    setCurrentPage(currentPage);
  };

  const handleRowsSelected = (rows: any) => {
    //debugger
    //setRowsSelectedByUser(rows)
    setRowsSelected(rows);
    console.log("####### rows");
    console.log(rows[0]);
    setSelectedUser(rows[0]);
  };

  React.useEffect(() => {
    if (!vmUsers.users) {
      vmUsers.loadUsers();
    }
  }, []);

  return (
    <>
      <div className={classes.contentUsers}>
        <Grid item xs={12}>
          {vmUsers.currentUser?.role === "Admin" && <NavigationTabs />}
        </Grid>
        <div>
          {vmUsers.users?.length === 0 ? (
            /* Circular progress when table is not loaded */
            <div>
              <CircularProgress className={classes.circleProgress} />
            </div>
          ) : (
            <div>
              {
                <ConfirmDialog
                  {...{
                    open: confirmDialogOpen,
                    strings: confirmDialogStrings,
                    onClose: confirmDialogFunc.onClose,
                    onConfirm: confirmDialogFunc.onConfirm,
                  }}
                />
              }

              <UserForm open={open} cancel={handleClose} save={handleSave} selectedUser={selectedUser} usersVm={vmUsers} />

              <TableMaterial
                title={t("accountAdminView.table.title")}
                data={vmUsers.users}
                edit={() => {
                  handleEditUser(rowsSelected[0]);
                }}
                delete={() => {
                  handleDeleteUser(rowsSelected[0]);
                }}
                resizableGuidesButton={true}
                createButton={true}
                create={() => {
                  handleCreateUser();
                }}
                expandable={false}
                selectableRows="single"
                selectableRowsOnClick={true}
                handleRowsSelected={handleRowsSelected}
                handleCurrentPage={handleCurrentPage}
                updatePage={false}
                page={currentPage}
                columns={[
                  {
                    label: t("accountAdminView.table.name"),
                    name: "name",
                    options: {
                      sort: true,
                      filter: true,
                      filterOptions: { fullWidth: true },
                      customBodyRenderLite: (dataIndex: any) => {
                        let val = vmUsers.users?.[dataIndex]?.email;
                        return val;
                      },
                    },
                  },
                  {
                    label: t("accountAdminView.table.email"),
                    name: "email",
                    options: {
                      sort: true,
                      filter: true,
                      filterOptions: { fullWidth: true },
                      customBodyRenderLite: (dataIndex: any) => {
                        let val = vmUsers.users?.[dataIndex]?.email;
                        return val;
                      },
                    },
                  },
                  {
                    label: t("accountAdminView.table.role"),
                    name: "role",
                    options: {
                      sort: true,
                      filter: true,
                      filterOptions: { fullWidth: true },
                      customBodyRenderLite: (dataIndex: any) => {
                        let val = vmUsers.users?.[dataIndex]?.role;
                        return val;
                      },
                    },
                  },
                  {
                    label: t("accountAdminView.table.territoryId"),
                    name: "territoryId",
                    options: {
                      sort: true,
                      filter: true,
                      filterOptions: { fullWidth: true },
                      customBodyRenderLite: (dataIndex: any) => {
                        let val = vmUsers.users?.[dataIndex]?.territoryId;
                        return val;
                      },
                    },
                  } /*,
                                {
                                    label: t("accountAdminView.table.password"),
                                    name: "password",
                                    options: {
                                        sort: true,
                                        filter: true,
                                        filterOptions: { fullWidth: true },
                                        customBodyRenderLite: (dataIndex: any) => {
                                            let val = users![dataIndex].password;
                                            return val;
                                        }
                                    }
                                },*/,
                ]}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default observer(AdminAccountView);
