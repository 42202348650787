import dayjs from "dayjs";

export const scrollToTop = () => {
  /** Method to move the scrool to the top after the user moves from one section to another */
  window.scrollTo(0, 0);
};

export const setPriceWith2Decimals = (price: any): number => {
  if (price) {
    if (typeof price === "string") {
      let tempNum = Number.parseFloat(price);
      if (tempNum) {
        return tempNum.toFixed(2) as unknown as number;
      }
    } else {
      return price.toFixed(2) as number;
    }
  }
  return 0;
};

export const cloneArray = (obj: any) => {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  var temp = obj.constructor();
  for (var key in obj) {
    temp[key] = cloneArray(obj[key]);
  }
  return temp;
};

export const timestamp2HumanReadableDate = (unixTimestamp: number, t: (key: string) => string): string => {
  const date = new Date(unixTimestamp);
  var months = [
    t("date.format.january"),
    t("date.format.february"),
    t("date.format.march"),
    t("date.format.april"),
    t("date.format.may"),
    t("date.format.june"),
    t("date.format.july"),
    t("date.format.august"),
    t("date.format.september"),
    t("date.format.october"),
    t("date.format.november"),
    t("date.format.december"),
  ];
  var year = date.getFullYear();
  var month = months[date.getMonth()];
  var day = date.getDate();
  var hour = date.getHours();
  var min = date.getMinutes();
  var sec = date.getSeconds();
  var time = day + " " + month + " " + year;
  return time;
};

export const timestamp2HumanReadableDateTime = (unixTimestamp: number, t: (key: string) => string): string => {
  const date = new Date(unixTimestamp);
  var months = [
    t("date.format.january"),
    t("date.format.february"),
    t("date.format.march"),
    t("date.format.april"),
    t("date.format.may"),
    t("date.format.june"),
    t("date.format.july"),
    t("date.format.august"),
    t("date.format.september"),
    t("date.format.october"),
    t("date.format.november"),
    t("date.format.december"),
  ];
  var year = date.getFullYear();
  var month = months[date.getMonth()];
  var day = date.getDate();
  var hour = date.getHours();
  var min = date.getMinutes();
  var sec = date.getSeconds();
  var time = (hour < 10 ? "0" + hour : hour) + ":" + (min < 10 ? "0" + min : min) + " - " + day + " " + month + " " + year;
  return time;
};

export const getRamdomNumber = (lower: number, upper: number): number => {
  let numPosibilities = upper - lower;
  let random = Math.random() * (numPosibilities + 1);
  random = Math.floor(random);
  return lower + random;
};

export const getDifferentRandomNumbers = (lower: number, upper: number, totalNumbersToReturn: number) => {
  let ramdomNumbers: number[] = [];
  //console.log("ramdomNumbers", ramdomNumbers)
  do {
    let ramdomNumber = getRamdomNumber(lower, upper);
    //console.log("ramdomNumber", ramdomNumber)
    if (ramdomNumbers.length === 0) {
      ramdomNumbers.push(ramdomNumber);
      //console.log("ramdomNumbers.length === 0 => ramdomNumbers", ramdomNumbers)
    } else {
      let match = false;
      ramdomNumbers.forEach((element) => {
        if (element === ramdomNumber) {
          match = true;
          //console.log("element === ramdomNumber => ramdomNumbers", ramdomNumbers)
        }
      });
      if (!match) {
        ramdomNumbers.push(ramdomNumber);
        //console.log("!match => ramdomNumbers", ramdomNumbers)
      }
    }
  } while (ramdomNumbers.length < totalNumbersToReturn);
  return ramdomNumbers;
};

export function hasUndefinedFields<T extends Object>(obj: T) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === undefined) {
      return true;
    }
  }
  return false;
}

export function dayJSFromString(stringDate: string){
  const [day, month, year] = stringDate.split('-').map(Number);
  const dayjsDate = dayjs(new Date(year, month - 1, day));
  return dayjsDate;
}