/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableAppointment,
    AvailableAppointmentFromJSON,
    AvailableAppointmentFromJSONTyped,
    AvailableAppointmentToJSON,
} from './';

/**
 * 
 * @export
 * @interface AppointmentAvailabilityResponse
 */
export interface AppointmentAvailabilityResponse {
    /**
     * 
     * @type {string}
     * @memberof AppointmentAvailabilityResponse
     */
    postalAreaId?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentAvailabilityResponse
     */
    dayLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentAvailabilityResponse
     */
    dayOffset?: number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAvailabilityResponse
     */
    expAlb?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentAvailabilityResponse
     */
    phone?: string;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentAvailabilityResponse
     */
    eta?: Date;
    /**
     * 
     * @type {Array<AvailableAppointment>}
     * @memberof AppointmentAvailabilityResponse
     */
    availableTimeframes?: Array<AvailableAppointment>;
}

export function AppointmentAvailabilityResponseFromJSON(json: any): AppointmentAvailabilityResponse {
    return AppointmentAvailabilityResponseFromJSONTyped(json, false);
}

export function AppointmentAvailabilityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentAvailabilityResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postalAreaId': !exists(json, 'postalAreaId') ? undefined : json['postalAreaId'],
        'dayLimit': !exists(json, 'dayLimit') ? undefined : json['dayLimit'],
        'dayOffset': !exists(json, 'dayOffset') ? undefined : json['dayOffset'],
        'expAlb': !exists(json, 'expAlb') ? undefined : json['expAlb'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'eta': !exists(json, 'eta') ? undefined : (new Date(json['eta'])),
        'availableTimeframes': !exists(json, 'availableTimeframes') ? undefined : ((json['availableTimeframes'] as Array<any>).map(AvailableAppointmentFromJSON)),
    };
}

export function AppointmentAvailabilityResponseToJSON(value?: AppointmentAvailabilityResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postalAreaId': value.postalAreaId,
        'dayLimit': value.dayLimit,
        'dayOffset': value.dayOffset,
        'expAlb': value.expAlb,
        'phone': value.phone,
        'eta': value.eta === undefined ? undefined : (value.eta.toISOString().substr(0,10)),
        'availableTimeframes': value.availableTimeframes === undefined ? undefined : ((value.availableTimeframes as Array<any>).map(AvailableAppointmentToJSON)),
    };
}


