import React from "react";
import { Box, Link, Grid, TextField, Typography, makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container/Container";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LogoMobel from "../../assets/images/mobelMiniLogo.png";
import { OwnColors } from "../../commons/Constants";
import UsersViewModel from "../../viewmodels/UsersViewModel";
import Button from "../components/commons/Button";
import { observer } from "mobx-react-lite";
import loginStyles from "./LoginStyles";
import LoginForm from "../components/login/LoginForm";
import RecoverPassForm from "../components/login/RecoverPassForm";
import SessionStorage from "../../data/sessionStorage/SessionStorage";
import { PATHS, ROLES } from "../../infrastructure/app-config";

export interface LoginViewState {
  password: string;
  username: string;
  showPassword: boolean;
}

const LoginView: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const vmUsers = UsersViewModel.getInstance();
  const classes = loginStyles();

  const [errorMessage, setErrorMessage] = React.useState("");
  const [changePassMessage, setChangePassMessage] = React.useState("");
  const [changePassError, setChangePassError] = React.useState(false);
  const [forgotPass, setForgotPass] = React.useState(false);

  const [values, setValues] = React.useState<LoginViewState>({
    password: "",
    username: "",
    showPassword: false,
  });

  const handlePathAfterLogin = (): string => {
    let path: string;
    const role = vmUsers.currentUser?.role;

    if (role === ROLES.ADMIN) {
      path = PATHS.adminTracking;
    } else if (role === ROLES.TERRITORY_MANAGER) {
      path = PATHS.postalAreas;
    } else if (role === ROLES.SUPPORT) {
      path = PATHS.adminTracking;
    } else {
      path = PATHS.login;
    }

    return path;
  };

  const navigate = (res: any) => {
    if (res?.token) {
      window.scrollTo(0, 0);
      const path = handlePathAfterLogin();
      history.push(path);
    } else if (res?.code) {
      //todo improve error codes in order to increase warning verbosity to user
      setErrorMessage(t("login.error.invalidCredentials"));
    } else {
      setErrorMessage(t("login.error.somethingWentWrong"));
    }
  };

  const handleSubmitRecoverPass = () => {
    vmUsers.recoverPass(values.username, navigate).then((res: any) => {
      console.log("handleSubmitRecoverPass res", res);
      if (res !== undefined) {
        setChangePassMessage(t("login.view.recover.pass.ok.message"));
        setChangePassError(false);
      } else {
        setChangePassMessage(t("login.view.recover.pass.ko.message"));
        setChangePassError(true);
      }
    });
  };

  const handleSubmit = () => {
    setErrorMessage("");
    vmUsers.doLogin(values.username, values.password, navigate);
  };

  const handleEnterSubmit = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      setErrorMessage("");
      handleSubmit();
    }
  };

  const rememberPass = () => {
    //params.forgetPassword();
  };

  const handleChange = (prop: keyof LoginViewState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("handleChange => prop = ", prop, "///// event.target.value = ", event.target.value)
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleShowRecover = () => {
    setForgotPass(!forgotPass);
    setChangePassMessage("");
    setChangePassError(false);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      {!forgotPass ? (
        <LoginForm
          classes={classes}
          LogoMobel={LogoMobel}
          handleShowRecover={handleShowRecover}
          handleSubmit={handleSubmit}
          handleEnterSubmit={handleEnterSubmit}
          handleChange={handleChange}
          values={values}
          errorMessage={errorMessage}
        />
      ) : (
        <RecoverPassForm
          LogoMobel={LogoMobel}
          classes={classes}
          handleChange={handleChange}
          values={values}
          handleSubmitRecoverPass={handleSubmitRecoverPass}
          handleShowRecover={handleShowRecover}
          changePassError={changePassError}
          changePassMessage={changePassMessage}
        />
      )}
    </React.Fragment>
  );
};

export default observer(LoginView);
