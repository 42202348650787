/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Shipment,
    ShipmentFromJSON,
    ShipmentFromJSONTyped,
    ShipmentToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageableShipment
 */
export interface PageableShipment {
    /**
     * 
     * @type {number}
     * @memberof PageableShipment
     */
    currentPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableShipment
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableShipment
     */
    resultsPerPage?: number;
    /**
     * 
     * @type {Array<Shipment>}
     * @memberof PageableShipment
     */
    items?: Array<Shipment>;
    /**
     * 
     * @type {number}
     * @memberof PageableShipment
     */
    totalItems?: number;
}

export function PageableShipmentFromJSON(json: any): PageableShipment {
    return PageableShipmentFromJSONTyped(json, false);
}

export function PageableShipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableShipment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentPage': !exists(json, 'currentPage') ? undefined : json['currentPage'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'resultsPerPage': !exists(json, 'resultsPerPage') ? undefined : json['resultsPerPage'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(ShipmentFromJSON)),
        'totalItems': !exists(json, 'totalItems') ? undefined : json['totalItems'],
    };
}

export function PageableShipmentToJSON(value?: PageableShipment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentPage': value.currentPage,
        'totalPages': value.totalPages,
        'resultsPerPage': value.resultsPerPage,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(ShipmentToJSON)),
        'totalItems': value.totalItems,
    };
}


