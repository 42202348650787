/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManagerTerritoryResponse
 */
export interface ManagerTerritoryResponse {
    /**
     * 
     * @type {string}
     * @memberof ManagerTerritoryResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagerTerritoryResponse
     */
    dayOffset?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagerTerritoryResponse
     */
    dayLimit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ManagerTerritoryResponse
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManagerTerritoryResponse
     */
    name?: string;
    /**
     * 
     * @type {array}
     * @memberof ManagerTerritoryResponse
     */
    postalCodes?: string[];
    /**
     * 
     * @type {string}
     * @memberof ManagerTerritoryResponse
     */
    territoryId?: string;
}

export function ManagerTerritoryResponseFromJSON(json: any): ManagerTerritoryResponse {
    return ManagerTerritoryResponseFromJSONTyped(json, false);
}

export function ManagerTerritoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagerTerritoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dayOffset': !exists(json, 'dayOffset') ? undefined : json['dayOffset'],
        'dayLimit': !exists(json, 'dayLimit') ? undefined : json['dayLimit'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'postalCodes': !exists(json, 'postalCodes') ? undefined : json['postalCodes'],
        'territoryId': !exists(json, 'territoryId') ? undefined : json['territoryId'],
    };
}

export function ManagerTerritoryResponseToJSON(value?: ManagerTerritoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postalAreaId': value.id,
        'dayOffset': value.dayOffset,
        'dayLimit': value.dayLimit,
        'active': value.active,
        'name': value.name,
        'postalCodes': value.postalCodes,
        'territoryId': value.territoryId,
    };
}


