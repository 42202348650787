import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Shipment } from "../../client";
import { Step as IStep } from "../../client/models/Step";
import { OwnColors, timer } from "../../commons/Constants";
import usePreciseTimer from "../../commons/TimerHook";
import ChatViewModel from "../../viewmodels/ChatViewModel";
import ShipmentViewModel from "../../viewmodels/ShipmentViewModel";
import UsersViewModel from "../../viewmodels/UsersViewModel";
import ChatComponent from "../components/chat/ChatComponent";
import ConfirmDialog from "../components/commons/ConfirmDialog";
import NavigationTabs from "../components/skeleton/NavigationTabs";
import InProgress from "../components/tracking/InProgress";
import TrackingComponent from "../components/tracking/TrackingComponent";
import adminTrackingStyles from "./AdminTrackingStyles";
import AdminTrackingTable from "./AdminTrackingTable";

const AdminTrackingView: React.FunctionComponent = (props: any) => {
  const classes = adminTrackingStyles();
  const { t } = useTranslation();
  const vmShipment = ShipmentViewModel.getInstance();
  const vmChat = ChatViewModel.getInstance();
  const vmUser = UsersViewModel.getInstance();
  const messages = vmChat.currentChat;
  const myRef = React.useRef<HTMLInputElement>(null);
  const [showAlert, setShowAlert] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState<number>(-1);
  const [stepContent, setStepContent] = React.useState<IStep>();
  const [selectedRowIndex, setSelectedRowIndex] = React.useState<number | undefined>();
  const [selectedShipmentExp, setSelectedShipmentExp] = React.useState<number | undefined>();
  const [filterCheckActive, setFilterCheckActive] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    vmShipment.setTrackingAlb("");
    vmShipment.setTrackingPhone("");
  }, []);

  useEffect(() => {
    setSelectedRowIndex(vmShipment.selectedRowIndex);
    setSelectedShipmentExp(vmShipment.currentShipment?.shipmentExp ? +vmShipment.currentShipment?.shipmentExp : undefined);
  }, [vmShipment.selectedRowIndex, vmShipment.currentShipment]);

  const handleSelectedRef = (ref: Shipment) => {
    if (!vmShipment.loadingShipment) {
      if (ref !== undefined) {
        vmShipment.setSelectedRowIndex(vmShipment.shipmentsAdmin?.indexOf(ref));
        vmShipment.loadShipment(ref.shipmentAlb!, ref.phone!).then(() => {
          setActiveStep(-1);
          window.scrollTo(0, 0);
          checkChatNewMsg();
        });
      } else {
        vmShipment.unLoadShipment();
        vmShipment.setSelectedRowIndex(undefined);
      }
      if (myRef !== null && myRef.current !== null) myRef.current.scrollTo(0, 0);
    } else {
      setShowAlert(true);
    }
  };

  const handleSendChatMsg = (msg: string) => {
    if (msg !== "") {
      vmChat.sendChatMsg(vmShipment.currentShipment!, msg, false, t).then((res) => {
        vmShipment.shipmentsAdmin?.forEach((shipment) => {
          if (shipment.shipmentAlb === vmShipment.currentShipment?.shipmentAlb) shipment.unAnswered = false;
        });
      });
    }
  };

  const handleTableClicks = (e: any) => {
    if (vmShipment.loadingShipment) {
      e.persist();
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
      setShowAlert(true);
    }
  };

  const checkChatNewMsg = () => {
    vmChat.loadChatMsg(t, vmShipment?.currentShipment);
  };

  const handleCheckFilterClick = (checked: boolean) => {
    vmShipment.setIsLoadingInTable(true);
    if (checked) {
      vmShipment.filterExpeditionsByLastMessage().then((res) => vmShipment.setIsLoadingInTable(false));
      setFilterCheckActive(true);
    } else {
      vmShipment.loadAdminShipments().then((res) => vmShipment.setIsLoadingInTable(false));
      setFilterCheckActive(false);
    }
  };

  const handleClickDisableButton = () => {
    let active = vmShipment.currentShipmentResponse?.isChatActive == null ? false : !vmShipment.currentShipmentResponse?.isChatActive;
    vmChat
      .updateChat({
        shipmentExp: vmShipment.currentShipmentResponse?.shipment?.shipmentExp,
        isActive: active,
      })
      .then((res) => {
        vmShipment.currentShipmentResponse!.isChatActive = active;
        setOpen(false);
      });
  };

  usePreciseTimer(checkChatNewMsg, timer, vmShipment.currentShipment);

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={12}>
        {vmUser.currentUser?.role === "Admin" && <NavigationTabs />}
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 30 }}>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox style={{ color: OwnColors.mobelBlue }} onChange={(evt) => handleCheckFilterClick(evt.target.checked)} />
            }
            label={t("trackingAdminView.filterByMessage")}
            style={{ paddingLeft: "20px" }}
          />
          <Box style={{ maxHeight: "90vh", overflow: "auto" }} onClickCapture={(e) => handleTableClicks(e)}>
            <AdminTrackingTable
              handleSelectedRef={handleSelectedRef}
              classes={classes}
              selectedIndex={selectedRowIndex}
              selectedRef={selectedShipmentExp}
              filterCheckActive={filterCheckActive}
            />
          </Box>
        </Grid>
        {!vmShipment.loadingShipment ? (
          <>
            <Grid item xs={12} sm={3}>
              {vmShipment.currentShipment ? (
                <TrackingComponent
                  selectedRef={vmShipment.currentShipmentResponse!}
                  activeStep={activeStep}
                  stepContent={stepContent}
                  eta={vmShipment.currentShipmentResponse!.eta}
                  deliveryType={vmShipment.currentShipmentResponse!.deliveryType}
                  isAdmin={true}
                  managerName={vmUser.currentUser?.name}
                />
              ) : (
                <Typography align={"center"}>{t("trackingAdminView.NoRef")}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={5}>
              <Grid container justify="flex-end" spacing={2} style={{ marginBottom: "1px" }}>
                {vmShipment.currentShipment && vmShipment?.currentShipmentResponse?.shipment?.clientName && (
                  <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                    <Typography align={"center"} style={{ marginLeft: 10 }}>
                      {vmShipment?.currentShipmentResponse?.shipment?.clientName}
                    </Typography>
                  </Grid>
                )}
                {vmShipment.currentShipment && (
                  <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={() => setOpen(true)} className={classes.activateButton}>
                      {vmShipment.currentShipmentResponse?.isChatActive ? "Desactivar" : "Activar"}
                    </Button>
                    <ConfirmDialog
                      open={open}
                      onClose={() => setOpen(false)}
                      onConfirm={handleClickDisableButton}
                      strings={{
                        cancelText: t("disable.dialog.cancel"),
                        confirmText: t("disable.dialog.confirm"),
                        title: vmShipment.currentShipmentResponse?.isChatActive
                          ? t("disable.dialog.title.disabled")
                          : t("disable.dialog.title.active"),
                        description: `${t("disable.dialog.description1")}${
                          vmShipment.currentShipmentResponse?.isChatActive
                            ? t("disable.dialog.title.disabled")
                            : t("disable.dialog.title.active")
                        }${t("disable.dialog.description2")}`,
                      }}
                    />
                  </Grid>
                )}
                {vmShipment.currentShipmentResponse?.isChatActive === false && (
                  <Grid item xs={12} justify="flex-start">
                    <Typography style={{ marginLeft: 10, fontWeight: "bold" }}>{t("disable.chat.disabled")}</Typography>
                  </Grid>
                )}
              </Grid>
              {vmShipment.currentShipment && (
                <ChatComponent cssClasses={classes} messages={messages} handleSendChatMsg={handleSendChatMsg} isActive={true} />
              )}
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={8}>
            <InProgress />
          </Grid>
        )}
      </Grid>
      <ConfirmDialog
        open={showAlert}
        onClose={() => setShowAlert(false)}
        onConfirm={() => setShowAlert(false)}
        strings={{
          title: t("trackingAdminView.loading.reference"),
          description: t("trackingAdminView.loading.reference.description"),
          confirmText: t("trackingAdminView.loading.reference.close"),
        }}
      />
    </Grid>
  );
};

export default observer(AdminTrackingView);
