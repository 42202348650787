import { makeAutoObservable, runInAction } from "mobx";
import { BackOfficeUser, LoginApi, LoginResponse } from "../client";
import UserRepository from "../data/repositories/UserRepository";
import SessionStorage from "../data/sessionStorage/SessionStorage";
import User from "../model/User";
import ShipmentViewModel from "./ShipmentViewModel";

export interface IUser {
  id?: number;
  name?: string;
  email?: string;
  territoryId?: string;
  role?: string;
  password?: string;
  token?: string;
}

export default class UsersViewModel {
  static instance: UsersViewModel;

  static getInstance(): UsersViewModel {
    if (UsersViewModel.instance === undefined) {
      UsersViewModel.instance = new UsersViewModel();
    }
    return UsersViewModel.instance;
  }

  constructor() {
    makeAutoObservable(this);
    this.loadLocalStorageUser();
    this.loadUsers();
  }

  currentUser: IUser | undefined = undefined;
  users: IUser[] | undefined = undefined;

  async recoverPass(username: string, cllbck: Function) {
    /*
    var credentials: Credentials = { username: username };
    const recoverApi = ApiClient.getClient(Api.RegisterApi) as RegisterApi;
    return recoverApi.resetPassword(credentials).then((res: any) => {
        console.log(res)
        return res
    }).catch(error => console.log(error))*/
  }

  async doLogin(email: string, password: string, cllbck: Function) {
    const loginApi = new LoginApi();
    /* TODO Refactor this. standard api call returns with cors problems. Switched to below snippet. Technical debt to refactor.*/
    UserRepository.doLogin(email, password)
      .then((res: LoginResponse) => {
        SessionStorage.setToken(res.token || "");
        SessionStorage.setLocalStorageUser(res.profile || "", 0, email);
        if (res.token) {
          this.currentUser = {
            email: email,
            role: res.profile,
            token: res.token
          };
        }
        ShipmentViewModel.getInstance().unLoadShipment();
        ShipmentViewModel.getInstance().unloadAdminShipments();
        cllbck(res);
      })
      .catch((err) => cllbck(err));
  }

  logOut() {
    SessionStorage.setToken("");
    SessionStorage.setLocalStorageUser("", 0, "");
    this.currentUser = undefined;
    ShipmentViewModel.getInstance().unLoadShipment();
    ShipmentViewModel.getInstance().unloadAdminShipments();
  }

  loadUsers() {
    return runInAction(async () => {
      return UserRepository.getUsers()
        .then((res: BackOfficeUser[]) => {
          if (res === undefined) this.users = undefined;
          else this.users = res;
          return res;
        })
        .catch((error) => {
          this.users = undefined;
          return error;
        });
    });
  }

  setCurrentUserByEmail(email: string): void {
    this.loadUserByEmail(email)
      .then((res) => {
        if (res === undefined) this.currentUser = undefined;
        else this.currentUser = res;
      })
      .catch((error) => {
        this.currentUser = undefined;
        return error;
      });
  }

  loadUserByEmail(email: string): Promise<BackOfficeUser> {
    return new Promise<BackOfficeUser>((resolve, reject) => {
      return UserRepository.getUser(email)
        .then((res: BackOfficeUser) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((e) => {
      return {
        name: "",
        email: "",
        password: "",
        phone: "",
        role: "",
      } as BackOfficeUser;
    });
  }

  loadLocalStorageUser(): boolean {
    var user: User = SessionStorage.getLocalStorageUser();
    if (user.email && user.token) {
      this.currentUser = user;
      this.currentUser.role = user.profile;
      return true;
    } else {
      return false;
    }
  }

  getUsernameByEmail(email: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.loadUserByEmail(email)
        .then((res) => resolve(res.name || ""))
        .catch((err) => reject(err));
    });
  }
}
