import { Typography } from "@material-ui/core";
import { RemoveCircle, CheckCircle, DeleteForever, Edit, Clear } from "@material-ui/icons";
import { Button, Paper, Table, TableBody, TableHead, TableRow } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import React from "react";
import { SpecificDayDTO } from "../../../client";
import { OwnColors } from "../../../commons/Constants";
import { SpecificDayCreationDialog } from "../../components/plazaManager/SpecificDayCreationDialog";
import { StyledTableCell, StyledTableRow } from "./TimeframesTable";
import { DeleteDPostalAreaSpecificDayDialog } from "../../components/plazaManager/DeletePostalAreaSpecificDayDialog";
import { useTranslation } from "react-i18next";
import PostalAreaViewModel from "../../../viewmodels/PostalAreaViewModel";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";

const emptySpecificDay: SpecificDayDTO = {
  active: true,
  date: Number(dayjs().unix().toString().padEnd(13, "0")),
  // id: getRamdomNumber(0, 111111).toString(),
  id: undefined,
  name: "",
  timeFrames: [],
  workDay: true,
};

export const SpecificDaysTable: React.FC<{}> = observer(() => {
  const vmPostalArea = PostalAreaViewModel.getInstance();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [specificDay, setSpecificDay] = React.useState<SpecificDayDTO>(emptySpecificDay);
  const { t } = useTranslation();
  const handleNewTimeframe = () => {
    if (!vmPostalArea.currentPostalArea) return;
    setSpecificDay({ ...emptySpecificDay });
    setOpen(true);
  };

  const handleEditSpecificDay = (specificDay: SpecificDayDTO) => {
    setSpecificDay(specificDay);
    setOpen(true);
  };

  const handleDeletePostalArea = (specificDay: SpecificDayDTO) => {
    setSpecificDay(specificDay);
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper>
      {open ? (
        <SpecificDayCreationDialog handleClose={handleClose} area={vmPostalArea.currentPostalArea!} specificDay={specificDay} />
      ) : null}
      {openDelete ? (
        <DeleteDPostalAreaSpecificDayDialog
          handleClose={() => setOpenDelete(false)}
          postalArea={vmPostalArea.currentPostalArea!}
          specificDay={specificDay}
          open={openDelete}
        />
      ) : null}
      <TableContainer>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography style={{ margin: ".5rem" }}>{t("postalarea.specificdays.table.title")}</Typography>
          <Button
            variant="contained"
            onClick={() => handleNewTimeframe()}
            style={{ width: "17rem", height: "2rem", marginBottom: ".5rem", background: OwnColors.mobelBlue }}
          >
            {t("postalarea.table.add.specificday")}
          </Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell width={"40%"}>{t("postalarea.specificdays.table.columns.name")}</StyledTableCell>
              <StyledTableCell width={"10%"}>{t("postalarea.specificdays.table.columns.active")}</StyledTableCell>
              <StyledTableCell width={"10%"} align="center">
                {t("postalarea.specificdays.table.columns.date")}
              </StyledTableCell>
              <StyledTableCell width={"5%"} align="center">
                {t("postalarea.specificdays.table.columns.activity")}
              </StyledTableCell>
              <StyledTableCell width={"40%"} align="center">
                {t("postalarea.specificdays.table.columns.timeframes")}
              </StyledTableCell>
              <StyledTableCell width={"5%"} align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ maxHeight: 450 }}>
            {vmPostalArea.currentPostalArea?.specificDays?.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.active ? (
                    <CheckCircle style={{ color: OwnColors.lightGreen }} />
                  ) : (
                    <Clear style={{ backgroundColor: OwnColors.lightRed, borderRadius: 15, color: "white" }} fontSize="small" />
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">{dayjs(row.date ?? 0).format("DD/MM/YYYY")}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.workDay ? (
                    <CheckCircle style={{ color: OwnColors.lightGreen }} />
                  ) : (
                    <RemoveCircle style={{ color: OwnColors.lightRed }} />
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <div style={{ display: "flex", pointerEvents: "none", flexWrap: "wrap" }}>
                    {row?.timeFrames?.map((timezone) => (
                      <div
                        key={timezone.id}
                        style={{
                          color: "black",
                          background: "white",
                          border: "1px solid #000",
                          borderRadius: "12px",
                          textAlign: "center",
                          padding: ".3rem",
                          margin: "1px",
                          display: "flex",
                        }}
                      >
                        {timezone.name}
                      </div>
                    ))}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <div style={{ display: "flex" }}>
                    <Edit
                      style={{ width: "1.3rem", marginRight: ".5rem", cursor: "pointer" }}
                      onClick={() => handleEditSpecificDay(row)}
                    />
                    <DeleteForever style={{ width: "1.3rem", cursor: "pointer" }} onClick={() => handleDeletePostalArea(row)} />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});
