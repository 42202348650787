import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";
import { Autocomplete, CircularProgress, Switch, TextField } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PostalAreaDTO, SpecificDayDTO } from "../../../client";
import PostalAreaViewModel from "../../../viewmodels/PostalAreaViewModel";
import { OwnColors } from "../../../commons/Constants";

export const SpecificDayCreationDialog: React.FC<{ handleClose: () => void; area: PostalAreaDTO; specificDay: SpecificDayDTO }> =
  observer(({ handleClose, area, specificDay }) => {
    const { enqueueSnackbar } = useSnackbar();
    const postalareaVm = PostalAreaViewModel.getInstance();
    const [value, setValue] = React.useState<Dayjs | null>();
    const [sday, setSday] = useState<SpecificDayDTO>(specificDay);
    const { t } = useTranslation();

    useEffect(() => {
      setSday(specificDay);
      setValue(dayjs(specificDay.date));
    }, [specificDay]);

    const create = async () => {
      const day = { ...sday, date: (value?.unix() ?? 0) * 1000 };
      const isSuccessfull = await postalareaVm.addSpecificDayForPostalArea(area.id!, day);
      isSuccessfull
        ? enqueueSnackbar(t("postalarea.dialog.common.creation.success"), { variant: "success" })
        : enqueueSnackbar(t("postalarea.dialog.common.deletion.success"), { variant: "error" });
      handleClose();
    };

    const edit = async () => {
      const day = { ...sday, date: (value?.unix() ?? 0) * 1000 };
      const isSuccessfull = await postalareaVm.modifySpecificDayFromPostalArea(area.id!, day);
      isSuccessfull
        ? enqueueSnackbar(t("postalarea.dialog.common.edition.success"), { variant: "success" })
        : enqueueSnackbar(t("postalarea.dialog.common.deletion.success"), { variant: "error" });
      handleClose();
    };

    return (
      <Dialog
        open={true}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: OwnColors.mobelBlue,
            height: "46px",
            textAlign: "start",
            padding: "6px",
          }}
        >
          <DialogTitle
            style={{
              display: "flex",
              color: "white",
            }}
            id="alert-dialog-title"
          >
            {specificDay.id ? t("postalarea.dialog.specificdays.title.edit") : t("postalarea.dialog.specificdays.title")}
          </DialogTitle>
          <IconButton
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disabled={postalareaVm.isLoading}
            style={{ color: "white" }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </div>

        <DialogContent>
          <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
              <label style={{ width: "4rem", paddingRight: "1rem" }}>{t("postalarea.specificdays.dialog.name")}</label>
              <TextField
                placeholder="El pilar"
                variant="standard"
                value={sday?.name}
                onChange={(e) => {
                  setSday({ ...sday, name: e.target.value });
                }}
                style={{ width: "20rem" }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginBottom: "1rem" }}>
              <label style={{ width: "8rem", paddingRight: "1rem" }}>{t("postalarea.specificdays.dialog.frames")}</label>

              <Autocomplete
                multiple
                fullWidth
                id="tags-standard"
                options={area?.timeFrames ?? []}
                value={sday?.timeFrames}
                onChange={(e, v) => {
                  setSday({ ...sday, timeFrames: v ?? [] });
                }}
                getOptionLabel={(option) => option.name ?? ""}
                renderInput={(params) => <TextField {...params} variant="standard" placeholder="Franjas" />}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "start", marginBottom: "1rem" }}>
              <label style={{ width: "8rem", paddingRight: "1rem" }}>{t("postalarea.specificdays.dialog.date")}</label>
              <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} />
            </div>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
              <label style={{ width: "4rem", paddingRight: "1rem" }}>{t("postalarea.specificdays.dialog.working.day")}</label>
              <Switch
                checked={sday?.workDay}
                onChange={(evt, isChecked) => setSday({ ...sday, workDay: isChecked })}
                color="primary"
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
              <label style={{ width: "4rem", paddingRight: "1rem" }}>{t("postalarea.specificdays.dialog.active")}</label>
              <Switch checked={sday?.active} onChange={(evt, isChecked) => setSday({ ...sday, active: isChecked })} color="primary" />
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={handleClose} disabled={postalareaVm.isLoading}>
            {t("postalarea.dialog.postalarea.cancel")}
          </Button>
          {postalareaVm.isLoading ? (
            <div style={{ display: "flex", justifyContent: "start" }}>
              <CircularProgress size={25} style={{ marginRight: "1rem" }} />
            </div>
          ) : (
            <Button
              style={{ backgroundColor: OwnColors.lightGreen, color: "white" }}
              onClick={() => (specificDay.id ? edit() : create())}
            >
              {specificDay.id ? t("postalarea.dialog.postalarea.save") : t("postalarea.dialog.postalarea.create")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  });
