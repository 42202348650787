/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerTimeFrame
 */
export interface CustomerTimeFrame {
    /**
     * 
     * @type {string}
     * @memberof CustomerTimeFrame
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTimeFrame
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTimeFrame
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTimeFrame
     */
    frameType?: CustomerTimeFrameFrameTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerTimeFrame
     */
    postalCode?: string;
}

export function CustomerTimeFrameFromJSON(json: any): CustomerTimeFrame {
    return CustomerTimeFrameFromJSONTyped(json, false);
}

export function CustomerTimeFrameFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerTimeFrame {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'endTime': !exists(json, 'endTime') ? undefined : json['endTime'],
        'frameType': !exists(json, 'frameType') ? undefined : json['frameType'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
    };
}

export function CustomerTimeFrameToJSON(value?: CustomerTimeFrame | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'startTime': value.startTime,
        'endTime': value.endTime,
        'frameType': value.frameType,
        'postalCode': value.postalCode,
    };
}

/**
* @export
* @enum {string}
*/
export enum CustomerTimeFrameFrameTypeEnum {
    GENERIC = 'GENERIC',
    SPECIFIC = 'SPECIFIC'
}


