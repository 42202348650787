import { makeStyles } from '@material-ui/core';

const viewStyles = makeStyles(theme => ({
    content: {
      marginTop: 54,
      [theme.breakpoints.up('sm')]: {
        marginTop: 64,
      },
      widht: '100%'
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    image: {
      maxWidth: 600,
      width: '100%',
      alignContent: "left"
    },
    imageGrid: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    textGrid: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textPrimaryBlueBig: {
      color: "#154179",
      [theme.breakpoints.down('sm')]: {
        display: "none",
      },
    },
    textPrimaryBlueSmall: {
      color: "#154179",
      [theme.breakpoints.down('xs')]: {
        display: "none"
      },
      [theme.breakpoints.up('md')]: {
        display: "none"
      },
    },
    textPrimaryBlueReallySmall: {
      color: "#154179",
      [theme.breakpoints.up('sm')]: {
        display: "none"
      },
    }
  }));

  export default viewStyles;