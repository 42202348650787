/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SpecificDayDTO,
    SpecificDayDTOFromJSON,
    SpecificDayDTOFromJSONTyped,
    SpecificDayDTOToJSON,
    TimeFrameDTO,
    TimeFrameDTOFromJSON,
    TimeFrameDTOFromJSONTyped,
    TimeFrameDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PostalAreaDTO
 */
export interface PostalAreaDTO {
    /**
     * 
     * @type {string}
     * @memberof PostalAreaDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PostalAreaDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PostalAreaDTO
     */
    dayOffset?: number;
    /**
     * 
     * @type {number}
     * @memberof PostalAreaDTO
     */
    dayLimit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PostalAreaDTO
     */
    active?: boolean;
    /**
     * 
     * @type {Array<TimeFrameDTO>}
     * @memberof PostalAreaDTO
     */
    timeFrames?: Array<TimeFrameDTO>;
    /**
     * 
     * @type {Array<SpecificDayDTO>}
     * @memberof PostalAreaDTO
     */
    specificDays?: Array<SpecificDayDTO>;
    /**
     * 
     * @type {Array<SpecificDayDTO>}
     * @memberof PostalAreaDTO
     */
    postalCodes?: Array<string>;
}

export function PostalAreaDTOFromJSON(json: any): PostalAreaDTO {
    return PostalAreaDTOFromJSONTyped(json, false);
}

export function PostalAreaDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostalAreaDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'postalCodes': !exists(json, 'postalCodes') ? undefined : (json['postalCodes'] as Array<string>),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dayOffset': !exists(json, 'dayOffset') ? undefined : json['dayOffset'],
        'dayLimit': !exists(json, 'dayLimit') ? undefined : json['dayLimit'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'timeFrames': !exists(json, 'timeFrames') ? undefined : ((json['timeFrames'] as Array<any>).map(TimeFrameDTOFromJSON)),
        'specificDays': !exists(json, 'specificDays') ? undefined : ((json['specificDays'] as Array<any>).map(SpecificDayDTOFromJSON)),
    };
}

export function PostalAreaDTOToJSON(value?: PostalAreaDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    console.log("PostalAreaDTOToJSON "+JSON.stringify(value))
    return {
        'postalCodes': value.postalCodes,
        'name': value.name,
        'id': value.id,
        'dayOffset': value.dayOffset,
        'dayLimit': value.dayLimit,
        'active': value.active,
        'timeFrames': value.timeFrames === undefined ? undefined : ((value.timeFrames as Array<any>).map(TimeFrameDTOToJSON)),
        'specificDays': value.specificDays === undefined ? undefined : ((value.specificDays as Array<any>).map(SpecificDayDTOToJSON)),
    };
}


