import "./App.css";
import Router from "./infrastructure/router/Router";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackbarProvider } from "notistack";
import i18n from "./infrastructure/i18n/i18n";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/pt";
import "dayjs/locale/en";

function App() {
  return (
    <SnackbarProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={String(i18n.language) ?? "es"}>
        <Router currentLang={"es"} logged={false} />
      </LocalizationProvider>
    </SnackbarProvider>
  );
}

export default App;
