/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Chat,
    ChatFromJSON,
    ChatToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface UpdateChatRequest {
    chat?: Chat;
}

/**
 * no description
 */
export class ChatApi extends runtime.BaseAPI {

    /**
     * updateChat
     * updateChat
     */
    async updateChatRaw(requestParameters: UpdateChatRequest): Promise<runtime.ApiResponse<Chat>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/chat`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChatToJSON(requestParameters.chat),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatFromJSON(jsonValue));
    }

    /**
     * updateChat
     * updateChat
     */
    async updateChat(chat?: Chat): Promise<Chat> {
        const response = await this.updateChatRaw({ chat: chat });
        return await response.value();
    }

}
