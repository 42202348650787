import { OwnColors } from "../../../../commons/Constants";

export const headerStyles = {
  mainContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: OwnColors.mobelBlue,
  },
  dialogTitle: {
    color: "white",
  },
  dialogButton: {
    color: "white",
  },
  closeButton: {
    color: "GrayText",
  },
};

export const bodyStyles = {
  dialogContent: { padding: 0, marginTop:"1rem" },
  mainContainer: { justifyContent: "center", paddingLeft: 5, paddingRight: 5 },
  timeframesContainer: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  timeframeItem: {
    padding: ".5rem",
    paddingBottom: ".5rem",
    height: "2.5rem",
    border: "1px solid #0F407A",
    borderRadius: "6px",
    marginBottom: "1rem",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
  },
  noTimeframesMessage: {
    color: "red",
  },
  selectedTimeFrameContainer: {
    width: "inherit",
    paddingLeft: 5,
    paddingRight: 5,
    justifyContent: "center",
  },
};
