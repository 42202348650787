import { useTranslation } from "react-i18next/";
import { TextField, Button, Typography, makeStyles, Card, CardContent, CardHeader, CardActions } from "@material-ui/core";
import { OwnColors } from "../../../commons/Constants";

const useInputDialogStyles = makeStyles({
  card: { margin: "10px" },
  cardTitle: {
    backgroundColor: OwnColors.mobelBlue,
    color: "#FFFFFF",
  },
  button: {
    color: OwnColors.mobelBlue,
    backgroundColor: "#FFFFFF",
    "&:disabled": {
      backgroundColor: OwnColors.lightGrey,
      color: OwnColors.grey,
    },
  },
  textField: { width: "200px" },
});

type TInputCard = {
  text?: string;
  error?: boolean;
  executingFunction?: (value: string) => void;
  submitFunction?: () => void;
};

const InputCard = ({ text, error, executingFunction = (value?: string) => {}, submitFunction = () => {} }: TInputCard) => {
  const classes = useInputDialogStyles();
  const { t } = useTranslation();

  return (
    <Card elevation={5} className={classes.card}>
      <CardHeader className={classes.cardTitle} title={<Typography variant="h6">{t("inputCard.title")}</Typography>} />
      <CardContent>
        <Typography>{t("inputCard.body")}</Typography>
        <TextField
          className={classes.textField}
          label={t(error ? "inputCard.label.nok" : "inputCard.label.ok")}
          error={error}
          onChange={(evt) => executingFunction(evt.target.value!)}
        />
      </CardContent>
      <CardActions>
        <Button variant="outlined" className={classes.button} disabled={text === ""} onClick={submitFunction}>
          {t("inputCard.button")}
        </Button>
      </CardActions>
    </Card>
  );
};

export default InputCard;
