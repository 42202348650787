import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  styled,
  TableRow,
  tableCellClasses,
  Paper,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import React, { useState } from "react";
import { OwnColors } from "../../../commons/Constants";
import { PostalAreaCreateDialog } from "../../components/plazaManager/PostalAreaCreationDialog";
import { DeleteForever, Edit, Check, Clear, Search } from "@material-ui/icons";
import { DeletePostalAreaDialog } from "../../components/plazaManager/DeletePostalAreaDialog";
import { useTranslation } from "react-i18next";
import PostalAreaViewModel from "../../../viewmodels/PostalAreaViewModel";
import { observer } from "mobx-react-lite";
import { ManagerTerritoryResponse, PostalAreaDTO } from "../../../client";
import { VariantType, useSnackbar } from "notistack";

export const emptyPostalArea: PostalAreaDTO = {
  active: true,
  dayLimit: undefined,
  dayOffset: undefined,
  id: undefined,
  specificDays: [],
  timeFrames: [],
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: OwnColors.mobelBlue,
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f5f5f5",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const iconStyles: React.CSSProperties = {
  borderRadius: 15,
  color: "white",
};

interface IPostalAreaTableProps {
  currentPage?: number;
  rowsPerPage?: number;
  totalItems?: number;
  currentItems?: ManagerTerritoryResponse[];
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  getPostalAreas: (pageNumber: number, pageSize: number) => void;
}

export const PostalAreaTable: React.FC<IPostalAreaTableProps> = observer(
  ({ getPostalAreas, handleChangePage, handleChangeRowsPerPage, currentItems, currentPage, rowsPerPage, totalItems }) => {
    const vmPostalArea = PostalAreaViewModel.getInstance();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [postalArea, setPostalArea] = useState<PostalAreaDTO>(emptyPostalArea);

    const handleNewPostalArea = () => {
      setPostalArea({ ...emptyPostalArea });
      setOpen(true);
    };

    const handleSearchPostalCode = () => vmPostalArea.getPostalAreasForManagerPaginated((currentPage ?? 0) + 1, rowsPerPage ?? 0);

    const handlePostalFilterChange = (value?: string) => vmPostalArea.setFilter(value);

    const handleCleanFilter = () => vmPostalArea.cleanFilter();

    const handleCreateClose = () => setOpen(false);

    const handleDeleteClose = () => setOpenDelete(false);

    const handleEditPostalArea = (postalArea: ManagerTerritoryResponse) => {
      setPostalArea({
        active: postalArea.active,
        dayLimit: postalArea.dayLimit,
        dayOffset: postalArea.dayOffset,
        id: postalArea.id,
        name: postalArea.name,
        specificDays: [],
        timeFrames: [],
        postalCodes:postalArea.postalCodes
      });
      setOpen(true);
    };

    const openSnackbarWithMessage = (message: string, variant: VariantType) => enqueueSnackbar(message, { variant: variant });

    const create = async (pa: PostalAreaDTO) => {
      console.log("create PA "+JSON.stringify(pa))
      const [message, severity] = (await vmPostalArea.createPostalArea(pa))
        ? [t("postalarea.dialog.common.creation.success"), "success" as VariantType]
        : [t("postalarea.dialog.common.failure"), "error" as VariantType];

      openSnackbarWithMessage(message, severity);
      getPostalAreas(currentPage ?? 0, rowsPerPage ?? 0);
      handleCreateClose();
    };

    const edit = async (pa: PostalAreaDTO) => {
      const [message, severity] = (await vmPostalArea.editPostalArea(pa))
        ? [t("postalarea.dialog.common.edition.success"), "success" as VariantType]
        : [t("postalarea.dialog.common.failure"), "error" as VariantType];

      openSnackbarWithMessage(message, severity);
      getPostalAreas(currentPage ?? 0, rowsPerPage ?? 0);
      handleCreateClose();
    };

    const remove = async (id: string) => {
      const [message, severity] = (await vmPostalArea.deletePostalArea(id))
        ? [t("postalarea.dialog.common.deletion.success"), "success" as VariantType]
        : [t("postalarea.dialog.common.failure"), "error" as VariantType];

      openSnackbarWithMessage(message, severity);
      getPostalAreas(currentPage ?? 0, rowsPerPage ?? 0);
      handleDeleteClose();
    };

    const handleDeletePostalArea = (postalArea: ManagerTerritoryResponse) => {
      setPostalArea({
        active: postalArea.active,
        dayLimit: postalArea.dayLimit,
        dayOffset: postalArea.dayOffset,
        id: postalArea.id,
        name: postalArea.name,
        specificDays: [],
        timeFrames: [],
      });
      setOpenDelete(true);
    };

    return (
      <Paper>
        <Grid container justifyContent="space-between" style={{ padding: ".5rem" }}>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              onClick={handleNewPostalArea}
              style={{ width: "17rem", height: "100%", background: OwnColors.mobelBlue }}
            >
              {t("postalarea.table.add.postalarea")}
            </Button>
          </Grid>
          <Grid container item xs={12} md={6} justifyContent="space-evenly">
            <TextField
              label={t("postalarea.table.search.label")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCleanFilter}>
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(evt) => handlePostalFilterChange(evt.target.value)}
              value={vmPostalArea.postalFilter ?? ""}
              variant="outlined"
              size="small"
              style={{ color: OwnColors.mobelBlue }}
            />
            <Button variant="contained" onClick={handleSearchPostalCode} style={{ background: OwnColors.mobelBlue }}>
              <Search htmlColor="white" />
            </Button>
          </Grid>
        </Grid>

        <PostalAreaCreateDialog
          handleClose={handleCreateClose}
          open={open}
          postalArea={postalArea}
          create={create}
          edit={edit}
          isLoading={vmPostalArea.isLoading}
        />
        <DeletePostalAreaDialog
          handleClose={handleDeleteClose}
          open={openDelete}
          postalAreaId={postalArea.id ?? ""}
          isLoading={vmPostalArea.isLoading}
          remove={remove}
        />
        <TableContainer>
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell width={"25%"}>{t("postalarea.table.columns.name")}</StyledTableCell>
                <StyledTableCell width={"25%"} align="center">
                  {t("postalarea.table.columns.daysmargin")}
                </StyledTableCell>
                <StyledTableCell width={"20%"} align="center">
                  {t("postalarea.table.columns.dayslimit")}
                </StyledTableCell>
                <StyledTableCell width={"20%"} align="center">
                  {t("postalarea.table.columns.active")}
                </StyledTableCell>
                <StyledTableCell width={"10%"} align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentItems?.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.dayOffset}</StyledTableCell>
                  <StyledTableCell align="center">{row.dayLimit}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.active ? (
                      <Check style={{ backgroundColor: OwnColors.lightGreen, ...iconStyles }} fontSize="small" />
                    ) : (
                      <Clear style={{ backgroundColor: OwnColors.lightRed, ...iconStyles }} fontSize="small" />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <div style={{ display: "flex" }}>
                      <Edit
                        style={{ width: "1.3rem", marginRight: ".5rem", cursor: "pointer" }}
                        onClick={() => handleEditPostalArea(row)}
                      />
                      <DeleteForever style={{ width: "1.3rem", cursor: "pointer" }} onClick={() => handleDeletePostalArea(row)} />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalItems ?? 0}
          rowsPerPage={rowsPerPage ?? 0}
          page={currentPage ?? 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
);
