const ROLES = {
  ADMIN: "Admin",
  TERRITORY_MANAGER: "TERRITORY_MANAGER",
  SUPPORT: "SUPPORT"
};

const PATHS = {
  root: "/",
  tracking: "/tracking",
  adminTracking: "/tracking-admin",
  adminAccount: "/account-admin",
  postalAreas: "/postal-areas",
  timeFrames: "/time-frames",
  login: "/login",
};

export { ROLES, PATHS };
