const prefix = "REACT_APP_";
const suffix = "_"+process.env.REACT_APP_ENV

export function getEnvURL_BE():string{
    return process.env[prefix + "BE_URL" + suffix] || "";
}

export function getEnvURL_FE():string{
    return process.env[prefix + "FE_URL" + suffix] || "";
}
