import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MUIButton from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {     
      width: "100%",
      '& > *': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
  }),
);

export interface IButton {
  variant?: "text" | "outlined" | "contained"
  color?: string
  textColor?: string
  size?: "small" | "medium" | "large"
  text: string
  onClick: Function
  disabled?: boolean
  //TODO: add icon support
}

export default function Button(params: IButton) {
  const classes = useStyles();

  // when the user clicks outside the dialog
  const handleClick = () => {
    params.onClick()
  }

  return (
      <div className={classes.root}>
        <MUIButton
          variant={params.variant}
          fullWidth
          style={params.disabled?
            {
              background: "#dbdbdb",
              color: "#a3a2a2",
              fontSize: "0.65rem"
            }:
            {background: params.variant === "contained" ? params.color : "",
            color: params.textColor,
            fontSize: "0.65rem"
          }}
          size={params.size}
          onClick={handleClick}
          disabled={params.disabled}
        >
          {params.text}
        </MUIButton>
      </div>
  );
}