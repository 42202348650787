export const OwnColors = {
    redError: "#FFC9CF",
    mobelBlue: "#03A3DF",
    lightBlue: "#BFDEE8",
    blueGrey: "#80BFD6",
    grey: "#B2BCBF",
    darkGrey: "#5a5a5a",
    lightGrey: "#EFEFEF",
    alertBlur: "#f8e4a0",
    success: '#4CAC1C',
    unavailable: "#9E9E9E",
    lightRed: "#fe3939",
    lightGreen: "#67b757"
}

export const timer = 5000