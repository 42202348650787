
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const getMuiTheme = () => createMuiTheme({
    overrides: {  
      MUIDataTableToolbar: {
        root: {
          backgroundColor: "#fafafa",
          boxShadow: "0px 4px 4px -1px rgba(0,0,0,0.27) !important",
          marginBottom: 8
        },
        actions:{
          display:"contents"
        },
        icon:{
          color:"rgba(0, 0, 0, 0.54);"
        },
        titleText:{
          color:'rgba(0, 0, 0, 0.54);'
        },
        iconActive:{
          color:'white !important'
        }
      },
      MUIDataTableHeadCell:{
        root:{
          color:'black !important',
          fontWeight: "bold"
        }
      },
      MUIDataTableResize:{
        resizer:{
          width:0
        }
      },
      MUIDataTableToolbarSelect:{
        root:{
          backgroundColor:"#fafafa !important",
          color:'rgba(0, 0, 0, 0.54)',
          boxShadow: "0px 4px 4px -1px rgba(0,0,0,0.27) !important",
          marginBottom: 8,
          height:64,
        },
        title:{
          width: "max-content"
        }
      },
      MuiPaper:{
        rounded:{
          borderRadius:0
        }
      },
      MUIDataTableSearch: {
        searchIcon: {
          color: 'rgba(0,0,0,0.54)',
          margin: 0,
          marginTop: 12
        },
        searchText:{
          marginTop:8
        }
      },
      MuiInputBase: {
        input:{
          color:"rgba(0,0,0,0.54)"
        },
        
      },
      MUIDataTablePagination:{
        tableCellContainer:{
          backgroundColor: '#fafafa !important',
          boxShadow: "-2px 0px 4px 3px rgba(0,0,0,0.27) !important",
          zIndex: 300
        }
      },
      MuiTypography:{
        colorInherit:{
          color: "rgba(0,0,0,0.54)"
        }
      },
      MuiTablePagination: {
        root:{
          color: "rgba(0,0,0,0.54) !important",
         
        },
        selectIcon:{
          color: "rgba(0,0,0,0.54) !important",
        },
        toolbar:{
          marginRight:30,
          padding:"0px !important"
        }
      },
      MuiTableCell:{
        footer:{
          backgroundColor:"#3f51b5 !important",
          //position: "fixed",
          width: '100%',
          //bottom: 0
        }
      },
      MuiIconButton:{
        root:{
          color:"rgba(0, 0, 0, 0.54)",
          '&:hover':{
            color:"#3f51b5 !important",
            backgroundColor:'rgba(255, 255, 255, 0.38)'
          }
          
        }
      },
      MuiGrid:{
        container:{
          justifyContent: "flex-end",
          alignItems: "center"
        }
      },
      MUIDataTableSelectCell:{
        icon:{
          color: "black"
        }
      },
     
    }
  })

export default getMuiTheme;