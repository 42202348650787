import { Backdrop, CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useEffect } from "react";
import UsersViewModel from "../../../viewmodels/UsersViewModel";
import usePlazaManagerTimeZonesViewStyles from "./TimeZonesView.styles";
import ManagerNavigationTabs from "../../components/plazaManager/ManagerNavigationTabs";
import { SpecificDaysTable } from "./SpecificDaysTable";
import { TimeframeTable } from "./TimeframesTable";
import PostalAreaViewModel from "../../../viewmodels/PostalAreaViewModel";
import { ROLES } from "../../../infrastructure/app-config";
import { useTranslation } from "react-i18next";

const PlazaManagerPreAppointmentView: React.FunctionComponent = (props: any) => {
  const vmPostalArea = PostalAreaViewModel.getInstance();
  const vmUsers = UsersViewModel.getInstance();
  const classes = usePlazaManagerTimeZonesViewStyles();
  const { t } = useTranslation();

  useEffect(() => {
    vmPostalArea.getPostalAreasIdList();
  }, [vmPostalArea]);

  const onChangeValue = (_: SyntheticEvent<Element, Event>, areaId: string | null | undefined) => {
    if (areaId) {
      vmPostalArea.getPostalAreaById(areaId);
    } else {
      vmPostalArea.currentPostalArea = undefined;
    }
  };

  return (
    <div className={classes.contentUsers}>
      <div>{vmUsers.currentUser?.role === ROLES.TERRITORY_MANAGER && <ManagerNavigationTabs />}</div>
      {vmUsers.users?.length === 0 ? (
        <div>
          <CircularProgress className={classes.circleProgress} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <div style={{ width: "50rem" }}>
            <Autocomplete
              disablePortal
              id="postalAreas"
              style={{ width: "10rem", marginBottom: "2rem" }}
              options={vmPostalArea.postalAreasIds ?? []}
              onChange={onChangeValue}
              defaultValue={vmPostalArea.currentPostalArea?.name ?? ""}
              sx={{ width: 300 }}
              getOptionLabel={(option) => {
                console.log("TTT")
                console.log("option: "+option)
                console.log("postalAreas: "+JSON.stringify(vmPostalArea.postalAreas))
                return vmPostalArea.postalAreas.find(pa => pa.id === option)?.name ?? ""
              }}
              renderInput={(params) => <TextField {...params} label={t("site.manager.timezone.view.autocomplete.label")} />}
            />
            <TimeframeTable />
          </div>

          <div style={{ width: "50rem", marginTop: "5rem", marginBottom: "5rem" }}>
            <SpecificDaysTable />
          </div>
        </div>
      )}
      <Backdrop
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          position: "fixed",
          zIndex: 10,
        }}
        open={vmPostalArea.isLoading}
      />
    </div>
  );
};

export const days = ["L", "M", "X", "J", "V", "S", "D"];

export default observer(PlazaManagerPreAppointmentView);
