import { Box, Grid, TextField, Typography, InputAdornment, IconButton } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Message } from "../../../client";
import { OwnColors } from "../../../commons/Constants";
import ChatLine from "./ChatLine";
import SendIcon from "@material-ui/icons/Send";
import useStyles from "./ChatComponentCss";
import ContactSupport from "@material-ui/icons/ContactSupport";

export interface IChatComponentsProps {
  cssClasses: any;
  messages: Message[];
  handleSendChatMsg: (msg: string) => void;
  isActive: boolean;
}

const ChatComponent: React.FunctionComponent<IChatComponentsProps> = (props: IChatComponentsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [ongoingMessage, setOngoingMessage] = React.useState("");
  const keyPress = (e: any) => {
    if (e.keyCode === 13 && ongoingMessage.length <= 500) {
      props.handleSendChatMsg(ongoingMessage);
      setOngoingMessage("");
    }
  };

  const buildDateString = (date: Date): string => {
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  const buildTimeString = (date: Date): string => {
    let minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    return `${date.getHours()}:${minutes}`;
  };

  return (
    <div className={classes.mainContent}>
      {/* <Grid container item xs={12} justify="center">
        <Grid item xs={6} style={{ position: "absolute", zIndex: -1, opacity: 0.5 }}>
          <Grid container item xs={12} justify="center">
            <svg style={{ height: "250px", marginTop: "5%" }}>
              <ContactSupport style={{ color: OwnColors.blueGrey }} />
            </svg>
          </Grid>
          <Grid container item xs={12} justify="center">
            <Typography align="center" variant="h4" style={{ fontWeight: "bold", color: OwnColors.blueGrey }}>
              {t("chatComponent.greeting")}
            </Typography>
          </Grid>
        </Grid>
      </Grid> */}
      <div style={{ display: "flex", flexDirection: "column", height: "105%" }}>
        <Box className={classes.chatBox} style={{ overflowY: "scroll" }}>
          <div>
            {props.messages?.length !== 0 ? (
              <div>
                {props.messages?.map((msg, n) => {
                  let userLine = msg.clientId === msg.messageOwnerId ? true : false;
                  return (
                    <div style={{ margin: 10, marginTop: n !== 0 ? 10 : 0, opacity: 0.9 }}>
                      <ChatLine
                        userLine={userLine}
                        chatText={msg.message!}
                        messageDate={buildDateString(msg.datetime!)}
                        messageHour={buildTimeString(msg.datetime!)}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <Typography style={{ paddingTop: 15 }} align={"center"}>
                {t("chatComponent.noMsg")}
              </Typography>
            )}
          </div>
        </Box>
        <Box
          display="flex"
          style={{
            color: "white",
            background: "#fff",
            borderRadius: "6px",
            padding: 10,
            marginBottom: 10,
          }}
        >
          <Grid item xs={12} direction={"row"}>
            <TextField
              error={ongoingMessage.length >= 501}
              variant={"outlined"}
              size={"small"}
              fullWidth
              style={{ width: "100%" }}
              value={ongoingMessage}
              onChange={(text) => setOngoingMessage(text.target.value)}
              onKeyDown={keyPress}
              disabled={!props.isActive}
              helperText={`${t("chatComponent.charNumber")} ${ongoingMessage.length} / 500`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="send msg"
                      onClick={() => {
                        props.handleSendChatMsg(ongoingMessage);
                        setOngoingMessage("");
                      }}
                      edge="end"
                      style={{ color: OwnColors.mobelBlue }}
                      disabled={ongoingMessage.length >= 501 || !props.isActive}
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default ChatComponent;
