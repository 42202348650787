/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ExpeditionStatusResponse,
    ExpeditionStatusResponseFromJSON,
    ExpeditionStatusResponseToJSON,
} from '../models';

export interface GetStatusExpeditionByRefRequest {
    expParam: number;
}

/**
 * no description
 */
export class ExpeditionApi extends runtime.BaseAPI {

    /**
     * getStatusExpeditionByRef
     * getStatusExpeditionByRef
     */
    async getStatusExpeditionByRefRaw(requestParameters: GetStatusExpeditionByRefRequest): Promise<runtime.ApiResponse<ExpeditionStatusResponse>> {
        if (requestParameters.expParam === null || requestParameters.expParam === undefined) {
            throw new runtime.RequiredError('expParam','Required parameter requestParameters.expParam was null or undefined when calling getStatusExpeditionByRef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/expeditions/{expParam}`.replace(`{${"expParam"}}`, encodeURIComponent(String(requestParameters.expParam))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExpeditionStatusResponseFromJSON(jsonValue));
    }

    /**
     * getStatusExpeditionByRef
     * getStatusExpeditionByRef
     */
    async getStatusExpeditionByRef(expParam: number): Promise<ExpeditionStatusResponse> {
        const response = await this.getStatusExpeditionByRefRaw({ expParam: expParam });
        return await response.value();
    }

}
