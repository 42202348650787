import { makeStyles } from "@material-ui/core";
import { OwnColors } from "../../../commons/Constants";

export const informationPanelStyles = makeStyles({
  mainContainer: {
    backgroundColor: OwnColors.alertBlur,
    padding: "10px 5px 10px 5px",
    marginBottom: 10,
  },
  infoIcon: {
    color: OwnColors.mobelBlue
  },
  closeButton: {
    padding: 0,
    color: "black",
  },
});
