/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof Message
     */
    datetime?: Date;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    shipmentExp?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    accountManagerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    messageOwnerId?: string;
}

export function MessageFromJSON(json: any): Message {
    return MessageFromJSONTyped(json, false);
}

export function MessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Message {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'datetime': !exists(json, 'datetime') ? undefined : (new Date(json['datetime'])),
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'shipmentExp': !exists(json, 'shipmentExp') ? undefined : json['shipmentExp'],
        'accountManagerId': !exists(json, 'accountManagerId') ? undefined : json['accountManagerId'],
        'messageOwnerId': !exists(json, 'messageOwnerId') ? undefined : json['messageOwnerId'],
    };
}

export function MessageToJSON(value?: Message | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'message': value.message,
        'datetime': value.datetime === undefined ? undefined : (value.datetime.toISOString()),
        'clientId': value.clientId,
        'shipmentExp': value.shipmentExp,
        'accountManagerId': value.accountManagerId,
        'messageOwnerId': value.messageOwnerId,
    };
}


