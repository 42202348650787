/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BackOfficeUser,
    BackOfficeUserFromJSON,
    BackOfficeUserToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface CreateBackOfficeUserRequest {
    backOfficeUser?: BackOfficeUser;
}

export interface DeleteBackOfficeUserByEmailRequest {
    email: string;
}

export interface GetBackOfficeUserByEmailRequest {
    email: string;
}

export interface UpdateBackOfficeUserByEmailRequest {
    email: string;
    backOfficeUser?: BackOfficeUser;
}

/**
 * no description
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Endpoint for createUsers
     * createBackOfficeUser
     */
    async createBackOfficeUserRaw(requestParameters: CreateBackOfficeUserRequest): Promise<runtime.ApiResponse<BackOfficeUser>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/backofficeuser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BackOfficeUserToJSON(requestParameters.backOfficeUser),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BackOfficeUserFromJSON(jsonValue));
    }

    /**
     * Endpoint for createUsers
     * createBackOfficeUser
     */
    async createBackOfficeUser(backOfficeUser?: BackOfficeUser): Promise<BackOfficeUser> {
        const response = await this.createBackOfficeUserRaw({ backOfficeUser: backOfficeUser });
        return await response.value();
    }

    /**
     * deleteBackOfficeUserByEmail
     * deleteBackOfficeUserByEmail
     */
    async deleteBackOfficeUserByEmailRaw(requestParameters: DeleteBackOfficeUserByEmailRequest): Promise<runtime.ApiResponse<ErrorResponse>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling deleteBackOfficeUserByEmail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/backofficeuser/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ErrorResponseFromJSON(jsonValue));
    }

    /**
     * deleteBackOfficeUserByEmail
     * deleteBackOfficeUserByEmail
     */
    async deleteBackOfficeUserByEmail(email: string): Promise<ErrorResponse> {
        const response = await this.deleteBackOfficeUserByEmailRaw({ email: email });
        return await response.value();
    }

    /**
     * Endpoint for list all backOfficeusers
     * getAllBackOfficeUsers
     */
    async getAllBackOfficeUsersRaw(): Promise<runtime.ApiResponse<Array<BackOfficeUser>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/backofficeuser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BackOfficeUserFromJSON));
    }

    /**
     * Endpoint for list all backOfficeusers
     * getAllBackOfficeUsers
     */
    async getAllBackOfficeUsers(): Promise<Array<BackOfficeUser>> {
        const response = await this.getAllBackOfficeUsersRaw();
        return await response.value();
    }

    /**
     * getBackOfficeUserByEmail
     * getBackOfficeUserByEmail
     */
    async getBackOfficeUserByEmailRaw(requestParameters: GetBackOfficeUserByEmailRequest): Promise<runtime.ApiResponse<BackOfficeUser>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling getBackOfficeUserByEmail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/backofficeuser/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BackOfficeUserFromJSON(jsonValue));
    }

    /**
     * getBackOfficeUserByEmail
     * getBackOfficeUserByEmail
     */
    async getBackOfficeUserByEmail(email: string): Promise<BackOfficeUser> {
        const response = await this.getBackOfficeUserByEmailRaw({ email: email });
        return await response.value();
    }

    /**
     * updateBackOfficeUserByEmail
     * updateBackOfficeUserByEmail
     */
    async updateBackOfficeUserByEmailRaw(requestParameters: UpdateBackOfficeUserByEmailRequest): Promise<runtime.ApiResponse<BackOfficeUser>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling updateBackOfficeUserByEmail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/backofficeuser/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BackOfficeUserToJSON(requestParameters.backOfficeUser),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BackOfficeUserFromJSON(jsonValue));
    }

    /**
     * updateBackOfficeUserByEmail
     * updateBackOfficeUserByEmail
     */
    async updateBackOfficeUserByEmail(email: string, backOfficeUser?: BackOfficeUser): Promise<BackOfficeUser> {
        const response = await this.updateBackOfficeUserByEmailRaw({ email: email, backOfficeUser: backOfficeUser });
        return await response.value();
    }

}
