import React from "react";
import {
  Box,
  Link,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Container
} from "@material-ui/core";

import { OwnColors } from "../../../commons/Constants";
import { useTranslation } from "react-i18next";
import { Copyright } from "@material-ui/icons";
import Button from "../commons/Button";
import { useHistory } from "react-router-dom";
import { LoginViewState } from "../../loginView/LoginView";

export interface ILoginFormProps {
    classes: any,
    LogoMobel: string,
    handleShowRecover: ()=>void,
    handleSubmit: ()=>void,
    handleEnterSubmit: (event: React.KeyboardEvent)=>void,
    handleChange: (prop: keyof LoginViewState) => (event: React.ChangeEvent<HTMLInputElement>)=>void,
    values: any,
    errorMessage: string
}

const LoginForm: React.FunctionComponent<ILoginFormProps> = (props: ILoginFormProps) => {

    const {t} = useTranslation();
    const history = useHistory();
    
    return <Container component="main" maxWidth="xs">
        <div className={props.classes.paper}>
            <Grid container spacing={2} style={{ marginTop: 15 }}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        value={props.values.username}
                        id="email"
                        label={t("login.email.address")}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={props.handleChange("username")}
                        onKeyPress={(event: React.KeyboardEvent) => props.handleEnterSubmit(event)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        value={props.values.password}
                        label={t("login.password")}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={props.handleChange("password")}
                        onKeyPress={(event: React.KeyboardEvent) => props.handleEnterSubmit(event)}
                    />
                </Grid>
            </Grid>
            <Button
                variant={"contained"}
                text={t("login.sign.in")}
                onClick={() => props.handleSubmit()}
                size="medium"
                color={OwnColors.lightGrey}
                textColor="black"
            />
            <Grid container>
                <Grid item xs>
                    <Link href="#" variant="body2" onClick={() => props.handleShowRecover()}>
                        {t("login.password.forgotten")}
                    </Link>
                </Grid>
            </Grid>
        </div>
        <Typography variant="body2" color="error" align="center">
            {props.errorMessage}
        </Typography>
        <Box mt={8}>
            <Copyright />
        </Box>
    </Container>

}

export default LoginForm;