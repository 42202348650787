/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Step
 */
export interface Step {
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    shipmentExp?: string;
    /**
     * 
     * @type {Date}
     * @memberof Step
     */
    datetime?: Date;
    /**
     * 
     * @type {number}
     * @memberof Step
     */
    stepNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof Step
     */
    status?: string;
    /**
     * 
     * @type {Array<Step>}
     * @memberof Step
     */
    subSteps?: Array<Step>;
}

export function StepFromJSON(json: any): Step {
    return StepFromJSONTyped(json, false);
}

export function StepFromJSONTyped(json: any, ignoreDiscriminator: boolean): Step {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shipmentExp': !exists(json, 'shipmentExp') ? undefined : json['shipmentExp'],
        'datetime': !exists(json, 'datetime') ? undefined : (new Date(json['datetime'])),
        'stepNumber': !exists(json, 'stepNumber') ? undefined : json['stepNumber'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'subSteps': !exists(json, 'subSteps') ? undefined : ((json['subSteps'] as Array<any>).map(StepFromJSON)),
    };
}

export function StepToJSON(value?: Step | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shipmentExp': value.shipmentExp,
        'datetime': value.datetime === undefined ? undefined : (value.datetime.toISOString()),
        'stepNumber': value.stepNumber,
        'description': value.description,
        'country': value.country,
        'location': value.location,
        'status': value.status,
        'subSteps': value.subSteps === undefined ? undefined : ((value.subSteps as Array<any>).map(StepToJSON)),
    };
}


