/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeFrameDTO
 */
export interface TimeFrameDTO {
    /**
     * 
     * @type {string}
     * @memberof TimeFrameDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeFrameDTO
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TimeFrameDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TimeFrameDTO
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeFrameDTO
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeFrameDTO
     */
    postalCode?: string;
    /**
     * 
     * @type {number}
     * @memberof TimeFrameDTO
     */
    workDays?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeFrameDTO
     */
    availableAppointments?: number;
}

export function TimeFrameDTOFromJSON(json: any): TimeFrameDTO {
    return TimeFrameDTOFromJSONTyped(json, false);
}

export function TimeFrameDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeFrameDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'endTime': !exists(json, 'endTime') ? undefined : json['endTime'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'workDays': !exists(json, 'workDays') ? undefined : json['workDays'],
        'availableAppointments': !exists(json, 'availableAppointments') ? undefined : json['availableAppointments'],
    };
}

export function TimeFrameDTOToJSON(value?: TimeFrameDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'active': value.active,
        'startTime': value.startTime,
        'endTime': value.endTime,
        'postalCode': value.postalCode,
        'workDays': value.workDays,
        'availableAppointments': value.availableAppointments,
    };
}


