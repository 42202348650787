/* tslint:disable */
/* eslint-disable */
/**
 * MOBEL Proxy Api Documentation
 * MOBEL Proxy Api Documentation
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BackOfficeUser
 */
export interface BackOfficeUser {
    /**
     * 
     * @type {string}
     * @memberof BackOfficeUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BackOfficeUser
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BackOfficeUser
     */
    territoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof BackOfficeUser
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof BackOfficeUser
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof BackOfficeUser
     */
    password?: string;
}

export function BackOfficeUserFromJSON(json: any): BackOfficeUser {
    return BackOfficeUserFromJSONTyped(json, false);
}

export function BackOfficeUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackOfficeUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'territoryId': !exists(json, 'territoryId') ? undefined : json['territoryId'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'password': !exists(json, 'password') ? undefined : json['password'],
    };
}

export function BackOfficeUserToJSON(value?: BackOfficeUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'name': value.name,
        'territoryId': value.territoryId,
        'phone': value.phone,
        'role': value.role,
        'password': value.password,
    };
}


