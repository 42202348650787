import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import ShipmentViewModel from "../../viewmodels/ShipmentViewModel";
import trackingStyles from "./TrackingStyles";
import InProgress from "./../components/tracking/InProgress";
import NotFound from "./../components/tracking/NotFound";
import TrackingComponent from "./../components/tracking/TrackingComponent";
import ChatComponent from "./../components/chat/ChatComponent";
import { Step as IStep } from "../../client/models/Step";
import ChatViewModel from "../../viewmodels/ChatViewModel";
import usePreciseTimer from "../../commons/TimerHook";
import { OwnColors, timer } from "../../commons/Constants";
import NotSearched from "../components/tracking/NotSearched";
import UsersViewModel from "../../viewmodels/UsersViewModel";
import InputCard from "../components/commons/InputCard";
import { InformationPanel } from "../components/informationPanel/InformationPanel";
import SessionStorage from "../../data/sessionStorage/SessionStorage";
import PostalAreaViewModel from "../../viewmodels/PostalAreaViewModel";

const TrackingView: React.FunctionComponent = (props: any) => {
  const classes = trackingStyles();
  const vmShipment = ShipmentViewModel.getInstance();
  const vmChat = ChatViewModel.getInstance();
  const postalAreaVm = PostalAreaViewModel.getInstance();
  const selectedRef = vmShipment.currentShipment;
  const messages = vmChat.currentChat;
  const { t } = useTranslation();
  const [stepContent, setStepContent] = React.useState<IStep>();
  const [activeStep, setActiveStep] = React.useState(-1);
  const [firstRender, setFirstRender] = React.useState(true);
  const [managerName, setManagerName] = React.useState("");
  const [customerEmail, setCustomerEmail] = React.useState("");
  const [tempCustomerEmail, setTempCustomerEmail] = React.useState("");
  const [emailCheckError, setEmailCheckError] = React.useState(false);
  const [isPanelDismissed, setIsPanelDismissed] = useState(false);
  const [infoPanelVisible, setInfoPanelVisible] = useState(false);

  React.useEffect(() => {
    var query: string | undefined = props.location.search;
    if (props.location.hash) query += props.location.hash;

    if (query) {
      var params = query.split("&");
      var alb = "";
      var phone = "";
      alb = params.find((value) => value.indexOf("alb=") > -1) || "";
      alb && (alb = alb.split("=")?.[1]);
      alb = decodeURIComponent(alb);
      phone = params.find((value) => value.indexOf("phone=") > -1) || "";
      phone && (phone = phone.split("=")?.[1]);

      if (phone && alb) {
        vmShipment.setTrackingAlb(alb);
        vmShipment.setTrackingPhone(phone);
        vmShipment.loadShipment(alb, phone);
      }
    }
  }, []);

  useEffect(() => {
    const panelDismissed = SessionStorage.isInformationAcknowledged();
    setIsPanelDismissed(!panelDismissed);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setInfoPanelVisible(isPanelDismissed);
    }, 150);
  }, [isPanelDismissed]);

  useEffect(() => {
    const { shipmentAlb, phone } = selectedRef ?? {};

    if (shipmentAlb !== undefined && shipmentAlb !== "" && phone !== undefined && phone !== "") {
      postalAreaVm.getAppointments(shipmentAlb ?? "", phone ?? "");
    }
  }, [postalAreaVm, selectedRef]);

  const handleSendChatMsg = (msg: string) => {
    let customer = true;
    if (msg !== "") {
      vmChat.sendChatMsg(selectedRef!, msg, customer, t);
      //check if messages stack is empty and show warning about messages.
    }
  };

  const checkChatNewMsg = () => {
    // console.log("checkChatNewMsg => timestamp =", Date.now());
    vmChat.loadChatMsg(t, selectedRef);
  };

  const handleDismissInfoPanel = () => {
    setIsPanelDismissed(false);
    SessionStorage.acknowledgeInformation();
  };

  const handleEmailSubmitButton = () => {
    const regex = new RegExp(/^[\w._-]+@[A-Za-z]+\.[A-Za-z]+$/);

    if (tempCustomerEmail.match(regex)?.length === 1) {
      setCustomerEmail(tempCustomerEmail);
      handleSendChatMsg(tempCustomerEmail);
      setEmailCheckError(false);
    } else {
      setEmailCheckError(true);
    }
  };

  usePreciseTimer(checkChatNewMsg, timer, selectedRef);

  useEffect(() => {
    console.log("vmShipment.loadingShipment", vmShipment.loadingShipment);
    vmShipment.loadingShipment && setFirstRender(false);
  }, [vmShipment.loadingShipment]);

  useEffect(() => {
    selectedRef &&
      UsersViewModel.getInstance()
        .getUsernameByEmail(selectedRef?.accountManagerId || "")
        .then((name) => setManagerName(name));

    setCustomerEmail(selectedRef?.accountManagerId ?? "");
  }, [UsersViewModel.getInstance().users, selectedRef]);

  return (
    <Grid container className={classes.mainContainer}>
      {!vmShipment.loadingShipment ? (
        selectedRef ? (
          <>
            <Grid item xs={12} sm={6} style={{ padding: "0px 15px" }}>
              {infoPanelVisible ? <InformationPanel dismissFn={handleDismissInfoPanel} panelVisible={isPanelDismissed} /> : <></>}

              <TrackingComponent
                managerName={managerName}
                selectedRef={vmShipment.currentShipmentResponse}
                activeStep={activeStep}
                stepContent={stepContent}
                eta={vmShipment.currentShipmentResponse?.eta}
                deliveryType={vmShipment.currentShipmentResponse?.deliveryType}
                isAdmin={false}
                appointmentAvailabilityResponse={postalAreaVm.appointmentAvailabilityResponse}
              />
            </Grid>

            <Grid item xs={12} sm={6} style={{ padding: "0px 15px", background: OwnColors.lightGrey }}>
              {vmShipment.currentShipmentResponse?.isChatActive === false && (
                <Grid item xs={12} justify="flex-start">
                  <Typography style={{ marginLeft: 10, fontWeight: "bold" }}>{t("disable.chat.disabled")}</Typography>
                </Grid>
              )}
              {customerEmail !== "" ? (
                <ChatComponent
                  cssClasses={classes}
                  messages={messages}
                  handleSendChatMsg={handleSendChatMsg}
                  isActive={vmShipment.currentShipmentResponse?.isChatActive ?? true}
                />
              ) : (
                <InputCard
                  text={tempCustomerEmail}
                  error={emailCheckError}
                  executingFunction={setTempCustomerEmail}
                  submitFunction={handleEmailSubmitButton}
                />
              )}
            </Grid>
          </>
        ) : firstRender ? (
          <NotSearched />
        ) : (
          <NotFound />
        )
      ) : (
        <InProgress />
      )}
    </Grid>
  );
};

export default observer(TrackingView);
