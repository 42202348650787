import User from "../../model/User";

export default class SessionStorage {
  //todo extract keys to constants
  static getToken() {
    const token = localStorage.getItem("token");
    /*if (!token) {
                throw new TokenNotFoundException()
            }*/
    return token || "";
  }

  static setToken(token: string) {
    localStorage.setItem("token", token);
  }

  static setLocalStorageUser(profile: string, userId: number, email: string) {
    console.log("localSessionStore =>  setLocalStorageUser: ", profile);
    localStorage.setItem("profile", profile);
    localStorage.setItem("userId", userId.toString());
    localStorage.setItem("email", email);
  }

  static getLocalStorageUser(): User {
    return {
      email: localStorage.getItem("email") || "",
      profile: localStorage.getItem("profile") || "",
      token: SessionStorage.getToken() || "",
    };
  }

  static isLoggedIn() {
    const token = localStorage.getItem("token");
    return token && token.length > 0 ? true : false;
  }

  static getCurrentPath() {
    if (!localStorage.getItem("currentPath")) localStorage.setItem("currentPath", "/");
    console.log("getCurrentPath", localStorage.getItem("currentPath"));
    return localStorage.getItem("currentPath")!!;
  }

  setCurrentPath(path: string) {
    localStorage.setItem("currentPath", path);
  }

  static acknowledgeInformation() {
    localStorage.setItem("informationAcknowledged", "true");
  }

  static isInformationAcknowledged(): boolean {
    return !!(localStorage.getItem("informationAcknowledged") ?? "");
  }
}
